import React, { useEffect } from "react";
import { Row, Col, Modal, Button, ModalBody, ModalHeader } from "reactstrap";
import UILoader from "@components/ui-loader";
import Loader from "../../../common/Loader/Loader";

const ConfirmModel = ({
  open,
  setOpen,
  title,
  info,
  primaryBtnTitle,
  secondaryBtnText,
  handleClick,
  btnColor,
  outline,
  disabled,
  modalSize,
  handleClose,
  children,
}) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "visible";
    };
  }, [open]);
  return (
    <>
      <Modal
        isOpen={open}
        toggle={() => setOpen(!open)}
        className={`modal-dialog-centered ${modalSize ? modalSize : ""}`}
        // onClosed={onClose}
        backdrop="static"
      >
        <UILoader blocking={disabled} loader={<Loader />}>
          <ModalHeader
            className={`bg-transparent ${disabled && "btn-loading"}`}
            toggle={() => setOpen(!open)}
          ></ModalHeader>
          <ModalBody className="px-sm-4 mx-50 pb-5 ">
            <h1 className="text-center mb-1">
              {title ? title : "Are You Sure ?"}
            </h1>
            <p
              className="text-center"
              // style={{ color: "#f8a78c" }}
            >
              {info ? info : ""}
            </p>
            {children}
            <Row tag="form" className="gy-1 gx-2 mt-75">
              <Col className="text-center mt-1" xs={12}>
                <Button
                  disabled={disabled}
                  className="me-1"
                  color={btnColor ? btnColor : "primary"}
                  onClick={handleClick}
                  outline={outline ? outline : ""}
                >
                  {primaryBtnTitle ? primaryBtnTitle : "Yes"}
                </Button>
                <Button
                  color="secondary"
                  outline
                  onClick={() => {
                    if (handleClose) {
                      handleClose();
                    }
                    if (setOpen) {
                      setOpen(!open);
                    }
                  }}
                >
                  {secondaryBtnText ? secondaryBtnText : "No"}
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </UILoader>
      </Modal>
    </>
  );
};

export default ConfirmModel;
