/* eslint-disable multiline-ternary */
// ** React Imports
import { Fragment, useEffect, useState } from "react";
// import { useSelector, useDispatch } from "react-redux";

// ** Reactstrap Imports
import { Button, Card, CardBody, Label, UncontrolledTooltip } from "reactstrap";

// ** Third Party Components

import { Check, HelpCircle, X } from "react-feather";

// ** Custom Components
import Avatar from "@components/avatar";
import LineSkelaton from "../../../common/skeleton/LineSkelaton";

// eslint-disable-next-line no-unused-vars
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { getErrorMsg } from "../../../common/ErrorMessages/errorMessage";
import { getCurrentUser } from "../../../redux/currentUserSlice/currentUser.thunk";
import {
  getUserProfile,
  updateUserProfile,
} from "../../../services/user.services";
import { maxSizeInMB } from "../../../utility/Utils";
import ToastContent from "../../components/toast/toast";
// import LineSkelaton from "../../../common/skelaton/LineSkelaton";

const AdminAvatar = ({ store }) => {
  const dispatch = useDispatch();

  const [avatar, setAvatar] = useState([]);
  const [profileImage, setProfileImage] = useState(null);
  const [loadingState, setLoadingState] = useState(true);
  const [disableButton, setDisableButton] = useState(true);
  // const fileRef = useRef(null);
  // console.log("fileRef: ", fileRef);

  useEffect(() => {
    if (store?.data?.data?.personal_info.id) {
      getUserProfile(store?.data?.data?.personal_info.id)
        .then((res) => {
          setProfileImage(res.data);
          setLoadingState(false);
        })
        .catch((err) => {
          console.log("err", err);
          setProfileImage(null);
          setLoadingState(false);
        });
    }
  }, [store?.data?.data?.personal_info.id]);

  const formData = new FormData();
  const handleImageUpload = (e) => {
    setAvatar(e.target.files);
    setDisableButton(false);
  };

  const handleUpload = () => {
    setDisableButton(true);
    if (avatar.length > 0) {
      if (avatar[0] && avatar[0].size > maxSizeInMB) {
        // setDisableButton(false);
        toast((t) => (
          <ToastContent
            t={t.id}
            message="Limit exceeded: Profile picture must not be more than 20MB."
            color="danger"
            icon={<X size={12} />}
          />
        ));
      } else {
        // API Call
        formData.append("image", avatar[0]);
        formData.append("id", store?.data?.data?.personal_info.id);
        formData.append("role_id", store?.data?.data?.personal_info.role_id);
        updateUserProfile(formData)
          .then((res) => {
            toast((t) => (
              <ToastContent
                t={t.id}
                message={res?.message}
                icon={<Check size={12} />}
              />
            ));
            setDisableButton(true);
            getUserProfile(store?.data?.data?.personal_info.id)
              .then((res) => {
                setProfileImage(res.data);
              })
              .catch((err) => {
                console.log("err", err);
                setProfileImage(null);
              });
            dispatch(getCurrentUser());
          })
          .catch((err) => {
            console.log(err);
            setDisableButton(true);
            getErrorMsg(err);
          });
      }
    } else {
      setDisableButton(true);
      toast((t) => (
        <ToastContent
          t={t.id}
          color="danger"
          message="No image selected! Please choose an image before clicking upload."
          icon={<Check size={12} />}
        />
      ));
    }
  };
  // ** Get Data From Store
  // const store = useSelector((state) => state.user);

  // **Dispatch
  // const dispatch = useDispatch();

  // ** Get firstName LastName
  const userName = store?.data?.data?.personal_info
    ? `${store?.data?.data?.personal_info?.first_name} ${store?.data?.data?.personal_info?.last_name}`
    : "john Doe";

  // ** Hook

  // ** render user img
  const renderUserImg = () => {
    if (profileImage !== null) {
      return (
        <Button
          tag={Label}
          className="mb-75 me-75 p-0"
          style={{
            minHeight: "110px",
            minWidth: "110px",
          }}
          size="sm"
          color="primary"
        >
          <img
            className="rounded"
            src={avatar.length ? URL.createObjectURL(avatar[0]) : profileImage}
            alt={userName}
            height="108"
            width="108"
          />
          {/* <Input
            type="file"
            onChange={(e) => handleImageUpload(e)}
            hidden
            accept="image/*"
          /> */}
        </Button>
      );
    } else {
      return (
        <Button
          tag={Label}
          className="mb-75 me-75 p-0"
          size="sm"
          style={{
            height: "110px",
            width: "110px",
          }}
        >
          {avatar.length === 1 ? (
            <img
              className="rounded"
              src={
                avatar.length ? URL.createObjectURL(avatar[0]) : profileImage
              }
              alt={userName}
              height="108"
              width="108"
            />
          ) : (
            <Avatar
              initials
              color={"primary"}
              className="rounded"
              content={store?.data?.data?.personal_info ? userName : "John Doe"}
              contentStyles={{
                borderRadius: 0,
                fontSize: "calc(48px)",
                width: "100%",
                height: "100%",
                // color: "#4DA69A",
              }}
              style={{
                height: "108px",
                width: "108px",
              }}
            />
          )}
          {/* <Input
            type="file"
            onChange={(e) => handleImageUpload(e)}
            hidden
            accept="image/*"
          /> */}
        </Button>
      );
    }
  };

  useEffect(() => {
    renderUserImg();
  }, [avatar]);
  return (
    <Fragment>
      <Card>
        <CardBody>
          <div className="user-avatar-section">
            <div className="d-flex align-items-center flex-column">
              {store?.isLoading || loadingState ? (
                <LineSkelaton
                  style={{
                    height: "110px",
                    width: "110px",
                  }}
                  className="rounded mt-3 mb-2"
                />
              ) : (
                renderUserImg()
              )}
              <div className="d-flex flex-column align-items-center text-center">
                <div className="user-info">
                  <h4 className="text-capitalize">
                    {store?.isLoading ? (
                      <LineSkelaton style={{ minWidth: "150px" }} />
                    ) : store?.data?.data?.personal_info ? (
                      userName
                    ) : (
                      ""
                    )}
                  </h4>

                  <Button
                    className="mb-75 me-75 custom-upload-button"
                    size="sm"
                    color="primary"
                    outline
                    // onClick={() => fileRef.current.click()}
                  >
                    {/* <Label for="fileIn">Upload</Label>
                    <Input
                      // ref={fileRef}
                      type="file"
                      hidden
                      accept="image/*"
                      onChange={(e) => handleImageUpload(e)}
                      id="fileIn"
                    /> */}
                    <input
                      type="file"
                      id="upload"
                      hidden
                      accept="image/*"
                      onChange={(e) => handleImageUpload(e)}
                    />
                    <label for="upload" className="label-upload">
                      Upload
                    </label>
                  </Button>

                  <Button
                    className="mb-75 "
                    color="primary"
                    size="sm"
                    disabled={disableButton || !avatar.length > 0}
                    onClick={() => handleUpload()}
                    // onClick={() => setAvatar([])}
                  >
                    Save
                  </Button>
                  {/* <Button
                    tag={Label}
                    className="mb-75 me-75"
                    size="sm"
                    color="primary"
                  >
                    Edit
                    <Input
                      type="file"
                      onChange={onChange}
                      hidden
                      accept="image/*"
                    />
                  </Button> */}
                  {/* <Button
                    className="mb-75"
                    color="secondary"
                    size="sm"
                    outline
                    onClick={handleImgReset}
                  >
                    Reset
                  </Button> */}
                </div>
              </div>
              {/* <div className="d-flex align-items-end mt-75 ms-1">
                <div>
                  <p className="mb-0">
                    Allowed JPG, GIF or PNG. Max size of 800kB
                  </p>
                </div>
              </div> */}
            </div>
          </div>

          <div className="info-container">
            {!store?.data?.data?.subscription_info ? (
              ""
            ) : (
              <>
                <hr className="mb-2" />
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h6 className="text-muted fw-bolder"> Number of Coins</h6>
                    <div className="d-flex align-items-center">
                      <h3 className="font-large-2 mb-0 text-primary">
                        {numberWithCommas(
                          store?.data?.data?.carets?.total_amount
                        ) ?? "0"}
                        <span className="font-large-1 mb-0 text-primary">
                          {" "}
                          Coins
                        </span>
                      </h3>
                      <HelpCircle
                        className="mt-75 mx-25"
                        id="UnControlledExample"
                      />

                      <UncontrolledTooltip
                        placement="top"
                        target="UnControlledExample"
                      >
                        Coins = points, basically! These are added to your
                        account when you subscribe to any of our membership
                        plans. You can use these coins to pay for video edits
                        throughout the year. The higher the plan, the more
                        valuable your coins are, indicated by the discount
                        amounts listed on each package. Still finding it tricky?
                        Don't worry! We provide a calculator below to help you
                        compare the cost if you were to pay per edit versus
                        using a membership plan!
                      </UncontrolledTooltip>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          {/* <div className="d-flex justify-content-center pt-2">
            <Button color="primary" onClick={() => setShow(true)}>
              Edit
            </Button>
            <Button
              className="ms-1"
              color="danger"
              outline
              onClick={handleSuspendedClick}
            >
              Suspended
            </Button>
          </div> */}
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default AdminAvatar;
