import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormFeedback } from "reactstrap";

import ReactDatePicker from "react-datepicker";
import { RenderDynamicFiledLabel } from "./index";

const FormDatePicker = ({ fieldData }) => {
  const { control, errors, fieldsDisabled } = useFormContext();

  return (
    <React.Fragment>
      {/* Label */}
      <RenderDynamicFiledLabel fieldData={fieldData} />

      <Controller
        id={fieldData?.name}
        name={fieldData?.name}
        control={control}
        render={({ field: { value, onChange, ...fieldProps } }) => (
          <ReactDatePicker
            {...fieldProps}
            selected={value}
            onChange={(date) => {
              onChange(date);
            }}
            placeholder={fieldData?.placeholder || ""}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            className="form-control"
            dateFormat="MM/dd/yy"
            calendarClassName="custom-datepicker-calendar"
            popperClassName="custom-datepicker-popper"
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            autoComplete="off"
            disabled={fieldsDisabled}
          />
        )}
      />
      {errors && errors[fieldData?.name] && (
        <FormFeedback>{errors[fieldData?.name].message}</FormFeedback>
      )}
    </React.Fragment>
  );
};

export default FormDatePicker;
