import React, { useState } from "react";
import { Check, Edit, Eye, Trash } from "react-feather";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Button, UncontrolledTooltip } from "reactstrap";
import { ReactComponent as ArchiveIcon } from "../../../../assets/images/pages/archive.svg";
import { getErrorMsg } from "../../../../common/ErrorMessages/errorMessage";
import { getAllDataProjectManagement } from "../../../../redux/allProjectsManagement/allDataProjectManagement.thunk";
import {
  archivedProject,
  deleteProject,
  unarchiveProject,
} from "../../../../services/ArchivedProjects/archivedProjects.services";
import ConfirmModel from "../../../components/modals/ConfirmModal";
import ToastContent from "../../../components/toast/toast";
import { useLocation, useNavigate } from "react-router-dom";
import { ADMIN_ROUTES } from "../../../../constant/route";
import { ROLE } from "../../../../constant/constant";
import { getUserData } from "../../../../utility/Utils";
import { ReactComponent as UnArchiveIcon } from "../../../../assets/images/pages/unarchive.svg";
import { getArchivedProjects } from "../../../../redux/archivedProjects/archivedProjects.thunks";

const ArchivedOrDelete = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isDisabled = location.pathname === ADMIN_ROUTES.ARCHIVED_PROJECTS;

  const [deleteModelOpen, setDeleteModelOpen] = useState({
    isOpen: false,
    value: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleArchived = () => {
    const payload = {
      id: props?.data?.sub_project_id,
    };
    archivedProject(payload)
      .then((res) => {
        toast((t) => (
          <ToastContent
            t={t.id}
            message={res?.message}
            icon={<Check size={12} />}
            color="success"
          />
        ));
        dispatch(getAllDataProjectManagement({ user_id: "all" }));
      })
      .catch((err) => {
        console.log("err: ", err);
        getErrorMsg(err);
      });
  };

  const handleDeleteModal = () => {
    setDeleteModelOpen({ isOpen: true, value: props?.data?.sub_project_id });
  };

  const handleDeleteProject = () => {
    setIsLoading(true);
    const payload = {
      id: deleteModelOpen?.value,
    };

    deleteProject(payload)
      .then((res) => {
        toast((t) => (
          <ToastContent
            t={t.id}
            message={res?.message}
            icon={<Check size={12} />}
            color="success"
          />
        ));
        setDeleteModelOpen({ isOpen: false, value: "" });
        setIsLoading(false);
        // getListData({
        //   user_id: "all",
        //   page: currentPage,
        //   search: searchQuery,
        // });
        dispatch(getAllDataProjectManagement({ user_id: "all" }));
      })
      .catch((err) => {
        console.log("err: ", err);
        setIsLoading(false);
        getErrorMsg(err);
      });
  };

  // EDIT
  const view_or_edit = [ROLE.SUPER_ADMIN, ROLE.ADMIN].includes(
    getUserData()?.role
  );
  const navigate = useNavigate();
  const handleEditClick = () => {
    navigate(ADMIN_ROUTES.PROJECT_INFO, {
      state: {
        sub_project_id: props?.data?.sub_project_id,
        module: isDisabled && "ARCHIVED",
      },
    });
  };

  // UnArchive
  const handleUnArchive = () => {
    unarchiveProject({ id: props?.data?.sub_project_id })
      .then((res) => {
        toast((t) => (
          <ToastContent
            t={t.id}
            message={res?.message}
            icon={<Check size={12} />}
            color="success"
          />
        ));

        dispatch(getArchivedProjects({ user_id: "all" }));
      })
      .catch((err) => {
        console.log("err: ", err);
        getErrorMsg(err);
      });
  };
  return !isDisabled ? (
    <>
      <>
        <UncontrolledTooltip
          className="custom-tooltip"
          target={`view-edit-order-${props?.data?.sub_project_id}`}
        >
          {view_or_edit ? "Edit Order" : "View Order"}
        </UncontrolledTooltip>
        <Button.Ripple
          className="btn-icon rounded-circle me-50 grouping"
          color="flat-success"
          id={`view-edit-order-${props?.data?.sub_project_id}`}
          onClick={() => handleEditClick()}
        >
          {view_or_edit ? <Edit size={16} /> : <Eye size={16} />}
        </Button.Ripple>
      </>
      {view_or_edit && (
        <>
          <UncontrolledTooltip
            className="custom-tooltip"
            target={`archived-order-${props?.data?.sub_project_id}`}
          >
            Archived Order
          </UncontrolledTooltip>
          <Button.Ripple
            className="btn-icon light-blue rounded-circle me-50 grouping"
            // color="flat-secondary"
            id={`archived-order-${props?.data?.sub_project_id}`}
            onClick={() => handleArchived()}
          >
            <ArchiveIcon />
          </Button.Ripple>
        </>
      )}

      {view_or_edit && (
        <>
          <UncontrolledTooltip
            className="custom-tooltip"
            target={`delete-order-${props?.data?.sub_project_id}`}
          >
            Delete Order
          </UncontrolledTooltip>
          <Button.Ripple
            className="btn-icon rounded-circle grouping"
            color="flat-danger"
            id={`delete-order-${props?.data?.sub_project_id}`}
            onClick={() => handleDeleteModal()}
          >
            <Trash size={16} />
          </Button.Ripple>
          <ConfirmModel
            disabled={isLoading}
            handleClick={handleDeleteProject}
            btnColor="danger"
            primaryBtnTitle={isLoading ? "Deleting..." : "Delete"}
            secondaryBtnText="Cancel"
            open={deleteModelOpen?.isOpen}
            setOpen={setDeleteModelOpen}
            title="Are you sure you want to delete this project?"
          />
        </>
      )}
    </>
  ) : (
    <>
      <>
        <UncontrolledTooltip
          className="custom-tooltip"
          target={`view-order-${props?.data?.sub_project_id}`}
        >
          View Order
        </UncontrolledTooltip>
        <Button.Ripple
          className="btn-icon rounded-circle me-50 grouping"
          color="flat-success"
          id={`view-order-${props?.data?.sub_project_id}`}
          onClick={() => handleEditClick()}
        >
          <Eye size={16} />
        </Button.Ripple>
      </>
      {view_or_edit && (
        <>
          <UncontrolledTooltip
            className="custom-tooltip"
            target={`un-archived-order-${props?.data?.sub_project_id}`}
          >
            Unarchived Order
          </UncontrolledTooltip>
          <Button.Ripple
            className="btn-icon rounded-circle grouping"
            color="flat-primary"
            id={`un-archived-order-${props?.data?.sub_project_id}`}
            onClick={() => handleUnArchive()}
          >
            <UnArchiveIcon />
          </Button.Ripple>
        </>
      )}
    </>
  );
};

export default ArchivedOrDelete;
