import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormFeedback, Input } from "reactstrap";
import { RenderDynamicFiledLabel } from "./index";

const FormTextTextarea = ({ fieldData }) => {
  const { control, errors, fieldsDisabled } = useFormContext();
  return (
    <React.Fragment>
      {/* Label */}
      <RenderDynamicFiledLabel fieldData={fieldData} />
      <Controller
        id={fieldData?.name}
        name={fieldData?.name}
        control={control}
        render={({ field: { value, ...fieldProps } }) => (
          <Input
            {...fieldProps}
            type="textarea"
            rows={fieldData?.rows ? fieldData?.rows : 2}
            placeholder={fieldData?.placeholder || ""}
            value={value}
            disabled={fieldsDisabled}
          />
        )}
      />
      {errors && errors[fieldData?.name] && (
        <FormFeedback>{errors[fieldData?.name].message}</FormFeedback>
      )}
    </React.Fragment>
  );
};

export default FormTextTextarea;
