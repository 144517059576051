import Breadcrumbs from "@components/breadcrumbs";
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import "@styles/react/libs/flatpickr/flatpickr.scss";
import "@styles/react/libs/react-select/_react-select.scss";
import { Card } from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import LineSkelaton from "../../../common/skeleton/LineSkelaton";
import { getCustomTable } from "../../../services/projectManagementService";
// import moment from "moment";

import { getArchivedProjects } from "../../../redux/archivedProjects/archivedProjects.thunks";
import { getEditors } from "../../../redux/editorsMaster/editorsMaster.thunk";
import { getStatusManagementTableList } from "../../../redux/statusManagement/statusManagementList.thunk";
import { columnDefs } from "../projectManagement/agGrid/utils";

const ArchivedProjects = () => {
  const dispatch = useDispatch();
  const gridApiRef = useRef(null);
  const [isListApiCall, setIsListApiCall] = useState(true);
  const [colConfig, setColConfig] = useState(columnDefs());
  const [isLoadingState, setIsLoadingState] = useState(false);
  const [tableGridApi, setTableGridApi] = useState({});
  const projects = useSelector(
    (state) => state.archivedProjects.archivedProjectsList
  );

  useEffect(() => {
    dispatch(getStatusManagementTableList());
    dispatch(getEditors());
  }, []);

  const onGridReady = (params) => {
    setIsLoadingState(true);
    params?.api?.showLoadingOverlay();
    setTableGridApi(params);
  };

  const getListData = () => {
    const payload = { user_id: "all" };
    dispatch(getArchivedProjects(payload));
    setIsListApiCall(!isListApiCall);
  };

  useEffect(() => {
    if (isListApiCall) getListData();
  }, [isListApiCall]);

  const getColumnsInApi = useCallback(() => {
    if (!tableGridApi || !tableGridApi.api) {
      return;
    }

    getCustomTable(1)
      .then((res) => {
        const columns = res?.data?.project_listing.column_sizes;
        const currentColumns = tableGridApi.api.getColumnDefs();
        const updatedColumns = columns.map((row) => {
          const colFind = currentColumns.find(
            (item) => item.colId === row.colId
          );
          const valueFormatter = tableGridApi.api
            .getColumnDefs()
            .find((row) => row.colId === "custom_nullable")?.valueFormatter;

          if (colFind) {
            const {
              cellRenderer,
              valueParser,
              getQuickFilterText,
              valueFormatter,
              cellClass,
            } = colFind;

            const updatedColumn = {
              ...(cellRenderer && { cellRenderer }),
              ...(valueParser && { valueParser }),
              ...(getQuickFilterText && { getQuickFilterText }),
              ...(valueFormatter && { valueFormatter }),
              ...(cellClass && { cellClass }),
            };

            return {
              ...row,
              ...updatedColumn,
              cellStyle: { display: "grid", alignItems: "center" },
            };
          }
          return {
            ...row,
            valueFormatter,
            cellStyle: { display: "grid", alignItems: "center" },
          };
        });

        tableGridApi.api.setColumnDefs(updatedColumns);
        setColConfig(updatedColumns);
        setIsLoadingState(false);
      })
      .catch(() => setIsLoadingState(false));
  }, [tableGridApi]);

  useEffect(() => {
    getColumnsInApi();
  }, [tableGridApi, tableGridApi?.api]);

  const containerStyle = useMemo(
    () => ({
      width: "100%",
      height: "calc(100vh - 180px)",
      minHeight: "480px",
      alignItems: "center",
    }),
    []
  );
  return (
    <>
      <Breadcrumbs
        title="All Archived Orders"
        data={[{ title: "All Archived Orders", link: "" }]}
      />

      <div className="custom-ag-grouping custom-loader-ag">
        {isLoadingState === true && projects?.isFetching !== false && (
          <div>
            {[...Array(5)].map((_, i) => (
              <LineSkelaton key={i} />
            ))}
          </div>
        )}
        <div
          style={containerStyle}
          className={`ag-theme-quartz ${
            projects?.data?.length && tableGridApi?.api ? `d-block` : "d-none"
          } ${isLoadingState && "d-none"}`}
        >
          <AgGridReact
            rowData={projects?.data || []}
            columnDefs={colConfig}
            ref={gridApiRef}
            suppressRowHoverHighlight={true}
            suppressDragLeaveHidesColumns={true}
            onGridReady={onGridReady}
            suppressMaxRenderedRowRestriction={true}
            popupParent={document.body}
            getRowHeight={50}
          />
        </div>
        <div className="no_data">
          {projects?.isFetching === false &&
            projects?.data?.length === 0 &&
            !isLoadingState && (
              <Card>
                <p>No Data Found</p>
              </Card>
            )}
        </div>
      </div>
    </>
  );
};

export default ArchivedProjects;
