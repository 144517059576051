import React from "react";
import { AGDropdownStyles } from "../../../../utility/Utils";
import { useSelector } from "react-redux";
import { assignProjectEditor } from "../../../../services/EditorsMaster/editorsMaster.services";
import ToastContent from "../../../components/toast/toast";
// import { getAllDataProjectManagement } from "../../../../redux/allProjectsManagement/allDataProjectManagement.thunk";
import { Check } from "react-feather";
import { getErrorMsg } from "../../../../common/ErrorMessages/errorMessage";
import toast from "react-hot-toast";
import ReactSelect from "react-select";
import { checkUserID } from "./utils";
import { useLocation } from "react-router-dom";
import { ADMIN_ROUTES } from "../../../../constant/route";

const AssignedEditor = (props) => {
  const editorsOptions = useSelector(
    (state) => state.editorsMaster.editorsList
  );
  const location = useLocation();
  const isDisabled = location.pathname === ADMIN_ROUTES.ARCHIVED_PROJECTS;

  const currentUser = useSelector(
    (state) => state?.currentUser?.currentuserdata?.data
  );
  // const dispatch = useDispatch();
  const handleApiCall = (data) => {
    const payload = {
      sub_project_id: props?.data?.sub_project_id,
      editor_id: data?.id,
    };
    assignProjectEditor(payload)
      .then((res) => {
        toast((t) => (
          <ToastContent
            t={t.id}
            message={res?.message}
            icon={<Check size={12} />}
            color="success"
          />
        ));
        // dispatch(getAllDataProjectManagement({ user_id: "all" }));
      })
      .catch((err) => {
        console.log("err: ", err);
        getErrorMsg(err);
      });
  };
  return (
    // <div>
    <span
      style={{
        opacity: (checkUserID(currentUser?.role_id) || isDisabled) && 0.7,
      }}
    >
      {/* {checkUserID(currentUser?.role_id) ? (
        editorsOptions?.data?.find((item) => item.id === props.data?.editor_id)
      ) : ( */}
      <ReactSelect
        menuPortalTarget={document.body}
        id="assign_editor"
        placeholder="Select Editor"
        styles={{
          ...AGDropdownStyles,
          control: (provided) => ({
            ...provided,
            height: "30px",
            lineHeight: "normal",
            minHeight: "30px",
            // borderColor: `${ProjectStatusColorCode.get(
            //   props?.data?.project_status
            // )} !important`,
            backgroundColor: "transparent !important",
          }),
          valueContainer: (provided) => ({
            ...provided,
            padding: "1px 8px",
          }),
          indicatorsContainer: (provided) => ({
            ...provided,
            height: "29px",
          }),
          singleValue: (provided) => ({
            ...provided,
            // color: `${ProjectStatusColorCode.get(
            //   props?.data?.project_status
            // )} !important`,
          }),
        }}
        className="react-select custom-table-react-se"
        classNamePrefix="select"
        isClearable={false}
        options={editorsOptions?.data}
        getOptionValue={(opt) => opt.id}
        getOptionLabel={(opt) => opt.editor_name}
        // theme={selectThemeColors}
        // value={getProjectStatus(row?.project_status)}
        defaultValue={editorsOptions?.data?.find(
          (item) => item.id === props.data?.editor_id
        )}
        isDisabled={checkUserID(currentUser?.role_id) || isDisabled}
        onChange={(data) => handleApiCall(data)}
      />
      {/* )} */}
    </span>
  );
};

export default AssignedEditor;
