import Breadcrumbs from "@components/breadcrumbs";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Check, HelpCircle } from "react-feather";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
// import { useLocation } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import * as yup from "yup";
import { getErrorMsg } from "../../../common/ErrorMessages/errorMessage";
// import { ADMIN_ROUTES } from "../../../constant/route";
import {
  UpdatePopUpMessage,
  getAllPopUpMessage,
} from "../../../services/popUpManagement.service";
// import { popUpIndexData } from "../../../utility/Utils";
import CustomQuillTextEditor from "../../components/common/CustomQuillTextEditor";
import ToastContent from "../../components/toast/toast";
import LineSkelaton from "../../../common/skeleton/LineSkelaton";
// import Maintenance from "../../pages/misc/Maintenance";
import { isLocalhostUrl } from "../../../utility/Utils";

const Schema = yup.object({
  message: yup.string().required("Pop-Up message is Required").nullable(),
});

const PopupManagement = ({ indexNumber, toolTipMessage }) => {
  const [IsLoading, setIsLoading] = useState(false);
  const [popUpDetailsData, setPopUpDetailsData] = useState(null);
  // const location = useLocation();
  // const localPath = location.pathname.split("/").pop();
  // for find dynamic index value
  // const PopUpIndex = new Map([
  //   [
  //     ADMIN_ROUTES.POPUP_MANAGEMENT_PROJECT.split("/").pop(),
  //     popUpIndexData.NEW_PROJECT,
  //   ],
  //   [
  //     ADMIN_ROUTES.POPUP_MANAGEMENT_ORDER.split("/").pop(),
  //     popUpIndexData.ORDER_PAGE,
  //   ],
  //   [
  //     ADMIN_ROUTES.POPUP_MANAGEMENT_SUBSCRIPTION.split("/").pop(),
  //     popUpIndexData.SUBSCRIPTION_PURCHASE,
  //   ],
  // ]);

  const defaultValues = {
    pop_up_name: "",
    message: "",
  };

  const {
    control,
    setError,
    setValue,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({ defaultValues, resolver: yupResolver(Schema) });

  useEffect(() => {
    if (isLocalhostUrl) {
      setIsLoading(true);
      getAllPopUpMessage()
        .then((res) => {
          const val = res[indexNumber];
          setPopUpDetailsData(val);
          reset({
            pop_up_name: val?.name,
            message: val?.message,
          });
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          getErrorMsg(err);
          setIsLoading(false);
          setPopUpDetailsData("error");
        });
    }
  }, [indexNumber]);

  // submit data
  const onSubmit = (data) => {
    const SendData = {
      id: popUpDetailsData?.id,
      message: data.message,
    };

    UpdatePopUpMessage(SendData)
      .then((res) => {
        toast((t) => (
          <ToastContent
            t={t.id}
            message={res?.message}
            icon={<Check size={12} />}
          />
        ));
      })
      .catch((err) => {
        console.log(err);
        getErrorMsg(err);
      });
  };

  const handleEditorTextChange = (e) => {
    if (e !== "<p><br></p>") {
      setValue("message", e);
      setError("message", "");
    } else if (e === "<p><br></p>") {
      setValue("message", null);
      setError("message", {
        type: "custom",
        message: "Pop-Up message is Required",
      });
    }
  };

  if (IsLoading) return [...Array(5)].map((_, i) => <LineSkelaton key={i} />);
  return (
    <>
      <Breadcrumbs
        title={popUpDetailsData?.name}
        data={[{ title: "Edit Pop-Up", link: "" }]}
      />

      <Card className="p-1 p-md-0">
        <div className="pb-2 pt-50 px-sm-2">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="gy-1 pt-75">
              <Col md={12} xs={12}>
                <Label className="form-label fw-bolder" for="InputHelp">
                  Pop-up
                </Label>
                <Controller
                  id="pop_up_name"
                  name="pop_up_name"
                  control={control}
                  render={({ field }) => (
                    <Input
                      type="text"
                      placeholder="Pop Up name"
                      {...field}
                      disabled
                    />
                  )}
                />
              </Col>

              <Col md={12} className="custom-quill-editor">
                <Label className="form-label fw-bolder">
                  Edit Popup
                  {toolTipMessage && (
                    <>
                      <HelpCircle
                        style={{ height: 16 }}
                        className="cursor-pointer"
                        id={`toolTipMessage${indexNumber}`}
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target={`toolTipMessage${indexNumber}`}
                      >
                        {toolTipMessage}
                      </UncontrolledTooltip>
                    </>
                  )}
                </Label>
                <CustomQuillTextEditor
                  value={watch("message")}
                  handleChange={(e) => handleEditorTextChange(e)}
                />
                {errors.message && (
                  <FormFeedback>{errors.message.message}</FormFeedback>
                )}
              </Col>
              {/* start */}
              <Col md={12} className="dynamic-popup-data">
                <Label className="popup-data-example-label">
                  <h3>
                    Message Preview
                    <HelpCircle
                      className="mx-25 cursor-pointer"
                      id={`PopupExample`}
                    />
                    <UncontrolledTooltip
                      placement="top"
                      target={`PopupExample`}
                    >
                      The size of the components may vary but the text size,
                      spacing, and color will remain the same.
                    </UncontrolledTooltip>
                  </h3>
                </Label>
                <div className="sub-component-dynamic-popup-data">
                  <div
                    className="main-text"
                    dangerouslySetInnerHTML={{ __html: watch("message") }}
                  />
                </div>
              </Col>
              {/* end */}
              <Col md={12} xs={12} className="">
                <Button type="submit" className="me-1" color="primary">
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Card>
    </>
  );
};

export default PopupManagement;
