import React, { useState } from "react";
import { Alert } from "reactstrap";
import { AlertCircle, X } from "react-feather";

const AlertBanner = () => {
  const [visible, setVisible] = useState(true);

  return (
    <>
      <Alert color="danger" isOpen={visible} className="alert-banner">
        <div className="alert-body">
          <div>
            <AlertCircle size={15} />
            <span className="ms-1">
              Oops! Subscription Trouble. It seems that three payments for this
              month's subscription have failed. Let's get things back on track!
              Please update your payment information to enjoy uninterrupted
              access to our services.
            </span>
          </div>
          <div>
            <X
              onClick={() => setVisible(!visible)}
              className="cursor-pointer"
            />
          </div>
        </div>
      </Alert>
    </>
  );
};

export default AlertBanner;
