import React from "react";
import Breadcrumbs from "@components/breadcrumbs";
import StripeInfo from "./StripeInfo";
import { Container } from "reactstrap";

const StripeInfoComponent = () => {
  return (
    <>
      {/* <Breadcrumbs
        title="Stripe Integration"
        data={[{ title: "Stripe Integration", link: "" }]}
      /> */}
      <StripeInfo />
    </>
  );
};

export default StripeInfoComponent;
