import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { USER_ROUTES } from "../../../../constant/route";
import { getAllPopUpMessage } from "../../../../services/popUpManagement.service";
import { isLocalhostUrl, popUpIndexData } from "../../../../utility/Utils";

const CreateProjectInfoModel = ({ show, setShow }) => {
  const navigate = useNavigate();
  // const styleProfile = localStorage.getItem("styleProfile");

  const [popUpDetails, setPopUpDetails] = useState({});
  useEffect(() => {
    if (isLocalhostUrl) {
      getAllPopUpMessage()
        .then((res) => {
          setPopUpDetails(res[popUpIndexData.NEW_PROJECT]);
        })
        .catch((err) => {
          console.log(err);
          setPopUpDetails({ message: "Something Went Wrong" });
        });
    }
  }, []);

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "visible";
    };
  }, [show]);
  return (
    <>
      <Modal
        isOpen={show}
        toggle={() => setShow(!show)}
        className="modal-dialog-centered"
        // onClosed={() => setCardType("")}
      >
        <ModalHeader
          className="bg-transparent"
          toggle={() => setShow(!show)}
        ></ModalHeader>
        <ModalBody className="px-sm-5 mx-50 pb-5">
          <Row
            tag="form"
            className="gy-1 gx-2 mt-75"
            // onSubmit={handleSubmit(onSubmit)}
          >
            <Col xs={12} className="text-center">
              {/* - Current turnaround time is 15 business days, starting from
                when we receive your footage. */}
              {/* Start 21-11-2023 */}
              {/* <p>
                Current turnaround times for subscribers is 15 business days.
                For Pay As You Go orders we are at about 4 weeks.
              </p>
              <p>
                - This is our best estimate, and we will update you if this
                changes! If you ordered multiple videos at once, the turnaround
                may be longer than 15 days.
              </p>
              <p>
                - Feel free to post a comment to the project after you order at
                any time to check on our ETA.
              </p> */}
              {/* End 21-11-2023 */}

              <div className="dynamic-popup-data-print-user">
                <div
                  className="main-text"
                  dangerouslySetInnerHTML={{ __html: popUpDetails?.message }}
                />
              </div>
              {/* <p>
                Current turnaround time is 15 business days, starting from when
                we receive your footage. This is our best estimate, and we will
                update you if this changes! If you ordered multiple videos at
                once, the turnaround may be longer than 15 days. Feel free to
                post a comment to the project after you order at any time to
                check on our ETA.
              </p> */}
              {/* <p>We will keep you posted about our progress.</p> */}
            </Col>
            <Col className="text-center mt-1" xs={12}>
              <Button
                className="me-1"
                color="primary"
                onClick={() => {
                  // if (styleProfile === "0" || styleProfile === "null") {
                  //   localStorage.setItem("redirectToCreateProject", true);
                  //   navigate(USER_ROUTES.STYLE_PROFILE);
                  // } else {
                  navigate(USER_ROUTES.CREATE_PROJECT);
                  // }
                }}
              >
                Ok
              </Button>
              {/* <Button
                color="secondary"
                outline
                onClick={() => {
                  setShow(!show);
                }}
              >
                Cancel
              </Button> */}
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CreateProjectInfoModel;
