import * as yup from "yup";
import { DATA_FIELD } from "./fields";

export const checkArray = (val) =>
  typeof val === "object" && Array.isArray(val);

export const checkObject = (val) =>
  typeof val === "object" && !Array.isArray(val);

export const schemaFormValidation = (val) => {
  const { inputFieldArray: data, defaultSchema } = val;
  const schemaObject = { ...(defaultSchema ? defaultSchema : {}) };

  if (data && Array.isArray(data)) {
    data.forEach((item) => {
      const { name, type, label, required, maxlength, subtype, multiple } =
        item;
      const maxlengthConst = parseInt(maxlength, 10);
      const maxLengthMsg = `${label} should not exceed ${maxlength} characters`;

      // START this is for input / textarea / email
      let fieldSchema = yup.string().nullable();
      if (subtype === "email") {
        fieldSchema = fieldSchema
          .email(`${label} needs to be a valid email address.`)
          .trim();
      }

      if (required) {
        fieldSchema = fieldSchema.required(
          `${label || "This Field"} is required`
        );
      }

      if (maxlengthConst) {
        fieldSchema = fieldSchema.max(maxlengthConst, maxLengthMsg);
      }

      // END this is for input / textarea / email

      switch (type) {
        // -->> text / textarea
        case DATA_FIELD.TEXT:
        case DATA_FIELD.TEXTAREA:
          schemaObject[name] = fieldSchema;
          break;
        // -->> date
        case DATA_FIELD.DATE:
          if (required) {
            schemaObject[name] = yup
              .string()
              .required(`${label || "Date"} is required`)
              .nullable();
          }
          break;
        // -->> select / autocomplete
        case DATA_FIELD.SELECT:
        case DATA_FIELD.AUTOCOMPLETE:
          if (required) {
            if (multiple) {
              schemaObject[name] = yup
                .array()
                .required(`${label} is required`)
                .min(1, `${label} is required`)
                .nullable();
            } else {
              schemaObject[name] = yup
                .object()
                .required(`${label} is required`)
                .nullable();
            }
          }

          break;
        // -->> radio-group
        case DATA_FIELD.RADIO_GROUP:
          if (required) {
            schemaObject[name] = yup
              .string()
              .required(`${label} is required`)
              .nullable();
          }
          break;
        // -->> checkbox-group
        case DATA_FIELD.CHECKBOX_GROUP:
          if (required) {
            schemaObject[name] = yup
              .array()
              .min(1, `At least one ${label} must be selected`)
              .required(`${label} is required`)
              .nullable();
          }
          break;
        // -->> file
        case DATA_FIELD.FILE:
          if (required) {
            schemaObject[name] = yup.mixed().required(`${label} is required`);
          }
          break;

        default:
          break;
      }
    });
  }
  return yup.object().shape(schemaObject);
};

export const defaultFormValue = (data) => {
  const { inputFieldArray, defaultData, defaultValues } = data;

  const fieldKeys = {
    ...(defaultValues ? defaultValues : {}),
  };

  if (inputFieldArray && Array.isArray(inputFieldArray)) {
    inputFieldArray.forEach((item) => {
      const { name, type, multiple, values } = item;
      const defaultValue = defaultData ? defaultData[name] : null;
      switch (type) {
        // -->> text / textarea
        case DATA_FIELD.TEXT:
        case DATA_FIELD.TEXTAREA:
          fieldKeys[name] = defaultValue || null;
          break;
        // -->> date
        case DATA_FIELD.DATE:
          fieldKeys[name] = defaultValue ? new Date(defaultValue) : null;
          break;
        // -->> select / autocomplete
        case DATA_FIELD.SELECT:
        case DATA_FIELD.AUTOCOMPLETE:
          if (multiple) {
            fieldKeys[name] =
              (checkArray(defaultValue) &&
                checkArray(values) &&
                defaultValue.filter((val) =>
                  values?.map((r) => r.value).includes(val.value)
                )) ||
              [];
          } else {
            fieldKeys[name] =
              (defaultValue &&
                checkObject(defaultValue) &&
                checkArray(values) &&
                values.some((r) => r.value === defaultValue.value) &&
                defaultValue) ||
              null;
          }
          break;
        // -->> radio-group
        case DATA_FIELD.RADIO_GROUP:
          // fieldKeys[name] = defaultValue || null;
          fieldKeys[name] =
            (defaultValue &&
              checkObject(defaultValue) &&
              checkArray(values) &&
              values.some((r) => r.value === defaultValue.value) &&
              defaultValue.value) ||
            null;

          // fieldKeys[`${DATA_FIELD.RADIO_GROUP}_${name}`] = values;
          break;
        // -->> checkbox-group
        case DATA_FIELD.CHECKBOX_GROUP:
          // fieldKeys[name] = defaultValue || [];
          fieldKeys[name] =
            (checkArray(defaultValue) &&
              checkArray(values) &&
              defaultValue?.map(
                (val) =>
                  values?.map((r) => r.value).includes(val.value) && val.value
              )) ||
            [];

          // fieldKeys[`${DATA_FIELD.CHECKBOX_GROUP}_${name}`] = values;
          break;
        // -->> file
        case DATA_FIELD.FILE:
          fieldKeys[name] = defaultValue || null;
          break;
        default:
          break;
      }
    });
  }
  return fieldKeys;
};

export const oneFieldRequired = ({ data, types }) => {
  const isArray = data && types && Array.isArray(data) && Array.isArray(types);
  const isTrue = isArray && data.some((field) => types.includes(field.type));
  return isTrue;
};
