// ** React Imports
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

// ** Reactstrap Imports
import { CardTitle } from "reactstrap";

// ** Styles
import "@styles/react/pages/page-authentication.scss";
import { AUTH_ROUTES } from "../../../constant/route";
import UncutHeader from "../../components/uncutHeader";

import { useEffect, useState } from "react";
import { Check } from "react-feather";
import toast from "react-hot-toast";
import { getErrorMsg } from "../../../common/ErrorMessages/errorMessage";
import { reSendEmail } from "../../../services/auth.services";
import ToastContent from "../../components/toast/toast";
// import themeConfig from "../../../configs/themeConfig";

const VerifyEmailBasic = () => {
  const { state } = useLocation();
  const [params] = useSearchParams();

  const paramsEmail = params.get("email");

  const [Loading, setLoading] = useState(false);

  const navigate = useNavigate();

  // ** Access the state values
  const email = state?.email || paramsEmail;

  useEffect(() => {
    if (!email) {
      navigate(AUTH_ROUTES.APP_LOGIN);
    }
  }, []);

  // const handleLoginClick = () => {
  //   navigate(AUTH_ROUTES.APP_LOGIN, { replace: true });
  // };

  const handleResend = () => {
    setLoading(true);
    reSendEmail(email)
      .then((res) => {
        toast((t) => (
          <ToastContent
            t={t.id}
            message={res?.data?.message}
            icon={<Check size={12} />}
          />
        ));
        setLoading(false);
        // navigate(AUTH_ROUTES.APP_LOGIN, { replace: true });
      })
      .catch((err) => {
        setLoading(false);
        getErrorMsg(err);
      });
  };

  return (
    <div className=" ">
      <div className="container-fluid main-login-header">
        <div className="row">{/* <UncutHeader /> */}</div>
      </div>

      <div className="container custom-email-verify auth-wrapper auth-basic register-page">
        <div>
          <CardTitle tag="h1" className="fw-bold mb-1">
            One More Step 👍
          </CardTitle>
          <p>
            We've sent an email to <b>{email ? email : ""}</b>. Please click the
            confirmation link in it to finalize your account.{" "}
          </p>

          <p>
            Didn't get the email? Please check your spam folder or
            <span
              className={`resend-btn ${Loading && "disabled"}`}
              onClick={() => !Loading && handleResend()}
            >
              {" "}
              {Loading ? "Sending..." : "resend."}
            </span>
          </p>
        </div>
      </div>
      {/* <div className="auth-wrapper auth-basic px-2 d-none">
        <div className="auth-inner my-2">
          <Card className="mb-0">
            <CardBody>
              <Link
                className="brand-logo"
                to="/"
                onClick={(e) => e.preventDefault()}
              >
                <div className="blinkova-logo">
                  <span className="">
                    <img src={themeConfig.app.appLogoImage} alt="logo" />
                  </span>
                  <h2 className="brand-text mb-0">{themeConfig.app.appName}</h2>
                </div>
              </Link>
              <CardTitle tag="h2" className="fw-bolder mb-1">
                Verify your email ✉️
              </CardTitle>
              <CardText className="mb-2">
                We've sent a link to your email address:{" "}
                <span className="fw-bolder">{email ? email : ""}</span> Please
                follow the link inside to continue.
              </CardText>

              <CardText className="mb-2">
                Please check your spam folder if you don't receive a
                verification email within 2 minutes.
              </CardText>

              <Button block color="primary" onClick={() => handleLoginClick()}>
                Login
              </Button>

              <Button
                disabled={Loading}
                outline
                block
                className="mt-1"
                color="primary"
                onClick={() => handleResend()}
              >
                {Loading ? "Sending..." : "Resend Verification Email"}
              </Button>
            </CardBody>
          </Card>
        </div>
      </div> */}
    </div>
  );
};

export default VerifyEmailBasic;
