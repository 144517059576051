/* eslint-disable multiline-ternary */
// ** React Imports
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

// ** Reactstrap Imports
import { CardTitle, Spinner } from "reactstrap";

// ** Styles
import "@styles/react/pages/page-authentication.scss";
import { AUTH_ROUTES } from "../../../constant/route";
import UncutHeader from "../../components/uncutHeader";

import { useEffect } from "react";
import BlinkovoHeader from "../../components/BlinkovoHeader";

const SettingWorkspace = () => {
  const { state } = useLocation();
  const [params] = useSearchParams();

  const paramsEmail = params.get("email");
  const isUser = params.get("is_user");

  const navigate = useNavigate();

  // ** Access the state values
  const email = state?.email || paramsEmail;

  useEffect(() => {
    if (!email) {
      navigate(AUTH_ROUTES.APP_LOGIN);
    }

    setTimeout(() => {
      navigate(AUTH_ROUTES.APP_LOGIN);
    }, 5000);
  }, []);

  return (
    <div className=" ">
      <div className="container-fluid main-login-header">
        <div className="row">{/* <BlinkovoHeader /> */}</div>
      </div>

      <div className="container custom-email-verify auth-wrapper auth-basic register-page">
        <div>
          <CardTitle tag="h2" className="fw-bold mb-1">
            <Spinner color="primary" />{" "}
            {!isUser
              ? "Setting up your workspace ..."
              : "Processing the verification of your email"}
          </CardTitle>
          <p>
            {/* We are personalize your onboarding. */}
            Please hang on, we are personalizing your workspace.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SettingWorkspace;
