/* eslint-disable no-confusing-arrow */
import classNames from "classnames";
import clsx from "clsx";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Navbar,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Button,
  OffcanvasHeader,
  OffcanvasBody,
  Offcanvas,
} from "reactstrap";
import { ReactComponent as Logo } from "../../../assets/images/logo/logo.svg";
import { AUTH_ROUTES } from "../../../constant/route";
// import themeConfig from "../../../configs/themeConfig";

const Burger = ({ open, ToggleSidebar }) => {
  return (
    <button
      open={open}
      onClick={() => ToggleSidebar()}
      className="hamburger d-flex d-lg-none"
    >
      <div className={open ? "isOpen" : ""} />
      <div className={open ? "isOpen" : ""} />
      <div className={open ? "isOpen" : ""} />
    </button>
  );
};

const Header = ({ admin }) => {
  // Navigate
  const navigate = useNavigate();

  // ** state
  const [open, setOpen] = useState(false);

  const [colorChange, setColorchange] = useState(false);
  // const [canvasPlacement, setCanvasPlacement] = useState("start");
  // const [canvasOpen, setCanvasOpen] = useState(false);

  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  const ToggleSidebar = () => {
    if (open) {
      setOpen(false);
      // setCanvasPlacement("start");
    } else {
      setOpen(true);
      // setCanvasPlacement("end");
    }
    // open === true ? setOpen(false) : setOpen(true);
  };

  if (open) {
    window.document.body.classList.add("Navbaropen");
  } else {
    window.document.body.classList.remove("Navbaropen");
  }

  // const toggleCanvasStart = () => {
  //   setCanvasPlacement("start");
  //   setCanvasOpen(!canvasOpen);
  // };
  // const toggleCanvasEnd = () => {
  //   setCanvasPlacement("end");
  //   setCanvasOpen(!canvasOpen);
  // };

  return (
    <>
      <div>
        <Navbar
          className={clsx("nav-bar navbar-wrap", colorChange ? "isScroll" : "")}
        >
          <div className="col-2 col-lg-5 col-xl-5 col-xxl-4 d-flex">
            {admin ? (
              ""
            ) : (
              <>
                {/* <div className="header_icons">
                  <Burger
                    open={open}
                    setOpen={setOpen}
                    ToggleSidebar={ToggleSidebar}
                  />
                </div>

                <div className="d-none d-lg-flex">
                  <a href="https://blinkovo.com/" className="nav-link">
                    Pricing
                  </a>
                  <a href="https://blinkovo.com/" className="nav-link">
                    Blog
                  </a>
                  <a href="https://blinkovo.com/" className="nav-link">
                    About Us
                  </a>
                </div> */}
              </>
            )}
          </div>
          <NavLink
            to={admin ? "/admin/login" : "/"}
            className="col-4 col-lg-3  logo "
          >
            {/* <Logo /> */}
            <div className="blinkova-logo">
              {/* <span className="">
                <img src={themeConfig.app.appLogoImage} alt="logo" />
              </span>
              <h2 className="brand-text mb-0">{themeConfig.app.appName}</h2> */}
            </div>
          </NavLink>

          <div className="col-3 col-lg-4  d-flex justify-content-end align-items-center">
            {admin ? (
              ""
            ) : (
              <>
                <NavLink
                  to={AUTH_ROUTES.APP_LOGIN}
                  className="nav-link d-none d-lg-block"
                >
                  Log In
                </NavLink>
                <button
                  className="header-btn btn"
                  onClick={() => navigate(AUTH_ROUTES.APP_REGISTER)}
                >
                  Get started
                </button>
              </>
            )}
          </div>
        </Navbar>

        <div className="container-fluid d-none">
          <div className={`sidebar ${open === true ? "active" : ""}`}>
            <div className="side-header">
              <Burger
                open={open}
                setOpen={setOpen}
                ToggleSidebar={ToggleSidebar}
              />
              <Logo />
            </div>
          </div>
          <div
            className={`sidebar-overlay ${open === true ? "active" : ""}`}
            onClick={() => ToggleSidebar()}
          ></div>
        </div>
        {/* {bookModal && (
        <BookModal setBookModal={setBookModal} bookModal={bookModal} />
      )} */}
      </div>

      <Offcanvas direction="start" isOpen={open} toggle={ToggleSidebar}>
        <OffcanvasHeader className="canvas-header">
          <Burger open={open} setOpen={setOpen} ToggleSidebar={ToggleSidebar} />
        </OffcanvasHeader>
        <OffcanvasBody
          className={classNames("mx-0 flex-grow-0 canvas-body start")}
        >
          <div className="">
            <a href="https://blinkovo.com/" className="nav-link">
              Video services
            </a>
            <a href="https://blinkovo.com/" className="nav-link">
              Pricing
            </a>
            <a href="https://blinkovo.com/" className="nav-link">
              Blog
            </a>
            <a href="https://blinkovo.com/" className="nav-link">
              About Us
            </a>
          </div>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
};

export default Header;
