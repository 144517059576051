/* eslint-disable no-unused-vars */
import React from "react";
import FormReactSelect from "./FormReactSelect";
import FormTextInput from "./FormTextInput";
import FormTextTextarea from "./FormTextTextarea";
import FormDatePicker from "./FormDatePicker";
import FormCheckBox from "./FormCheckBox";
import FormRadioButton from "./FormRadioButton";
import FormReactMultiSelect from "./FormReactMultiSelect";

import { Label, UncontrolledTooltip } from "reactstrap";
import { HelpCircle } from "react-feather";
import FormFileUploader from "./FormFileUploader";
import moment from "moment";

export const DATA_FIELD = {
  HEADER: "header",
  PARAGRAPH: "paragraph",
  SELECT: "select",
  AUTOCOMPLETE: "autocomplete",
  TEXT: "text",
  TEXTAREA: "textarea",
  DATE: "date",
  RADIO_GROUP: "radio-group",
  CHECKBOX_GROUP: "checkbox-group",
  FILE: "file",
  BUTTON: "button",
  HIDDEN: "hidden",
  NUMBER: "number",
};

// update payload according {value, label} / [{value, label}] format
export const PayloadDataUpdate = ({ inputFieldArray, sendData }) => {
  const data = {};
  if (inputFieldArray) {
    inputFieldArray.forEach(({ type, values, name, multiple }) => {
      if (
        [
          DATA_FIELD.CHECKBOX_GROUP,
          DATA_FIELD.RADIO_GROUP,
          DATA_FIELD.SELECT,
          DATA_FIELD.AUTOCOMPLETE,
        ].includes(type)
      ) {
        const cleanedValues = values.map(({ selected, ...rest }) => rest) || [];
        if (type === DATA_FIELD.CHECKBOX_GROUP) {
          // check-box data payload update
          data[name] =
            cleanedValues.filter((r) => sendData[name].includes(r.value)) || [];
        } else if (type === DATA_FIELD.RADIO_GROUP) {
          // radio-button data payload update
          data[name] =
            cleanedValues?.find((r) => r.value === sendData[name]) || null;
        } else if (type === DATA_FIELD.SELECT && multiple) {
          data[name] =
            sendData[name].map(({ selected, ...rest }) => rest) || [];
        } else if (type === DATA_FIELD.SELECT) {
          data[name] = sendData[name]
            ? { value: sendData[name].value, label: sendData[name].label }
            : {};
        }
      }

      // * DATE FORMAT CHANGE
      if (DATA_FIELD.DATE === type) {
        data[name] = sendData[name]
          ? moment(sendData[name]).format("YYYY-MM-DD")
          : null;
      }
    });
  }
  return { ...sendData, ...data };
};

export const RenderDynamicFiled = (val) => {
  const { fields_data, index } = val;

  const renderFormField = (field) => {
    switch (field?.type) {
      case DATA_FIELD.SELECT:
      case DATA_FIELD.AUTOCOMPLETE:
        return field?.multiple ? (
          <FormReactMultiSelect fieldData={field} index={index} />
        ) : (
          <FormReactSelect fieldData={field} index={index} />
        );
      case DATA_FIELD.TEXT:
        return <FormTextInput fieldData={field} index={index} />;
      case DATA_FIELD.TEXTAREA:
        return <FormTextTextarea fieldData={field} index={index} />;
      case DATA_FIELD.RADIO_GROUP:
        return <FormRadioButton fieldData={field} index={index} />;
      case DATA_FIELD.CHECKBOX_GROUP:
        return <FormCheckBox fieldData={field} index={index} />;
      case DATA_FIELD.DATE:
        return <FormDatePicker fieldData={field} index={index} />;
      case DATA_FIELD.HEADER:
      case DATA_FIELD.PARAGRAPH:
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: `<${field?.subtype} class="m-0">${field?.label}</${field?.subtype}>`,
            }}
          />
        );
      case DATA_FIELD.FILE:
        return <FormFileUploader fieldData={field} index={index} />;
      // return (
      //   <h2 style={{ textDecoration: "underline", color: "red" }}>
      //     File Field Pending
      //   </h2>
      // );
      default:
        return null;
    }
  };
  return <>{renderFormField(fields_data) || fields_data?.type}</>;
};

export const RenderDynamicFiledLabel = ({ fieldData, extraData }) => {
  return (
    <>
      <Label className="form-label w-100" for={fieldData?.name}>
        {fieldData?.label}
        {fieldData?.required && " *"}
        {fieldData?.description && (
          <>
            <HelpCircle
              style={{ width: "16px", height: "16px", marginLeft: "5px" }}
              id={fieldData?.name}
            />
            <UncontrolledTooltip placement="right" target={fieldData?.name}>
              {fieldData?.description}
            </UncontrolledTooltip>
          </>
        )}
        {extraData && extraData}
      </Label>
    </>
  );
};

export const default_data_for_form = {
  "text-1718946483698-0": "ASDFASDFASDFASDFASDFASDFASDF",
  "textarea-1718946485086-0": "ADSFSDFADF",
  "select-1718946486781-0": {
    label: "Option 1",
    value: "option-1",
    selected: true,
  },
  "checkbox-group-1718946488808-0": [
    {
      label: "Label 2024062110391975301",
      value: "unique_value_20240621103919754461",
      selected: false,
    },
  ],
  "radio-group-1718946490262-0": {
    label: "Option 2",
    value: "option-2",
    selected: false,
  },
  "date-1718946492036-0": "2024-05-29",
  "text-1718946520313-0": "ASDASD",
  "textarea-1718946523915-0": "ASDASD",
  "select-1718946526876-0": {
    label: "Option 2",
    value: "option-2",
    selected: false,
  },
  "select-1718946635496": [
    {
      label: "Option 1",
      value: "option-1",
      selected: false,
    },
    {
      label: "Option 2",
      value: "option-2",
      selected: false,
    },
  ],
  "checkbox-group-1718946531345-0": [
    {
      label: "Label 20240621104106955501",
      value: "unique_value_20240621104106955461",
      selected: false,
    },
  ],
  "radio-group-1718946540122-0": {
    label: "Option 2",
    value: "option-2",
    selected: false,
  },
  "date-1718946543363-0": "2024-06-27",
};

export const input_field_array = [
  {
    type: "header",
    label: "Header",
    subtype: "h1",
  },
  {
    type: "paragraph",
    label: "Paragraph",
    subtype: "p",
  },
  {
    name: "text-1718946483698-0",
    type: "text",
    label: "Text Field",
    subtype: "text",
    required: false,
    className: "form-control",
    table_display: false,
  },
  {
    name: "textarea-1718946485086-0",
    type: "textarea",
    label: "Text Area",
    required: false,
    className: "form-control",
    table_display: false,
  },
  {
    name: "select-1718946486781-0",
    type: "select",
    label: "Select",
    values: [
      {
        label: "Option 1",
        value: "option-1",
        selected: true,
      },
      {
        label: "Option 2",
        value: "option-2",
        selected: false,
      },
      {
        label: "Option 3",
        value: "option-3",
        selected: false,
      },
    ],
    multiple: false,
    required: false,
    className: "form-control",
    table_display: false,
  },
  {
    name: "checkbox-group-1718946488808-0",
    type: "checkbox-group",
    label: "Checkbox Group",
    inline: false,
    values: [
      {
        label: "Option 1",
        value: "option-1",
        selected: false,
      },
      {
        label: "Label 2024062110391975301",
        value: "unique_value_20240621103919754461",
        selected: false,
      },
      {
        label: "Label 20240621103921663242",
        value: "unique_value_20240621103921663232",
        selected: false,
      },
    ],
    required: false,
    table_display: false,
  },
  {
    name: "radio-group-1718946490262-0",
    type: "radio-group",
    label: "Radio Group",
    inline: false,
    values: [
      {
        label: "Option 1",
        value: "option-1",
        selected: false,
      },
      {
        label: "Option 2",
        value: "option-2",
        selected: false,
      },
      {
        label: "Option 3",
        value: "option-3",
        selected: false,
      },
    ],
    required: false,
    table_display: false,
  },
  {
    name: "date-1718946492036-0",
    type: "date",
    label: "Date Field",
    subtype: "date",
    required: false,
    className: "form-control",
    table_display: false,
  },
  {
    type: "header",
    label: "==========================================================",
    subtype: "h3",
  },
  {
    name: "text-1718946520313-0",
    type: "text",
    label: "Text Field",
    subtype: "text",
    required: true,
    className: "form-control",
    description: "Text Field",
    placeholder: "Text Field",
    table_display: false,
  },
  {
    name: "textarea-1718946523915-0",
    type: "textarea",
    label: "Text Area",
    required: true,
    className: "form-control",
    description: "Text Area",
    placeholder: "Text Area",
    table_display: true,
  },
  {
    name: "select-1718946526876-0",
    type: "select",
    label: "Select",
    values: [
      {
        label: "Option 1",
        value: "option-1",
        selected: false,
      },
      {
        label: "Option 2",
        value: "option-2",
        selected: false,
      },
      {
        label: "Option 3",
        value: "option-3",
        selected: false,
      },
    ],
    multiple: false,
    required: true,
    className: "form-control",
    description: "Select",
    placeholder: "Select",
    table_display: false,
  },
  {
    name: "select-1718946635496",
    type: "select",
    label: "Multiple Select",
    values: [
      {
        label: "Option 1",
        value: "option-1",
        selected: false,
      },
      {
        label: "Option 2",
        value: "option-2",
        selected: false,
      },
      {
        label: "Option 3",
        value: "option-3",
        selected: false,
      },
    ],
    multiple: true,
    required: true,
    className: "form-control",
    description: "Multiple Select",
    placeholder: "Multiple Select",
    table_display: true,
  },
  {
    name: "checkbox-group-1718946531345-0",
    type: "checkbox-group",
    label: "Checkbox Group",
    inline: true,
    values: [
      {
        label: "Option 1",
        value: "option-1",
        selected: true,
      },
      {
        label: "Label 20240621104106955501",
        value: "unique_value_20240621104106955461",
        selected: false,
      },
      {
        label: "Label 20240621104107867462",
        value: "unique_value_20240621104107867992",
        selected: false,
      },
    ],
    required: true,
    description: "Checkbox Group",
    table_display: false,
  },
  {
    name: "radio-group-1718946540122-0",
    type: "radio-group",
    label: "Radio Group",
    inline: true,
    values: [
      {
        label: "Option 1",
        value: "option-1",
        selected: true,
      },
      {
        label: "Option 2",
        value: "option-2",
        selected: false,
      },
      {
        label: "Option 3",
        value: "option-3",
        selected: false,
      },
    ],
    required: true,
    description: "Radio Group",
    table_display: false,
  },
  {
    name: "date-1718946543363-0",
    type: "date",
    label: "Date Field",
    subtype: "date",
    required: true,
    className: "form-control",
    description: "Date Field",
    placeholder: "Date Field",
    table_display: true,
  },
];
