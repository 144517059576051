/* eslint-disable multiline-ternary */
import { useEffect, useState } from "react";
// import { selectThemeColors } from "@utils";
// ** Reactstrap Imports
import {
  Button,
  Card,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
// ** Custom Components
import InputPasswordToggle from "@components/input-password-toggle";
import Select from "react-select";

import { Controller, useForm } from "react-hook-form";
// import { useNavigate } from "react-router-dom";
// import { USER_ROUTES } from "../../../../../constant/route";

import { useDispatch, useSelector } from "react-redux";
// import { getUser } from "../../../redux/userSlice/user.thunk";

// ** Styles
import "@styles/react/libs/tables/react-dataTable-component.scss";

// ? yup
import { yupResolver } from "@hookform/resolvers/yup";
import { Check, Watch } from "react-feather";
import toast from "react-hot-toast";
import * as yup from "yup";
import { getErrorMsg } from "../../../common/ErrorMessages/errorMessage";
import LineSkelaton from "../../../common/skeleton/LineSkelaton";
import { USER_ROUTES } from "../../../constant/route";
import { getUser } from "../../../redux/userSlice/user.thunk";
import { editUserInfo } from "../../../services/user.services";
import ToastContent from "../../components/toast/toast";
import { ROLE } from "../../../constant/constant";

// import LineSkelaton from "../../../common/skelaton/LineSkelaton";

const AdminInfo = () => {
  // ** Get Data From Store
  const store = useSelector((state) => state.user);

  const currentUser = useSelector((state) => state.currentUser);

  // **Dispatch
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  //   const navigate = useNavigate();
  // ** Hook

  // ** LoginSchema
  const defaultValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  };

  const Schema = yup.object({
    firstName: yup
      .string()
      .required("First name is Required")
      .max(50, "First name should be not greater than 50 character")
      .trim()
      .typeError("firstname is Required")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    lastName: yup
      .string()
      .required("Last name is Required")
      .max(50, "Last name should be not greater than 50 character")
      .trim()
      .typeError("Last name is Required")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    email: yup
      .string()
      .email("Please enter valid email address")
      .required("Email is Required")
      .max(60, "Email should be not greater than 60 character")
      .trim()
      .typeError("Email is Required"),
    companyName: yup
      .string()
      .required("Company Name is Required")
      .max(50, "Company Website should not more than 50 character")
      .typeError("Company Name is Required"),
  });

  const {
    control,
    // setError,
    reset,
    // watch,
    handleSubmit,
    // setValue,
    formState: { errors, isDirty },
  } = useForm({ defaultValues, resolver: yupResolver(Schema) });

  const onSubmit = (data) => {
    const val = {
      id: store?.data?.data?.personal_info?.id,
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      // unique_url: data.uniqueUrl,
      company_name: data.companyName,
      company_website: data.companyUrl,
      role_id: currentUser?.currentuserdata?.data?.role_id,
      address: data.address,
    };

    // if (Object.values(data).every((field) => field.length > 0)) {
    setIsLoading(true);
    editUserInfo(val)
      .then((res) => {
        dispatch(getUser());
        toast((t) => (
          <ToastContent
            t={t.id}
            message={res?.data?.message}
            icon={<Check size={12} />}
          />
        ));
        setIsLoading(false);
      })
      .catch((err) => {
        getErrorMsg(err);
        setIsLoading(false);
      });
    // } else {
    //   for (const key in data) {
    //     if (data[key].length === 0) {
    //       setError(key, {
    //         type: "manual",
    //       });
    //     }
    //   }
    // }
  };

  useEffect(() => {
    reset({
      firstName: store?.data?.data?.personal_info?.first_name,
      lastName: store?.data?.data?.personal_info?.last_name,
      email: store?.data?.data?.personal_info?.email,
      uniqueUrl: window.location.origin,
      companyName: store?.data?.data?.personal_info?.company_name,
      companyUrl: store?.data?.data?.personal_info?.company_website,
      address: store?.data?.data?.personal_info?.address,
    });
  }, [store?.data?.data?.personal_info]);

  return (
    <>
      {store.isLoading ? (
        <div>
          {[...Array(5)].map((_, i) => (
            <LineSkelaton key={i} />
          ))}
        </div>
      ) : (
        <Card className="p-1 p-md-0">
          <div className="pb-2 pt-50 px-sm-2">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row className="gy-1 pt-75">
                <Col md={6} xs={12}>
                  <Label className="form-label" for="firstName">
                    First Name
                  </Label>
                  <Controller
                    control={control}
                    id="firstName"
                    name="firstName"
                    render={({ field }) => (
                      <Input
                        {...field}
                        id="firstName"
                        placeholder="John"
                        invalid={errors.firstName && true}
                      />
                    )}
                  />
                  {errors?.firstName && (
                    <FormFeedback className="d-block">
                      {errors.firstName.message}
                    </FormFeedback>
                  )}
                </Col>
                <Col md={6} xs={12}>
                  <Label className="form-label" for="lastName">
                    Last Name
                  </Label>
                  <Controller
                    control={control}
                    id="lastName"
                    name="lastName"
                    render={({ field }) => (
                      <Input
                        {...field}
                        id="lastName"
                        placeholder="Doe"
                        invalid={errors.lastName && true}
                      />
                    )}
                  />
                  {errors?.lastName && (
                    <FormFeedback className="d-block">
                      {errors.lastName.message}
                    </FormFeedback>
                  )}
                </Col>

                <Col md={6} xs={12}>
                  <Label className="form-label" for="email">
                    Email
                  </Label>
                  <Controller
                    control={control}
                    id="email"
                    name="email"
                    render={({ field }) => (
                      <Input
                        disabled
                        {...field}
                        type="email"
                        placeholder="john@domain.com"
                        invalid={errors.email && true}
                      />
                    )}
                  />
                  {errors?.email && (
                    <FormFeedback className="d-block">
                      {errors.email.message}
                    </FormFeedback>
                  )}
                </Col>

                <Col md={6} xs={12}>
                  <Label className="form-label" for="email">
                    Unique Url
                  </Label>
                  <Controller
                    control={control}
                    id="uniqueUrl"
                    name="uniqueUrl"
                    render={({ field }) => (
                      <Input disabled {...field} type="uniqueUrl" />
                    )}
                  />
                </Col>

                <Col md={6} xs={12}>
                  <div className="mb-1">
                    <Label className="form-label" for="companyName">
                      Company Name
                    </Label>
                    <Controller
                      id="companyName"
                      name="companyName"
                      control={control}
                      render={({ field }) => (
                        <Input
                          placeholder="john studio"
                          invalid={errors.companyName && true}
                          {...field}
                        />
                      )}
                    />
                    {errors.companyName ? (
                      <FormFeedback>{errors.companyName.message}</FormFeedback>
                    ) : null}
                  </div>
                </Col>

                <Col md={6} xs={12}>
                  <div className="mb-1">
                    <Label className="form-label" for="companyUrl">
                      Company Website
                    </Label>
                    <Controller
                      id="companyUrl"
                      name="companyUrl"
                      control={control}
                      render={({ field }) => (
                        <Input
                          placeholder="https://johndoe.com"
                          // invalid={errors.companyUrl && true}
                          {...field}
                        />
                      )}
                    />
                    {/* {errors.companyUrl ? (
                      <FormFeedback>{errors.companyurl.message}</FormFeedback>
                    ) : null} */}
                  </div>
                </Col>

                {[ROLE.SUPER_ADMIN].includes(
                  currentUser?.currentuserdata?.data?.role_id
                ) && (
                  <Col md={12} xs={12}>
                    <Label className="form-label" for="address">
                      Address
                    </Label>
                    <Controller
                      defaultValue=""
                      control={control}
                      id="address"
                      name="address"
                      render={({ field }) => (
                        <Input
                          {...field}
                          rows={3}
                          type="textarea"
                          maxLength={100}
                          placeholder="Address"
                          invalid={errors.address && true}
                        />
                      )}
                    />
                    {/* {errors?.address && (
                      <FormFeedback className="d-block">
                        {errors.address.message}
                      </FormFeedback>
                    )} */}
                  </Col>
                )}

                <Col xs={12} className="text-start text-md-end mt-2 pt-50">
                  <Button
                    type="submit"
                    className="me-1"
                    color="primary"
                    disabled={isLoading || !isDirty}
                  >
                    Save changes
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Card>
      )}
    </>
  );
};

export default AdminInfo;
