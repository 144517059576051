// /* eslint-disable multiline-ternary */

import moment from "moment";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Check } from "react-feather";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getErrorMsg } from "../../../../common/ErrorMessages/errorMessage";
import { etaEstimationDate } from "../../../../services/projectManagementService";
import ToastContent from "../../../components/toast/toast";
import { checkUserID } from "./utils";
import { ADMIN_ROUTES } from "../../../../constant/route";

const CustomDateEditor = forwardRef((props, ref) => {
  const { data, api } = props;
  const location = useLocation();
  const isDisabled = location.pathname === ADMIN_ROUTES.ARCHIVED_PROJECTS;

  const [selectedDate, setSelectedDate] = useState(
    data?.estimated_date ? moment(data?.estimated_date).toDate() : null
  );

  const currentUser = useSelector(
    (state) => state?.currentUser?.currentuserdata?.data
  );

  useImperativeHandle(ref, () => ({
    getValue: () => selectedDate,
  }));

  const handleDateChange = (date) => {
    setSelectedDate(date);

    api.stopEditing();
    const payload = {
      id: props?.data?.sub_project_id,
      estimated_date: moment(date).format("YYYY-MM-DD"),
    };

    etaEstimationDate(payload)
      .then((res) => {
        toast((t) => (
          <ToastContent
            t={t.id}
            message={res?.message}
            icon={<Check size={12} />}
            color="success"
          />
        ));
      })
      .catch((err) => {
        console.log("err: ", err);
        getErrorMsg(err);
      });
  };
  const CustomDatePickerInput = ({ value, onClick }) => (
    <input
      type="text"
      value={value}
      onClick={onClick}
      // style={{
      //   color: `${ProjectStatusColorCode.get(data?.project_status)}`,
      // }}
      className="form-control custom-group-date"
      placeholder="ETA"
      disabled={checkUserID(currentUser?.role_id)}
    />
  );
  return (
    <span
      style={{
        ...((checkUserID(currentUser?.role_id) || isDisabled) && {
          opacity: 0.7,
          pointerEvents: "none",
        }),
      }}
    >
      {/* <ReactDatePicker
      placeholderText="ETA"
      selected={selectedDate}
      onChange={handleDateChange}
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      className="form-control custom-group-date"
      dateFormat="MM/dd/yy"
      minDate={new Date()}
      calendarClassName="custom-datepicker-calendar"
      popperClassName="custom-datepicker-popper"
      portalId="root-portal"
    /> */}
      <ReactDatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        dateFormat="MM/dd/yy"
        minDate={new Date()}
        calendarClassName="custom-datepicker-calendar"
        popperClassName="custom-datepicker-popper"
        portalId="root-portal"
        customInput={<CustomDatePickerInput />}
        disabled={checkUserID(currentUser?.role_id) || isDisabled}
      />
    </span>
  );
});

export default CustomDateEditor;
