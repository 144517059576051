/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// ** Reactstrap Imports
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  // Badge,
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Row,
  InputGroup,
  InputGroupText,
  Button,
  Badge,
  Form,
  CardHeader,
  FormFeedback,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
// import PricingCalculatorModel from "../myprojects/createProjectSteps/PricingCalculatorModel";
import classnames from "classnames";

// ** Third Party Components
import Select from "react-select";

// ** Utils
import { selectThemeColors } from "@utils";

// ** Styles
import "@styles/react/libs/react-select/_react-select.scss";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  USER_PLAN_TYPE,
  checkUserPlanType,
  createOrderMeta,
  getEventDayPercentage,
  orderProjectData,
  priceForUser,
} from "../myprojects/createProjectSteps/utils";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import ToastContent from "../../components/toast/toast";
import { Check, X } from "react-feather";
import { getPaymentMethod } from "../../../redux/userSlice/user.thunk";
import StripeElement from "../../../common/stripeComponent/StripeElement";
import { getErrorMsg } from "../../../common/ErrorMessages/errorMessage";
import TermAndCondition from "../../components/common/TermAndCondition";
import { numberWithCommas } from "../../../common/functions/helperFunctions";
import Loader from "../../../common/Loader/Loader";
import UILoader from "@components/ui-loader";
// import WPCalculator from "../../WPCalc/WPCalculator";
// ** Store & Actions
import { handleLogout } from "@store/authentication";
import { useLocation, useNavigate } from "react-router-dom";
import {
  addDiscountAdditionalEdit,
  getProjectEdit,
  orderForAlacarte,
  orderForSubscriber,
} from "../../../services/AdditionalEdit/additionalEdit.services";
import { labelOptions, languageOptions, resetHelper } from "./utils";
import { getCurrentUser } from "../../../redux/currentUserSlice/currentUser.thunk";
import { USER_ROUTES } from "../../../constant/route";

const schema = yup.object().shape({
  footage_size: yup
    .string()
    .matches(
      /^(?!0(\.0{1,2})?$)\d{1,5}(\.\d{1,2})?$/,
      "Please Enter Valid Footage size"
    )
    .required("Footage size is Required"),

  wedding_language: yup.object().required("Language is Required").nullable(),
  language_information: yup
    .string()
    .when("wedding_language", {
      is: (wedding_language) => wedding_language?.value === "other",
      then: yup
        .string()
        .required("Language Information is Required")
        .nullable(),
    })
    .nullable(),
  event_last_day: yup.object().required("Event day is Required").nullable(),
});

const AdditionalEdit = () => {
  const navigate = useNavigate();
  // const { stepper } = props;
  // ** State
  const location = useLocation();

  const [open, setOpen] = useState("1");
  // const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [openTermModel, setOpenTermModel] = useState(false);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [openLang, setOpenLang] = useState(false);
  const getDiscountCount = JSON.parse(localStorage.getItem("getDiscountCount"));
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.currentUser);
  const paymentData = useSelector((state) => state.user.paymentMethods);
  const userId = currentUser?.currentuserdata?.data?.id;
  const userPlanType = currentUser?.currentuserdata?.data?.user_subscription_id;

  // const [modalOpen, setModalOpen] = useState(false);
  const [isAlreadySelected, setIsAlreadySelected] = useState({});
  const [applyLoading, setApplyLoading] = useState(false);

  const defaultValues = {
    footage_size: "",
    event_last_day: "",
    wedding_language: "",
    insta_video: false,
    highlight_video: {
      min_2_3: false,
      min_4_6: false,
    },
    narrative_highlight_video: {
      min_3_5: false,
      min_6_8: false,
      min_9_12: false,
    },
    cinematic_film: {
      min_13_15: false,
      min_16_20: false,
      min_21_25: false,
    },
    documentary_edits: {
      ceremony_MCE: false,
      reception_MCE: false,
      dances_only: false,
      speeches_only: false,
      ceremony_reception_MCE: false,
      full_day_edit: false,
    },
    discount_code: "",
    additional_note: "",
  };

  const {
    // getValues,
    setValue,
    reset,
    // control,
    clearErrors,
    setError,
    watch,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const toggle = (id) => {
    open === id ? setOpen() : setOpen(id);
  };

  const selectedCheckboxes = watch(
    orderProjectData?.map((row) => row.fieldName)
  );

  const selectEventLastDay = watch("event_last_day");
  const selectLanguageField = watch("wedding_language");
  const calculateTotalAmount = () => {
    let totalAmount = 0;
    if (checkUserPlanType(userPlanType) === USER_PLAN_TYPE.SUBSCRIBER) {
      orderProjectData.forEach((checkbox) => {
        if (watch(checkbox.fieldName)) {
          totalAmount += parseInt(checkbox.forSubscriber.match(/\d+/)[0]);
        }
      });
      if (selectEventLastDay?.value) {
        const percentage = getEventDayPercentage(selectEventLastDay?.value);
        let eventDayTotal = 0;
        orderProjectData.forEach((checkbox) => {
          if (watch(checkbox.fieldName)) {
            eventDayTotal = Math.round(
              (parseInt(checkbox.forSubscriber.match(/\d+/)[0]) * percentage) /
                100
            );
            totalAmount = totalAmount + eventDayTotal;
          }
        });
      }

      return totalAmount;
    } else {
      orderProjectData.forEach((checkbox) => {
        if (watch(checkbox.fieldName)) {
          totalAmount += parseInt(checkbox.forAlacarte.match(/\d+/)[0]);
        }
      });

      if (selectEventLastDay?.value) {
        const percentage = getEventDayPercentage(selectEventLastDay?.value);
        const eventDayTotal = (totalAmount * percentage) / 100;
        totalAmount = totalAmount + eventDayTotal;
      }

      return totalAmount;
    }
  };

  const handleSelectedItems = () => {
    let maxValue = 0;
    const selectItems = orderProjectData.filter((item) => {
      return watch(item.fieldName);
    });

    selectItems.map((el) => {
      const price = priceForUser(
        userPlanType,
        el.fieldName,
        selectEventLastDay?.value
      ).props?.children;
      // const valueFromObject = parseInt(price.match(/\d+/)[0]);
      const valueFromObject = Number(price?.replace("$", ""));

      maxValue = Math.max(maxValue, valueFromObject);
    });

    return maxValue.toFixed(2);
  };

  const handleSelectedExpensiveItems = () => {
    let maxValue = 0;
    let expensiveItem = "";

    const sortArr = orderProjectData.sort((a, b) => a.priceItem - b.priceItem);

    const selectItems = sortArr.filter((item) => {
      return watch(item.fieldName);
    });

    selectItems.map((el) => {
      const price = priceForUser(
        userPlanType,
        el.fieldName,
        selectEventLastDay?.value
      ).props?.children;
      // const valueFromObject = parseInt(price.match(/\d+/)[0]);
      const valueFromObject = Number(price?.replace("$", ""));

      maxValue = Math.max(maxValue, valueFromObject);

      expensiveItem = maxValue === valueFromObject ? el : null;
    });

    // const selectItemsName = orderProjectData.find((item) => {
    //   return maxValue === parseInt(item?.forAlacarte.match(/\d+/)[0]);
    // });

    return expensiveItem?.stringName || "--";
  };

  const handleDiscountItem = () => {
    let item = 0;
    if (discountPercentage) {
      const discountTotal = (handleSelectedItems() * discountPercentage) / 100;
      item = item - discountTotal;
    }
    return Math.abs(Number(item).toFixed(2));
  };

  const handleDiscount = () => {
    let amount = calculateTotalAmount().toFixed(2);
    if (discountPercentage) {
      const discountTotal = (handleSelectedItems() * discountPercentage) / 100;
      amount = amount - discountTotal.toFixed(2);
    }

    return Number(amount).toFixed(2);
  };

  const cartItemCount = () => {
    const trueCount = selectedCheckboxes.filter(
      (value) => value === true
    ).length;
    return trueCount;
  };

  const addDiscountCode = async () => {
    const updatedDiscountCount = JSON.parse(
      localStorage.getItem("getDiscountCount")
    );

    try {
      setIsLoading(true);
      const payload = {
        promotion_code: watch("discount_code"),
        create_project_id: location.state,
      };

      if (updatedDiscountCount < 9) {
        const res = await addDiscountAdditionalEdit(payload);
        setDiscountPercentage(res?.data?.percentage);
        localStorage.setItem("getDiscountCount", 0);
        toast((t) => (
          <ToastContent
            t={t.id}
            message={res?.message}
            icon={<Check size={12} />}
          />
        ));
      } else {
        toast((t) => (
          <ToastContent
            t={t.id}
            message="You will be logged out of the system due to 10 consecutive incorrect entries in the discount coupon. Kindly log back in and ensure accurate coupon details are entered."
            color="danger"
            icon={<X size={12} />}
          />
        ));
        setTimeout(() => {
          dispatch(handleLogout());
          dispatch({ type: "RESET_STORE" });
          window.open("/", "_self");
        }, 2000);
      }
    } catch (err) {
      const count = updatedDiscountCount + 1;
      localStorage.setItem("getDiscountCount", count);

      getErrorMsg(err);
      setDiscountPercentage(0);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      dispatch(getPaymentMethod(userId));
    }

    if (!getDiscountCount) {
      localStorage.setItem("getDiscountCount", 0);
    }
  }, [userId]);

  useEffect(() => {
    cartItemCount();
  }, [selectedCheckboxes]);

  useEffect(() => {
    calculateTotalAmount();
  }, [selectedCheckboxes, selectEventLastDay?.value, discountPercentage]);

  const handelWeddingLanguage = (field) => {
    if (field === "english") {
      return {
        wedding_language: languageOptions.find(
          (item) => item.value === "english"
        ),
        language_information: null,
      };
    }
    if (field !== "english" && field === null) {
      return { wedding_language: null, language_information: null };
    }

    if (field && field !== "english") {
      return {
        wedding_language: languageOptions.find(
          (item) => item.value === "other"
        ),
        language_information: field,
      };
    }
  };

  const handleProjectApiCall = () => {
    getProjectEdit(location?.state)
      .then((res) => {
        setIsAlreadySelected(resetHelper(res?.data));
        // reset(resetHelper(res?.data));
        reset({
          footage_size: res?.data?.footage_size,
          event_last_day: res?.data?.event_last_day
            ? labelOptions.find(
                (item) => item.value === String(res?.data?.event_last_day)
              )
            : null,
          ...handelWeddingLanguage(res?.data?.wedding_language),
          additional_note: res?.data?.addtitional_note,
        });
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };

  useEffect(() => {
    handleProjectApiCall();
  }, []);

  const SendData = (data) => {
    let value = {
      id: location.state,
      order_meta: JSON.stringify(createOrderMeta(data)),
      // footage_size: data.footage_size,
      wedding_language:
        data?.wedding_language?.value === "other"
          ? data?.language_information
          : data?.wedding_language?.value,
      // event_last_day: data.event_last_day?.value,
      addtitional_note: data.additional_note ? data.additional_note : null,
    };

    if (checkUserPlanType(userPlanType) === USER_PLAN_TYPE.ALACARTE) {
      value = {
        ...value,
        discount_code: discountPercentage ? data.discount_code : "",
      };
    }

    if (cartItemCount()) {
      // handleNext(value);
      setApplyLoading(true);
      const getApiName =
        checkUserPlanType(userPlanType) === USER_PLAN_TYPE.ALACARTE
          ? orderForAlacarte
          : orderForSubscriber;
      getApiName(value)
        .then((res) => {
          if (res?.data?.redirect_to) {
            window.open(res?.data?.redirect_to, "_self");
          } else {
            toast((t) => (
              <ToastContent
                t={t.id}
                message={res?.message}
                icon={<Check size={12} />}
              />
            ));
            dispatch(getCurrentUser());
            navigate(USER_ROUTES.MY_PROJECTS);
          }
        })
        .catch((err) => {
          console.log("err: ", err);
          setApplyLoading(false);
          toast((t) => (
            <ToastContent
              t={t.id}
              message={err.response?.data?.message}
              color="danger"
              icon={<X size={12} />}
            />
          ));
        })
        .finally(() => {
          setApplyLoading(false);
        });
    } else {
      toast((t) => (
        <ToastContent
          t={t.id}
          message="Select at least one of the choice"
          color="danger"
          icon={<X size={12} />}
        />
      ));
    }
  };

  const onSubmit = async () => {
    // if (!isCheck && cartItemCount()) {  // ** previous code of RK

    if (cartItemCount()) {
      setOpenTermModel(true);
    } else {
      toast((t) => (
        <ToastContent
          t={t.id}
          message="Select at least one of the choice"
          color="danger"
          icon={<X size={12} />}
        />
      ));
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <UILoader blocking={applyLoading} loader={<Loader />}>
          <Row className="">
            <Col xs={12} md={12} lg={8}>
              <Card className="mb-1">
                <CardHeader>
                  <h5>PLEASE SELECT THE TYPE OF VIDEO YOU NEED</h5>
                </CardHeader>
                <CardBody>
                  <Col xs={12} sm={8} className="mb-1">
                    <Label className="form-label">
                      Footage Size (so we can be sure we received all of it!)
                    </Label>
                    {/* 2*/}
                    <Controller
                      name="footage_size"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: true,
                        pattern: {
                          value: /^\d+(\.\d{1,2})?$/,
                          message:
                            "Please enter a valid float value with two decimal places",
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          type="text"
                          disabled
                          placeholder="Specify your footage size in GB"
                          maxLength={8}
                          onChange={(e) => {
                            const pattern =
                              /^(?!0(\.0{1,2})?$)\d{0,5}(\.\d{0,2})?$/; // Modified regex
                            if (pattern.test(e.target.value)) {
                              setValue("footage_size", e.target.value);
                              setError("footage_size", "");
                            } else if (e.target.value === "") {
                              setValue("footage_size", "");
                              setError("footage_size", "");
                            } else {
                              setError("footage_size", "Invalid input");
                            }
                          }}
                        />
                      )}
                    />

                    {errors.footage_size && (
                      <FormFeedback>{errors.footage_size.message}</FormFeedback>
                    )}
                  </Col>
                  {/* start-ad */}
                  <Col xs={12} sm={8} className="mb-1">
                    <Label className="form-label">
                      What language is this wedding in? (If not in English,
                      please email us at hello@blinkovo.com before placing your
                      order to see if we can edit)
                    </Label>
                    <Controller
                      id="wedding_language"
                      name="wedding_language"
                      control={control}
                      render={({ field: { value, ...fieldProps } }) => (
                        <Select
                          {...fieldProps}
                          id="task-labels"
                          isDisabled={isAlreadySelected?.wedding_language}
                          isClearable={false}
                          options={languageOptions}
                          className="react-select"
                          classNamePrefix="select"
                          theme={selectThemeColors}
                          value={languageOptions.find(
                            (c) => c.value === value?.value
                          )}
                          // components={{ Option: LabelOptions }}
                          onChange={(data) => {
                            setValue("wedding_language", data);
                            if (data.value === "other") {
                              setOpenLang(true);
                            }
                            if (data) {
                              setError("wedding_language", "");
                            }
                          }}
                        />
                      )}
                    />
                    {errors.wedding_language && (
                      <FormFeedback>
                        {errors.wedding_language.message}
                      </FormFeedback>
                    )}
                  </Col>
                  {/* other lang selected */}
                  {selectLanguageField?.value === "other" && (
                    <Col xs={12} sm={8} className="mb-1">
                      <Label className="form-label">Language Information</Label>
                      <Controller
                        name="language_information"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <Input
                            {...field}
                            disabled={isAlreadySelected?.wedding_language}
                            type="text"
                            placeholder="Language Information"
                            maxLength={15}
                          />
                        )}
                      />
                      {errors.language_information && (
                        <FormFeedback>
                          {errors.language_information.message}
                        </FormFeedback>
                      )}
                    </Col>
                  )}
                  {/* end-ad */}
                  <Col xs={12} sm={8} className="mb-1">
                    <Label className="form-label">
                      How many days did the event last?
                    </Label>

                    <Controller
                      id="event_last_day"
                      name="event_last_day"
                      control={control}
                      render={({ field: { value, ...fieldProps } }) => (
                        <Select
                          {...fieldProps}
                          isDisabled
                          // defaultValue={}
                          id="task-labels"
                          isClearable={false}
                          options={labelOptions}
                          className="react-select"
                          classNamePrefix="select"
                          theme={selectThemeColors}
                          value={labelOptions.find(
                            (c) => c.value === value?.value
                          )}
                        />
                      )}
                    />
                    {errors.event_last_day && (
                      <FormFeedback>
                        {errors.event_last_day.message}
                      </FormFeedback>
                    )}
                  </Col>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <Accordion
                    className="accordion-margin"
                    open={open}
                    toggle={toggle}
                  >
                    {/* 1 */}
                    <AccordionItem>
                      <AccordionHeader targetId="1">
                        Instagram video
                      </AccordionHeader>
                      <AccordionBody accordionId="1">
                        <div className="order-accordian">
                          <div className="order-info">
                            <div className="form-check form-check-inline">
                              <Controller
                                name="insta_video"
                                control={control}
                                render={({ field }) => (
                                  <>
                                    <Input
                                      checked={isAlreadySelected?.insta_video}
                                      disabled={isAlreadySelected?.insta_video}
                                      type="checkbox"
                                      id="basic-cb-checked"
                                      {...field}
                                    />
                                    <Label
                                      for="basic-cb-checked"
                                      className="form-check-label ms-1"
                                    >
                                      1 Minute
                                    </Label>
                                  </>
                                )}
                              />
                              <div className="ms-1">
                                {priceForUser(
                                  userPlanType,
                                  "insta_video",
                                  selectEventLastDay?.value
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </AccordionBody>
                    </AccordionItem>

                    {/* 2 */}
                    <AccordionItem>
                      <AccordionHeader targetId="2">
                        Music Highlight Video – Music only
                      </AccordionHeader>
                      <AccordionBody accordionId="2">
                        <div className="order-accordian">
                          <div className="order-info">
                            <div className="form-check form-check-inline">
                              <Controller
                                name="highlight_video[min_2_3]"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                  <>
                                    <Input
                                      checked={
                                        isAlreadySelected?.highlight_video
                                          ?.min_2_3
                                      }
                                      disabled={
                                        isAlreadySelected?.highlight_video
                                          ?.min_2_3
                                      }
                                      type="checkbox"
                                      id="min_2_3"
                                      {...field}
                                    />
                                    <Label
                                      for="min_2_3"
                                      className="form-check-label ms-1"
                                    >
                                      2-3 Minutes
                                    </Label>
                                  </>
                                )}
                              />
                              <div className="ms-1">
                                {priceForUser(
                                  userPlanType,
                                  "highlight_video[min_2_3]",
                                  selectEventLastDay?.value
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="order-info">
                            <div className="form-check form-check-inline">
                              <Controller
                                name="highlight_video[min_4_6]"
                                control={control}
                                defaultValue=""
                                rules={{ required: true }}
                                render={({ field }) => (
                                  <>
                                    <Input
                                      checked={
                                        isAlreadySelected?.highlight_video
                                          ?.min_4_6
                                      }
                                      disabled={
                                        isAlreadySelected?.highlight_video
                                          ?.min_4_6
                                      }
                                      type="checkbox"
                                      id="min_4_6"
                                      {...field}
                                    />
                                    <Label
                                      for="min_4_6"
                                      className="form-check-label ms-1"
                                    >
                                      4-6 Minutes
                                    </Label>
                                  </>
                                )}
                              />
                              <div className="ms-1">
                                {priceForUser(
                                  userPlanType,
                                  "highlight_video[min_4_6]",
                                  selectEventLastDay?.value
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </AccordionBody>
                    </AccordionItem>

                    {/* 3 */}
                    <AccordionItem>
                      <AccordionHeader targetId="3">
                        Narrative Highlight video – Music + Dialogue
                      </AccordionHeader>
                      <AccordionBody accordionId="3">
                        <div className="order-accordian">
                          <div className="order-info">
                            <Controller
                              name="narrative_highlight_video[min_3_5]"
                              control={control}
                              defaultValue={false}
                              render={({ field }) => (
                                <div className="form-check form-check-inline">
                                  <Input
                                    checked={
                                      isAlreadySelected
                                        ?.narrative_highlight_video?.min_3_5
                                    }
                                    disabled={
                                      isAlreadySelected
                                        ?.narrative_highlight_video?.min_3_5
                                    }
                                    type="checkbox"
                                    id="min_3_5"
                                    {...field}
                                  />
                                  <Label
                                    for="min_3_5"
                                    className="form-check-label ms-1"
                                  >
                                    3-5 Minutes
                                  </Label>
                                  <div className="ms-1">
                                    {priceForUser(
                                      userPlanType,
                                      "narrative_highlight_video[min_3_5]",
                                      selectEventLastDay?.value
                                    )}
                                  </div>
                                </div>
                              )}
                            />
                            {/* </div> */}
                          </div>
                          <div className="order-info">
                            <Controller
                              name="narrative_highlight_video[min_6_8]"
                              control={control}
                              render={({ field }) => (
                                <div className="form-check form-check-inline">
                                  <Input
                                    checked={
                                      isAlreadySelected
                                        ?.narrative_highlight_video?.min_6_8
                                    }
                                    disabled={
                                      isAlreadySelected
                                        ?.narrative_highlight_video?.min_6_8
                                    }
                                    type="checkbox"
                                    id="min_6_8"
                                    {...field}
                                  />
                                  <Label
                                    for="min_6_8"
                                    className="form-check-label ms-1"
                                  >
                                    6-8 Minutes
                                  </Label>
                                  <div className="ms-1">
                                    {priceForUser(
                                      userPlanType,
                                      "narrative_highlight_video[min_6_8]",
                                      selectEventLastDay?.value
                                    )}
                                  </div>
                                </div>
                              )}
                            />
                          </div>
                          <div className="order-info">
                            <div className="form-check form-check-inline">
                              <Controller
                                control={control}
                                name="narrative_highlight_video[min_9_12]"
                                defaultValue={false}
                                render={({ field }) => (
                                  <Input
                                    checked={
                                      isAlreadySelected
                                        ?.narrative_highlight_video?.min_9_12
                                    }
                                    disabled={
                                      isAlreadySelected
                                        ?.narrative_highlight_video?.min_9_12
                                    }
                                    type="checkbox"
                                    id="min_9_12"
                                    {...field}
                                  />
                                )}
                              />
                              <Label
                                for="min_9_12"
                                className="form-check-label ms-1"
                              >
                                9-12 Minutes
                              </Label>
                              <div className="ms-1">
                                {priceForUser(
                                  userPlanType,
                                  "narrative_highlight_video[min_9_12]",
                                  selectEventLastDay?.value
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </AccordionBody>
                    </AccordionItem>

                    {/* 4 */}
                    <AccordionItem>
                      <AccordionHeader targetId="4">
                        Cinematic Feature Film – Music + Dialogue
                      </AccordionHeader>
                      <AccordionBody accordionId="4">
                        <div className="order-accordian">
                          <div className="order-info">
                            <div className="form-check form-check-inline">
                              <Controller
                                control={control}
                                name="cinematic_film[min_13_15]"
                                render={({ field }) => (
                                  <>
                                    <Input
                                      checked={
                                        isAlreadySelected?.cinematic_film
                                          ?.min_13_15
                                      }
                                      disabled={
                                        isAlreadySelected?.cinematic_film
                                          ?.min_13_15
                                      }
                                      type="checkbox"
                                      id="min_13_15"
                                      {...field}
                                    />
                                    <Label
                                      for="min_13_15"
                                      className="form-check-label ms-1"
                                    >
                                      13-15 Minutes
                                    </Label>
                                    <div className="ms-1">
                                      {priceForUser(
                                        userPlanType,
                                        "cinematic_film[min_13_15]",
                                        selectEventLastDay?.value
                                      )}
                                    </div>
                                  </>
                                )}
                              />
                            </div>
                          </div>
                          <div className="order-info">
                            <div className="form-check form-check-inline">
                              <Controller
                                control={control}
                                name="cinematic_film[min_16_20]"
                                defaultValue={false}
                                render={({ field }) => (
                                  <Input
                                    checked={
                                      isAlreadySelected?.cinematic_film
                                        ?.min_16_20
                                    }
                                    disabled={
                                      isAlreadySelected?.cinematic_film
                                        ?.min_16_20
                                    }
                                    type="checkbox"
                                    id="min_16_20"
                                    {...field}
                                  />
                                )}
                              />
                              <Label
                                for="min_16_20"
                                className="form-check-label ms-1"
                              >
                                16-20 Minutes
                              </Label>
                              <div className="ms-1">
                                {priceForUser(
                                  userPlanType,
                                  "cinematic_film[min_16_20]",
                                  selectEventLastDay?.value
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="order-info">
                            <div className="form-check form-check-inline">
                              <Controller
                                control={control}
                                name="cinematic_film[min_21_25]"
                                defaultValue={false}
                                render={({ field }) => (
                                  <Input
                                    checked={
                                      isAlreadySelected?.cinematic_film
                                        ?.min_21_25
                                    }
                                    disabled={
                                      isAlreadySelected?.cinematic_film
                                        ?.min_21_25
                                    }
                                    type="checkbox"
                                    id="min_21_25"
                                    {...field}
                                  />
                                )}
                              />
                              <Label
                                for="min_21_25"
                                className="form-check-label ms-1"
                              >
                                21-25 Minutes
                              </Label>
                              <div className="ms-1">
                                {priceForUser(
                                  userPlanType,
                                  "cinematic_film[min_21_25]",
                                  selectEventLastDay?.value
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </AccordionBody>
                    </AccordionItem>

                    {/* 5 */}
                    <AccordionItem>
                      <AccordionHeader targetId="5">
                        Documentary Edits
                      </AccordionHeader>
                      <AccordionBody accordionId="5">
                        <div className="order-accordian">
                          {/* 1 point */}
                          <div className="order-info">
                            <div className="form-check form-check-inline">
                              <Controller
                                control={control}
                                name="documentary_edits[ceremony_MCE]"
                                defaultValue={false}
                                render={({ field }) => (
                                  <Input
                                    checked={
                                      isAlreadySelected?.documentary_edits
                                        ?.ceremony_MCE
                                    }
                                    disabled={
                                      isAlreadySelected?.documentary_edits
                                        ?.ceremony_MCE
                                    }
                                    type="checkbox"
                                    id="ceremony_MCE"
                                    {...field}
                                  />
                                )}
                              />
                              <Label
                                for="ceremony_MCE"
                                className="form-check-label ms-1"
                              >
                                Ceremony Edit
                              </Label>
                              <div className="ms-1">
                                {priceForUser(
                                  userPlanType,
                                  "documentary_edits[ceremony_MCE]",
                                  selectEventLastDay?.value
                                )}
                              </div>
                            </div>
                          </div>

                          {/* 2 point */}
                          <div className="order-info">
                            <div className="form-check form-check-inline">
                              <Controller
                                control={control}
                                name="documentary_edits[reception_MCE]"
                                defaultValue={false}
                                render={({ field }) => (
                                  <Input
                                    checked={
                                      isAlreadySelected?.documentary_edits
                                        ?.reception_MCE
                                    }
                                    disabled={
                                      isAlreadySelected?.documentary_edits
                                        ?.reception_MCE
                                    }
                                    type="checkbox"
                                    id="reception_MCE"
                                    {...field}
                                  />
                                )}
                              />
                              <Label
                                for="reception_MCE"
                                className="form-check-label ms-1"
                              >
                                Reception Edit
                              </Label>
                              <div className="ms-1">
                                {priceForUser(
                                  userPlanType,
                                  "documentary_edits[reception_MCE]",
                                  selectEventLastDay?.value
                                )}
                              </div>
                            </div>
                          </div>

                          {/* 3 point*/}
                          <div className="order-info">
                            <div className="form-check form-check-inline">
                              <Controller
                                control={control}
                                name="documentary_edits[dances_only]"
                                defaultValue={false}
                                render={({ field }) => (
                                  <Input
                                    checked={
                                      isAlreadySelected?.documentary_edits
                                        ?.dances_only
                                    }
                                    disabled={
                                      isAlreadySelected?.documentary_edits
                                        ?.dances_only
                                    }
                                    type="checkbox"
                                    id="dances_only"
                                    {...field}
                                  />
                                )}
                              />
                              <Label
                                for="dances_only"
                                className="form-check-label ms-1"
                              >
                                Dances Only
                              </Label>
                              <div className="ms-1">
                                {priceForUser(
                                  userPlanType,
                                  "documentary_edits[dances_only]",
                                  selectEventLastDay?.value
                                )}
                              </div>
                            </div>
                          </div>
                          <hr />
                          {/* 4 point*/}
                          <div className="order-info">
                            <div className="form-check form-check-inline">
                              <Controller
                                control={control}
                                name="documentary_edits[speeches_only]"
                                defaultValue={false}
                                render={({ field }) => (
                                  <Input
                                    checked={
                                      isAlreadySelected?.documentary_edits
                                        ?.speeches_only
                                    }
                                    disabled={
                                      isAlreadySelected?.documentary_edits
                                        ?.speeches_only
                                    }
                                    type="checkbox"
                                    id="speeches_only"
                                    {...field}
                                  />
                                )}
                              />
                              <Label
                                for="speeches_only"
                                className="form-check-label ms-1"
                              >
                                Speeches Only
                              </Label>
                              <div className="ms-1">
                                {priceForUser(
                                  userPlanType,
                                  "documentary_edits[speeches_only]",
                                  selectEventLastDay?.value
                                )}
                              </div>
                            </div>
                          </div>

                          {/* 5 point*/}
                          <div className="order-info">
                            <div className="form-check form-check-inline">
                              <Controller
                                control={control}
                                name="documentary_edits[ceremony_reception_MCE]"
                                defaultValue={false}
                                render={({ field }) => (
                                  <Input
                                    checked={
                                      isAlreadySelected?.documentary_edits
                                        ?.ceremony_reception_MCE
                                    }
                                    disabled={
                                      isAlreadySelected?.documentary_edits
                                        ?.ceremony_reception_MCE
                                    }
                                    type="checkbox"
                                    id="ceremony_reception_MCE"
                                    {...field}
                                  />
                                )}
                              />
                              <Label
                                for="ceremony_reception_MCE"
                                className="form-check-label ms-1"
                              >
                                Ceremony + Reception Edit
                              </Label>
                              <div className="ms-1">
                                {priceForUser(
                                  userPlanType,
                                  "documentary_edits[ceremony_reception_MCE]",
                                  selectEventLastDay?.value
                                )}
                              </div>
                            </div>
                          </div>

                          {/* 6 point */}
                          <div className="order-info">
                            <div className="form-check form-check-inline">
                              <Controller
                                control={control}
                                name="documentary_edits[full_day_edit]"
                                defaultValue={false}
                                render={({ field }) => (
                                  <Input
                                    checked={
                                      isAlreadySelected?.documentary_edits
                                        ?.full_day_edit
                                    }
                                    disabled={
                                      isAlreadySelected?.documentary_edits
                                        ?.full_day_edit
                                    }
                                    type="checkbox"
                                    id="full_day_edit"
                                    {...field}
                                  />
                                )}
                              />
                              <Label
                                for="full_day_edit"
                                className="form-check-label ms-1"
                              >
                                Full Day Edit
                              </Label>
                              <div className="ms-1">
                                {priceForUser(
                                  userPlanType,
                                  "documentary_edits[full_day_edit]",
                                  selectEventLastDay?.value
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </AccordionBody>
                    </AccordionItem>
                  </Accordion>

                  <Row className="mt-3">
                    <Col sm="12" className="mb-1">
                      <h4>Additional Notes</h4>
                    </Col>
                    <Col sm="12" className="mb-1">
                      <Controller
                        control={control}
                        id="additional_note"
                        name="additional_note"
                        render={({ field }) => (
                          <Input
                            rows={3}
                            type="textarea"
                            placeholder="Additional Notes"
                            {...field}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} lg={4} className="order-price-box">
              <Card>
                <CardBody>
                  {/* user_subscription_id have value so its subscriber and user_subscription_id value is null user is alacarte user */}
                  {userPlanType ? null : (
                    <>
                      {isAlreadySelected?.discount_code ? null : (
                        <>
                          <label className="section-label mb-1">
                            Use a discount code
                          </label>

                          <Controller
                            name="discount_code"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <InputGroup className="input-group-merge coupons">
                                <Input
                                  // disabled={}
                                  placeholder="Apply Discount Code"
                                  {...field}
                                  onChange={(e) => {
                                    field.onChange(e);
                                    if (e.target.value === "") {
                                      setDiscountPercentage(0);
                                    } else {
                                      clearErrors("discount_code");
                                    }
                                  }}
                                />
                                {field.value && (
                                  <InputGroupText
                                    className="text-white ms-0 cursor-pointer border-start-0 border-end-0"
                                    onClick={() => {
                                      field.onChange("");
                                      setDiscountPercentage(0);
                                    }}
                                  >
                                    <X size={16} />
                                    {/* Replace with the clear input icon */}
                                  </InputGroupText>
                                )}
                                <Button
                                  disabled={isLoading}
                                  color="primary"
                                  outline
                                  className=" text-primary ms-0 cursor-pointer"
                                  onClick={() => {
                                    if (watch("discount_code")) {
                                      addDiscountCode();
                                    } else {
                                      // eslint-disable-next-line nonblock-statement-body-position
                                      setError("discount_code", {
                                        type: "Required",
                                        message: "Discount code is Required",
                                      });
                                    }
                                  }}
                                >
                                  Apply
                                </Button>
                              </InputGroup>
                            )}
                          />
                          {errors.discount_code && (
                            <FormFeedback>
                              {errors.discount_code.message}
                            </FormFeedback>
                          )}

                          <hr />
                        </>
                      )}
                    </>
                  )}
                  <div className="price-details">
                    <h6 className="price-title">Price Details</h6>
                    <ul className="list-unstyled">
                      {/* user_subscription_id have value so its subscriber and user_subscription_id value is null user is alacarte user */}
                      {userPlanType && (
                        <li className="price-detail">
                          <div className="order-title">
                            Total Coins You have
                          </div>
                          <div className="order-amt ">
                            {numberWithCommas(
                              currentUser?.currentuserdata?.data?.total_carets
                            )}{" "}
                            Coins
                          </div>
                        </li>
                      )}
                      <li className="price-detail">
                        <div className="detail-title">Item in Your Cart</div>
                        <div className="order-amt discount-amt d-flex align-items-center">
                          {" "}
                          <Badge pill color="primary" className="me-50">
                            {cartItemCount()}
                          </Badge>
                          {cartItemCount() <= 1 ? `Item` : `Items`}
                        </div>
                      </li>
                      <div className="cart-items">
                        {/* ITEM IN YOUR CART */}
                        {orderProjectData?.map((checkbox) => {
                          const fieldName = checkbox.fieldName;

                          let key, value;
                          if (fieldName.includes("[")) {
                            const parts = fieldName.split("[");
                            key = parts[0]?.replace(/_/g, " ");
                            value = parts[1]
                              ?.replace("]", "")
                              ?.replace(/_/g, "-")
                              ?.replace("min-", " ");

                            while (value.includes("[")) {
                              const nestedParts = value.split("[");
                              const nestedValue = nestedParts[1]?.replace(
                                "]",
                                ""
                              );
                              value = value.replace(
                                `[${nestedValue}]`,
                                nestedValue.replace(/_/g, " ")
                              );
                            }
                          } else {
                            switch (fieldName) {
                              case "insta_video":
                                key = "Instagram Video";
                                value = "1";
                                break;

                              default:
                                key = fieldName;
                                value = "";
                            }
                          }
                          if (watch(fieldName) && key) {
                            return (
                              <>
                                <div className="Item-selected">
                                  <ul className="list-unstyled">
                                    <li
                                      className="price-detail m-50"
                                      key={fieldName}
                                    >
                                      <div className="order-title text-capitalize">
                                        {(() => {
                                          if (
                                            key === "narrative highlight video"
                                          ) {
                                            return "Narrative Highlight Video – Music + Dialogue";
                                          }
                                          if (key === "highlight video") {
                                            return "Music Highlight Video – Music only";
                                          }
                                          if (key === "cinematic film") {
                                            return "Cinematic Feature Film – Music + Dialogue";
                                          } else {
                                            return key;
                                          }
                                        })()}
                                      </div>
                                      {value ? (
                                        <>
                                          {(() => {
                                            if (value === "1") {
                                              return (
                                                <div className="fw-bold text-capitalize text-primary">
                                                  {value} minute
                                                </div>
                                              );
                                            } else if (
                                              value.match(/[a-zA-Z]/) &&
                                              value.includes("-")
                                            ) {
                                              return (
                                                <div className="fw-bold text-capitalize text-primary">
                                                  {value ===
                                                  "ceremony-reception-MCE"
                                                    ? "ceremony + reception Edit"
                                                    : value
                                                        .replace(/-/g, " ")
                                                        .replace(/_/g, " ")
                                                        .replace("MCE", "Edit")}
                                                </div>
                                              );
                                            } else if (value.includes("-")) {
                                              return (
                                                <div className="fw-bold text-capitalize text-primary">
                                                  {value} Minutes
                                                </div>
                                              );
                                            } else {
                                              return (
                                                <div className="fw-bold text-capitalize text-primary">
                                                  {value} Minutes
                                                </div>
                                              );
                                            }
                                          })()}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </li>
                                  </ul>
                                </div>
                              </>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </div>
                      {/* ITEM IN YOUR CART END*/}

                      {userPlanType ? null : (
                        <>
                          {discountPercentage ? (
                            <>
                              <li className="price-detail">
                                <div className="detail-title">Amount</div>
                                <div className="detail-amt discount-amt text-primary">
                                  {`$${numberWithCommas(
                                    calculateTotalAmount().toFixed(2)
                                  )}`}
                                </div>
                              </li>

                              <li className="price-detail">
                                <div className="detail-title">
                                  Discounted Amount
                                </div>
                                <div className="detail-amt discount-amt text-success">
                                  {handleDiscountItem()} ({""}
                                  {discountPercentage}%)
                                </div>
                              </li>

                              <li className="price-detail">
                                <div className="detail-title">
                                  Note: The discount has been applied to the
                                  most expensive product - ({" "}
                                  {handleSelectedExpensiveItems()} , {""}${" "}
                                  {handleSelectedItems()} )
                                </div>
                              </li>
                            </>
                          ) : null}
                        </>
                      )}
                    </ul>
                    <hr />
                    <ul className="list-unstyled">
                      <li className="price-detail">
                        <div className="order-amt-total detail-total">
                          {userPlanType ? `Total Coins` : ` Total Amount`}
                        </div>

                        <div className="order-amt-total fw-bolder">
                          {userPlanType
                            ? `${numberWithCommas(
                                Math.round(calculateTotalAmount())
                              )} coins`
                            : `$${numberWithCommas(handleDiscount())}`}
                        </div>
                      </li>
                    </ul>
                    <Button
                      disabled={applyLoading}
                      block
                      color="primary"
                      type="submit"
                      className="btn-next place-order mb-1"
                    >
                      Place Order
                    </Button>
                  </div>

                  <div className="added-cards">
                    <>
                      {paymentData?.data?.map((card) => {
                        if (card.default_method) {
                          return (
                            <div
                              key={card.id}
                              className={classnames(
                                "cardMaster rounded border p-2 mb-1"
                              )}
                            >
                              <div className="d-flex justify-content-between flex-sm-row flex-column">
                                <div className="card-information word-break-all">
                                  <div className="d-flex align-items-center mb-50">
                                    <h6 className="mb-0">
                                      {card.name_on_card}
                                    </h6>
                                    {card.default_method === 1 && (
                                      <Badge
                                        color="light-primary"
                                        className="ms-50"
                                      >
                                        default
                                      </Badge>
                                    )}
                                  </div>
                                  <span className="card-number d-flex align-items-center mb-50 text-start d-flex">
                                    **** **** **** {card.card_number}
                                  </span>
                                  <span className="mt-2">
                                    Card expires at {card.card_expiry}
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })}
                    </>
                  </div>

                  {/* {userPlanType ? null : (
                    <>
                      <hr />
                      <div className="pb-1 text-center ">
                        <Button.Ripple
                          block
                          className="orange-btn"
                          color="flat-primary mb-1"
                          onClick={(e) => {
                            e.preventDefault();
                            setModalOpen(true);
                          }}
                        >
                          Compare With Pay As You Go Pricing
                        </Button.Ripple>

                        <Button.Ripple
                          block
                          // color="primary"
                          className="orange-btn "
                          onClick={(e) => {
                            e.preventDefault();
                            setShow(true);
                          }}
                        >
                          Calculate the Right Package for You!
                        </Button.Ripple>
                      </div>
                    </>
                  )} */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </UILoader>
      </Form>
      {/* START modal-for-language */}
      <Modal centered isOpen={openLang}>
        <ModalHeader className="terms-modal-header">STOP</ModalHeader>
        <ModalBody>
          <p>
            If the wedding is not in English, please email us at
            hello@blinkovo.com before completing this order form to verify
            whether or not we can accommodate this order.
          </p>
          <p>
            If you have already received approval from us you may close this
            popup and proceed with the rest of the order form.
          </p>
          <h4 className="text-center">Thank you!</h4>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => setOpenLang(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      {/* END modal-for-language */}
      {/* <StripeElement> */}
      {/* <PricingCalculatorModel setShow={setShow} show={show} /> */}
      {/* </StripeElement> */}
      <TermAndCondition
        openTermModel={openTermModel}
        setOpenTermModel={setOpenTermModel}
        handleSubmit={handleSubmit(SendData)}
      />

      {/* {modalOpen && (
        <WPCalculator modalOpen={modalOpen} setModalOpen={setModalOpen} />
      )} */}
    </>
  );
};

export default AdditionalEdit;
