/* eslint-disable no-unused-expressions */
/* eslint-disable multiline-ternary */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { MessageSquare } from "react-feather";
import { Offcanvas, OffcanvasBody, Badge } from "reactstrap";
import Talk from "talkjs";
import { projectTitle } from "../common/utils";
import themeConfig from "../../../../configs/themeConfig";
// import pusher from "../../../../utility/pusher";
import { ablyClient, ablychannel } from "../../../../utility/ably";
import {
  getUserInGroupChat,
  realtimeUnreadCount,
} from "../../../../services/projectManagementService";
import { useDispatch, useSelector } from "react-redux";
import { setAdminCount } from "../../../../redux/unreadCount/unreadCount";
import { getUserData } from "../../../../utility/Utils";
import { ROLE } from "../../../../constant/constant";
import { useLocation } from "react-router-dom";
import { ADMIN_ROUTES } from "../../../../constant/route";

const FeedbackCell = ({ data }) => {
  const location = useLocation();
  const isDisabled = location.pathname === ADMIN_ROUTES.ARCHIVED_PROJECTS;
  // console.log("data: ", data);
  const isDisable = data?.is_dummy === "yes";
  const chatboxEl = useRef();
  const dispatch = useDispatch();
  const userData = getUserData();

  const [canvasOpen, setCanvasOpen] = useState(false);
  const [canvasScroll, setCanvasScroll] = useState(false);
  const [unreadChats, setUnreadChats] = useState({});
  const RouteUrl = window.location.origin;
  const userUnreadCount = useSelector((state) => state.unreadCount);

  const toggleCanvasStart = () => {
    setCanvasScroll(true);
    setCanvasOpen(!canvasOpen);
  };

  const handleChatOpen = (value) => {
    setCanvasOpen(!canvasOpen);
    getUserInGroupChat(value.sub_project_id)
      .then((res) => {
        const { sub_project, co_worker, super_admin, admin_user } = res?.data;

        const participants = [
          // sub_project,
          co_worker,
          super_admin,
          ...admin_user,
        ]
          .filter((row) => row && (row !== null || row !== undefined))
          .map((item) => {
            if (
              [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.CUSTOMER_SUPPORT].includes(
                item.role_id
              )
            ) {
              return {
                ...item,
                id: item.id,
              };
            }
            // if (item.role_id === ROLE.USER) {
            //   return {
            //     ...item,
            //     id: item.user_id,
            //   };
            // }
          });

        Talk.ready.then(() => {
          // Super Admin
          // const SuperAdmin = new Talk.User({
          //   // id: `${super_admin.role_id}`,
          //   id: `${super_admin.id}`,
          //   name:
          //     `${super_admin.first_name} ${super_admin.last_name}` ||
          //     "Super Admin",
          //   email: super_admin.email,
          //   photoUrl: super_admin.image,
          //   role: process.env.REACT_APP_TALK_JS_CHAT_ROLE,

          //   custom: {
          //     id: `${sub_project?.sub_project_id}`,
          //     subtitle_description: projectTitle(sub_project?.sub_order),
          //     project_name: sub_project?.project_name,
          //     role_id: `${super_admin.role_id}`,
          //   },
          // });

          // // User
          // const User = new Talk.User({
          //   id: `${sub_project?.sub_project_id}`,
          //   name: `${sub_project?.first_name} ${sub_project?.last_name}`,
          //   email: sub_project?.email,
          //   photoUrl: sub_project?.image,
          //   role: process.env.REACT_APP_TALK_JS_CHAT_ROLE,

          //   custom: {
          //     id: `${sub_project?.sub_project_id}`,
          //     subtitle_description: projectTitle(sub_project?.sub_order),
          //     project_name: sub_project?.project_name,
          //     role_id: `${sub_project.role_id}`,
          //   },
          // });

          // // CustomerSupport
          // let CustomerSupport;
          // if (co_worker !== null) {
          //   CustomerSupport = new Talk.User({
          //     id: `${co_worker?.id}`,
          //     name: `${co_worker?.first_name} ${co_worker?.last_name}`,
          //     email: co_worker?.email,
          //     photoUrl: co_worker?.image,
          //     role: process.env.REACT_APP_TALK_JS_CHAT_ROLE,

          //     custom: {
          //       id: `${sub_project?.sub_project_id}`,
          //       subtitle_description: projectTitle(sub_project?.sub_order),
          //       project_name: sub_project?.project_name,
          //       role_id: `${co_worker.role_id}`,
          //     },
          //   });
          // }

          // // Sub Admin
          // let Admins = [];
          // if (admin_user.length) {
          //   Admins = admin_user.map((row) => {
          //     return new Talk.User({
          //       id: `${row?.id}`,
          //       name: `${row?.first_name} ${row?.last_name}`,
          //       email: row?.email,
          //       photoUrl: row?.image,
          //       role: process.env.REACT_APP_TALK_JS_CHAT_ROLE,

          //       custom: {
          //         id: `${sub_project?.sub_project_id}`,
          //         subtitle_description: projectTitle(sub_project?.sub_order),
          //         project_name: sub_project?.project_name,
          //         role_id: `${row.role_id}`,
          //       },
          //     });
          //   });
          // }

          // check current user login then session create
          const isCurrentUser = participants?.find(
            (row) => row.email === userData?.email
          );

          if (isCurrentUser) {
            const currentUser = new Talk.User({
              id: `${isCurrentUser?.id}`,
              name: `${isCurrentUser?.first_name} ${isCurrentUser?.last_name}`,
              email: isCurrentUser?.email,
              // photoUrl: isCurrentUser?.image,
              role: process.env.REACT_APP_TALK_JS_CHAT_ROLE,

              // custom: {
              //   id: `${sub_project?.sub_project_id}`,
              //   subtitle_description:
              //     (sub_project?.project_sub_type &&
              //       sub_project?.project_type &&
              //       `${sub_project?.project_type} : ${sub_project?.project_sub_type}`) ||
              //     "--",
              //   project_name: sub_project?.project_name,
              //   role_id: `${isCurrentUser.role_id}`,
              // },

              // custom: {
              //   id: `${sub_project?.sub_project_id}`,
              //   subtitle_description:
              //     (sub_project?.project_sub_type &&
              //       sub_project?.project_type &&
              //       `${sub_project?.project_type} : ${sub_project?.project_sub_type}`) ||
              //     "--",
              //   project_name: sub_project?.project_name,
              //   role_id: `${isCurrentUser.role_id}`,
              // },
            });
            window.talkSession = new Talk.Session({
              appId: process.env.REACT_APP_TALK_JS_CHAT,
              me: currentUser,
            });
          }

          const conversation = window.talkSession.getOrCreateConversation(
            `${sub_project?.conversation_id}`
          );

          // conversation.setParticipant(
          //   SuperAdmin,
          //   CustomerSupport ? { notify: false } : { notify: true }
          // );
          // conversation.setParticipant(User);
          // if (CustomerSupport) {
          //   conversation.setParticipant(CustomerSupport);
          // }

          // if (Admins.length) {
          //   Admins.forEach((row) => {
          //     conversation.setParticipant(
          //       row,
          //       CustomerSupport ? { notify: false } : { notify: true }
          //     );
          //   });
          // }

          conversation.setAttributes({
            subject: `${sub_project?.first_name} ${sub_project?.last_name}`,
            custom: {
              id: `${sub_project?.sub_project_id}`,
              subtitle_description:
                (sub_project?.project_sub_type &&
                  sub_project?.project_type &&
                  `${sub_project?.project_type} : ${sub_project?.project_sub_type}`) ||
                "--",
              project_name: sub_project?.project_name,
            },
          });

          const chatbox = window.talkSession.createChatbox({
            messageField: { spellcheck: true },
          });
          chatbox.select(conversation);

          chatbox.mount(chatboxEl.current);

          chatbox.onCustomConversationAction("myClose", (event) => {
            if (event.params.choice === "close") {
              setCanvasOpen(false);
            }
          });
          console.log("chatbox: ", chatbox);
        });
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };

  return !isDisabled ? (
    <>
      <div className="list-feedback-msg">
        {/* {console.log("userUnreadCount: admin", userUnreadCount.userUnreadCount)} */}
        <div className="chat-with-icon" style={{ height: "60px" }}>
          <label
            className={`position-relative ${
              !isDisable && "cursor-pointer chat-icon-tb"
            }`}
            style={{
              color: isDisable && "#b4b7bd",
            }}
            onClick={() => {
              if (!isDisable) {
                handleChatOpen(data);
              }
            }}
          >
            <MessageSquare />
            {userUnreadCount.userUnreadCount.user_id &&
              userUnreadCount.userUnreadCount.user_id[
                `${data.sub_project_id}`
              ] &&
              userUnreadCount.userUnreadCount.user_id[
                `${data.sub_project_id}`
              ]?.includes(userData.id) &&
              userUnreadCount.userUnreadCount.sub_project_id[
                `${data.sub_project_id}`
              ] && (
                <Badge color="primary" className="badge-up admin-badge">
                  {" "}
                  {
                    userUnreadCount.userUnreadCount.sub_project_id[
                      `${data.sub_project_id}`
                    ][
                      userUnreadCount.userUnreadCount.user_id[
                        `${data.sub_project_id}`
                      ].indexOf(userData.id)
                    ]
                  }
                </Badge>
              )}
          </label>
        </div>
      </div>
      {canvasOpen && (
        <Offcanvas
          scrollable={canvasScroll}
          isOpen={canvasOpen}
          direction="end"
          toggle={toggleCanvasStart}
          style={{ width: "400px" }}
          onClosed={() => {
            if (window.talkSession) window.talkSession.destroy();
          }}
        >
          <OffcanvasBody className="d-flex p-0 chat-off-canvas">
            <div
              ref={chatboxEl}
              style={{
                width: "400px",
                height: "99.39%",
              }}
            />
          </OffcanvasBody>
        </Offcanvas>
      )}
    </>
  ) : (
    <div className="chat-with-icon">
      <label className="chat-icon-tb position-relative">
        <MessageSquare className="disabled-color" />
      </label>
    </div>
  );
};

export default FeedbackCell;
