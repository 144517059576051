/* eslint-disable multiline-ternary */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { MODULE } from "../../../constant/constant";
import { getAllPopUpMessage } from "../../../services/popUpManagement.service";
import { isLocalhostUrl, popUpIndexData } from "../../../utility/Utils";

const TermAndCondition = ({
  openTermModel,
  setOpenTermModel,
  setIsTermsAccept,
  handleSubmit,
  module,
  setOpenRemaining,
  setOpenUpgrade,
}) => {
  const [isTermsCheck, setIsTermsCheck] = useState();
  const openModal =
    module === MODULE.SUBSCRIPTION ? openTermModel?.isOpen : openTermModel;
  const resetState = () => {
    setIsTermsCheck();

    if (module === MODULE.SUBSCRIPTION) {
      setOpenTermModel({ isOpen: false, value: null });
    } else {
      setOpenTermModel(false);
    }
  };

  const handleAccept = () => {
    resetState();

    if (openTermModel?.for === "payRemaining") {
      setOpenRemaining({ isOpen: true, data: openTermModel?.data });
    }
    if (openTermModel?.for === "upgrade") {
      setOpenUpgrade({ isOpen: true, data: openTermModel?.data });
    }

    setIsTermsAccept && setIsTermsAccept(true);
    handleSubmit && handleSubmit();
  };

  const [popUpDetails, setPopUpDetails] = useState({});
  useEffect(() => {
    if (isLocalhostUrl) {
      const value =
        module === MODULE.SUBSCRIPTION
          ? popUpIndexData.SUBSCRIPTION_PURCHASE
          : popUpIndexData.ORDER_PAGE;
      getAllPopUpMessage()
        .then((res) => {
          setPopUpDetails(res[value]);
        })
        .catch((err) => {
          console.log(err);
          setPopUpDetails({ message: "Something Went Wrong" });
        });
    }
  }, []);

  useEffect(() => {
    if (openModal) document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "visible";
    };
  }, [openModal]);

  return (
    <div>
      <Modal
        isOpen={openModal}
        toggle={() => resetState()}
        size="lg"
        centered
        scrollable
        className="terms-modal"
      >
        <ModalHeader className="terms-modal-header">
          Terms & Conditions
        </ModalHeader>

        <ModalBody className="terms-modal-body">
          <div className="dynamic-popup-data-print-user">
            <div
              className="main-text"
              dangerouslySetInnerHTML={{ __html: popUpDetails?.message }}
            />
          </div>
        </ModalBody>

        <ModalFooter className="justify-content-between">
          <div className="text-start">
            <Input
              id="basicInput"
              className="m-1 me-50 cursor-pointer"
              type="checkbox"
              onChange={(e) => setIsTermsCheck(e.target.checked)}
            />
            <Label
              className="form-label m-1 ms-25 cursor-pointer"
              for="basicInput"
            >
              Terms & Conditions Apply
            </Label>
          </div>

          <Button
            color="primary"
            onClick={() => handleAccept()}
            disabled={!isTermsCheck}
            type={module === MODULE.SUBSCRIPTION ? "button" : "submit"}
          >
            Accept
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default TermAndCondition;
