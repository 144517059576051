/* eslint-disable multiline-ternary */
import React from "react";
import { Check } from "react-feather";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getErrorMsg } from "../../../../common/ErrorMessages/errorMessage";
import { getAllDataProjectManagement } from "../../../../redux/allProjectsManagement/allDataProjectManagement.thunk";
import { ChangeStatus } from "../../../../services/projectManagementService";
import { AGDropdownStyles } from "../../../../utility/Utils";
import ToastContent from "../../../components/toast/toast";
// import { ProjectStatusColorCode } from "../common/utils";
import { setLoadingState } from "../../../../redux/projectManagementState/projectManagementStates";
import { useLocation } from "react-router-dom";
import { ADMIN_ROUTES } from "../../../../constant/route";

const ProjectStatus = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isDisabled = location.pathname === ADMIN_ROUTES.ARCHIVED_PROJECTS;
  const allStatusList = useSelector(
    (state) => state?.statusManagementList?.allStatusList?.data
  );
  const currentStatus = allStatusList?.find((item) => item.id === props.value);
  const handleApiCall = (value) => {
    dispatch(setLoadingState(true));

    ChangeStatus({ status: value?.id, id: props?.data?.sub_project_id })
      .then((data) => {
        toast((t) => (
          <ToastContent
            t={t.id}
            message={data?.message}
            icon={<Check size={12} />}
            color="success"
          />
        ));
        dispatch(getAllDataProjectManagement({ user_id: "all" }));
        dispatch(setLoadingState(false));
      })
      .catch((err) => {
        getErrorMsg(err);
        dispatch(setLoadingState(false));
      });
  };

  const getOptionStyle = (option) => ({
    backgroundColor: option?.color,
    color: "#fff",
    margin: "10px 0px",
    ":hover": {
      backgroundColor: `${option?.color} !important`,
      color: "#fff !important",
      opacity: "0.6",
    },
    "&.select__option--is-focused": {
      backgroundColor: `${option?.color} !important`,
      color: "#fff !important",
    },
    "&.select__option--is-selected": {
      backgroundColor: `${option?.color} !important`,
      color: "#fff !important",
    },
  });

  return (
    <span
      style={{
        opacity: isDisabled && 0.7,
      }}
    >
      {props.value === "approved_by_client" ? (
        "Approved By Client"
      ) : (
        <Select
          menuPortalTarget={document.body}
          id="status"
          isDisabled={isDisabled}
          placeholder="Select Status"
          styles={{
            ...AGDropdownStyles,
            option: (provided, state) => ({
              ...provided,
              ...getOptionStyle(state.data),
            }),
            control: (provided) => ({
              ...provided,
              height: "30px",
              lineHeight: "normal",
              minHeight: "30px",
              backgroundColor: "transparent !important",
            }),
            valueContainer: (provided) => ({
              ...provided,
              padding: "1px 8px",
            }),
            indicatorsContainer: (provided) => ({
              ...provided,
              height: "29px",
            }),
            singleValue: (provided) => ({
              ...provided,
            }),
          }}
          className="react-select custom-table-react-se projectManagement"
          classNamePrefix="select"
          isClearable={false}
          options={allStatusList.filter((r) => r.id !== props.value)}
          getOptionLabel={(option) => option.name}
          getOptionValue={(opt) => opt.id}
          defaultValue={currentStatus}
          onChange={(value) => {
            handleApiCall(value);
          }}
        />
      )}
    </span>
  );
};

export default ProjectStatus;
