import React from "react";
import { useFormContext } from "react-hook-form";
import { FormFeedback, Input, Label } from "reactstrap";
import { RenderDynamicFiledLabel } from "./index";

const FormCheckBox = ({ fieldData }) => {
  const { register, errors, fieldsDisabled } = useFormContext();

  const { ref: checkbox_field_checkbox, ...checkbox_field_test } = register(
    fieldData?.name,
    {
      required: true,
    }
  );

  return (
    <React.Fragment>
      {/* Label */}
      <RenderDynamicFiledLabel fieldData={fieldData} />

      {fieldData?.values.map((val, i) => {
        const id_for = `${fieldData?.name}_${i}_${val?.value}`;
        return (
          <div
            className={`form-check ${
              fieldData?.inline ? "form-check-inline" : "mt-50"
            }`}
            key={id_for}
          >
            <Input
              innerRef={checkbox_field_checkbox}
              {...checkbox_field_test}
              type="checkbox"
              name={fieldData?.name}
              id={id_for}
              value={val?.value}
              disabled={fieldsDisabled}
              className="form-check-input"
            />
            <Label for={id_for} className="form-check-label">
              {val?.label}
            </Label>
          </div>
        );
      })}

      {errors && errors[fieldData?.name] && (
        <FormFeedback>{errors[fieldData?.name].message}</FormFeedback>
      )}
    </React.Fragment>
  );
};

export default FormCheckBox;
