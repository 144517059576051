/* eslint-disable no-mixed-operators */
// ** React Imports
import { Fragment, useEffect, useState } from "react";

// ** Reactstrap Imports
import {
  Row,
  Col,
  Label,
  Form,
  Input,
  Button,
  Card,
  FormFeedback,
} from "reactstrap";

// ** Third Party Components
// import * as yup from "yup";
// import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";

// import { getUser } from "../../../redux/userSlice/user.thunk";

// ** Service
// import { editUserCompanyInfo } from "../../../services/user.services";

// ** Toast Message
import toast from "react-hot-toast";
import ToastContent from "../../components/toast/toast";
import { Check, X } from "react-feather";
import LineSkelaton from "../../../common/skeleton/LineSkelaton";
import { getErrorMsg } from "../../../common/ErrorMessages/errorMessage";
import {
  getStripeKeys,
  updateStripeKeys,
} from "../../../services/AdminProfile/adminProfile.service";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  ENVIRONMENT_FEATURES,
  environmentCheck,
} from "../../../utility/environmentCheck";
import { useDispatch } from "react-redux";
import { getPublisherKey } from "../../../services/myProject.services";
import { setStripePublicKey } from "../../../redux/currentUserSlice/currentUser";

const schema = yup.object().shape({
  publishKey: yup
    .string()
    .test("is-valid-stripe-key", "Invalid stripe key", function (value) {
      if (
        environmentCheck(ENVIRONMENT_FEATURES.NOT_ALLOW_TEST_KEYS) &&
        value &&
        value.search("_test") !== -1
      ) {
        return false;
      }
      return true;
    }),
  secretKey: yup
    .string()
    .test("is-valid-stripe-key", "Invalid stripe key", function (value) {
      if (
        environmentCheck(ENVIRONMENT_FEATURES.NOT_ALLOW_TEST_KEYS) &&
        value &&
        value.search("_test") !== -1
      ) {
        return false;
      }
      return true;
    }),
});

const StripeInfo = () => {
  // ** Get Data From Store
  // const store = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);

  // **Dispatch
  const dispatch = useDispatch();

  // ** Default Values
  const defaultValues = {
    publishKey: "",
    secretKey: "",
  };
  // ** Hooks
  const {
    control,
    reset,

    setError,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  const handleStripeApiCall = async () => {
    const {
      data: { stripe_public_key },
    } = await getPublisherKey();

    dispatch(setStripePublicKey(stripe_public_key));
  };

  const onSubmit = (data) => {
    const val = {
      // id: store?.data?.data?.personal_info?.id,
      public_key: data.publishKey,
      secret_key: data.secretKey,
    };

    if (data) {
      setIsLoading(true);
      updateStripeKeys(val)
        .then((res) => {
          // dispatch(getUser());
          toast((t) => (
            <ToastContent
              t={t.id}
              message={res?.data?.message}
              icon={<Check size={12} />}
            />
          ));
          setIsLoading(false);
          handleStripeApiCall();
        })
        .catch((err) => {
          getErrorMsg(err);
          setIsLoading(false);
        });
    } else {
      for (const key in data) {
        if (data[key].length === 0) {
          setError(key, {
            type: "manual",
          });
        }
      }
    }
  };

  const handleApiCall = async () => {
    try {
      setIsLoading(true);
      const res = await getStripeKeys();

      reset({
        publishKey: res?.data?.stripe_public_key,
        secretKey: res?.data?.stripe_public_key,
      });
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    handleApiCall();
  }, []);

  return (
    <Fragment>
      {isLoading ? (
        <div>
          {[...Array(9)].map((_, i) => (
            <LineSkelaton key={i} />
          ))}
        </div>
      ) : (
        <>
          <Row className="gy-1 pt-75">
            <Col xs="12" xl="6" className="m-auto">
              <Card className="p-2 px-md-3">
                <h2 className="mb-1">Live API Keys</h2>
                <div>
                  <p className="mb-0">
                    Secret keys are encrypted in our database. To get your API
                    keys go to{" "}
                    <a
                      href="https://docs.stripe.com/keys"
                      target="_blank"
                      rel="Developers &gt; API Keys"
                    >
                      Developers &gt; API Keys
                    </a>{" "}
                    and click Create Secret Key.
                  </p>
                </div>
                <hr />
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Row className="gy-1 ">
                    <Col xs={12}>
                      <div className="position-relative">
                        <Label className="form-label" for="publishKey">
                          Live Publishable Key
                        </Label>
                        <Controller
                          defaultValue=""
                          control={control}
                          id="publishKey"
                          name="publishKey"
                          render={({ field }) => (
                            <Input
                              {...field}
                              placeholder="Live Publishable Key"
                              autoComplete="off"
                              type="password"
                            />
                          )}
                        />
                        {errors?.publishKey && (
                          <FormFeedback className="d-block">
                            {errors.publishKey.message}
                          </FormFeedback>
                        )}
                      </div>
                    </Col>
                    <Col xs={12}>
                      <div className="position-relative">
                        <Label className="form-label" for="secretKey">
                          Live Secret Key
                        </Label>
                        <Controller
                          id="secretKey"
                          name="secretKey"
                          control={control}
                          render={({ field }) => (
                            <Input
                              {...field}
                              placeholder="Live Secret Key"
                              autoComplete="off"
                              type="password"
                            />
                          )}
                        />
                        {errors?.secretKey && (
                          <FormFeedback className="d-block">
                            {errors.secretKey.message}
                          </FormFeedback>
                        )}
                      </div>
                    </Col>

                    <Col xs={12} className=" text-end mt-2 pt-50">
                      <Button
                        type="submit"
                        className="me-1"
                        color="primary"
                        disabled={isLoading || !isDirty}
                      >
                        Save changes
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row>
          <Row className="gy-1 pt-2 pb-2">
            <Col
              xs="12"
              xl="6"
              className="m-auto d-block d-lg-flex justify-content-between"
            >
              <div>
                <h2>US Bank Account</h2>
                <p>Receive ACH direct debit payments from your US customers.</p>
              </div>
              <div>
                <Button
                  color="primary"
                  style={{ width: "max-content" }}
                  // onClick={() =>
                  //   window.open(
                  //     "mailto:help@blinkovo.com",
                  //     "_blank",
                  //     "width=600,height=400,resizable=yes,scrollbars=yes"
                  //   )
                  // }
                  onClick={() => {
                    const width = 800;
                    const height = 600;
                    const left = window.screen.width / 2 - width / 2;
                    const top = window.screen.height / 2 - height / 2;
                    const features = `width=${width},height=${height},left=${left},top=${top},resizable=yes,scrollbars=yes`;
                    window.open("mailto:help@blinkovo.com", "_blank", features);
                  }}
                >
                  Contact Us
                </Button>
              </div>
            </Col>
          </Row>
        </>
      )}
    </Fragment>
  );
};

export default StripeInfo;
