import { selectThemeColors } from "@utils";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import Select from "react-select";
import { FormFeedback } from "reactstrap";
import { RenderDynamicFiledLabel } from "./index";

const FormReactMultiSelect = ({ fieldData }) => {
  const { control, errors, watch, fieldsDisabled } = useFormContext();
  return (
    <React.Fragment>
      {/* Label */}
      <RenderDynamicFiledLabel fieldData={fieldData} />

      <Controller
        id={fieldData?.name}
        name={fieldData?.name}
        control={control}
        render={({ field: { ...fieldProps } }) => (
          <Select
            isMulti
            {...fieldProps}
            options={fieldData?.values}
            theme={selectThemeColors}
            className="react-select"
            classNamePrefix="select"
            isClearable
            placeholder={fieldData?.placeholder || ""}
            value={watch(fieldData?.name) || []}
            isDisabled={fieldsDisabled}
          />
        )}
      />

      {errors && errors[fieldData?.name] && (
        <FormFeedback>{errors[fieldData?.name].message}</FormFeedback>
      )}
    </React.Fragment>
  );
};

export default FormReactMultiSelect;
