/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Badge,
  Button,
  UncontrolledTooltip,
  Tooltip,
} from "reactstrap";
// import { UncontrolledTooltip } from "reactstrap";
// ** Third Party Components
import classnames from "classnames";

// ** Cutome Component
import { Check, CheckCircle, HelpCircle, Info, Truck, X } from "react-feather";
// import PricingCalculatorModel from "../myprojects/createProjectSteps/PricingCalculatorModel";

import { useDispatch, useSelector } from "react-redux";
//subscriptionDetails
import { fetchPlans, subscriptionDetails } from "../../../redux/subscription";
import toast from "react-hot-toast";
import ToastContent from "../../components/toast/toast";

import PlansSkeleton from "../../../common/skeleton/PlanSkeleton/Plans";
import {
  cancelUserSubscription,
  // confirmPayment,
  createStripeCustomer,
} from "../../../services/subscription.service";
import PaymentWizard from "../../../common/PaymentModal/PaymentWizard";
import StripeElement from "../../../common/stripeComponent/StripeElement";
import { getErrorMsg } from "../../../common/ErrorMessages/errorMessage";
import ConfirmModel from "../../components/modals/ConfirmModal";
import { getCurrentUser } from "../../../redux/currentUserSlice/currentUser.thunk";
import { useNavigate, useSearchParams } from "react-router-dom";
import { USER_ROUTES } from "../../../constant/route";

import TermAndCondition from "../../components/common/TermAndCondition";
import CPtoClipBoardModal from "../../components/modals/CPtoClipboard";
import OrderButton from "../../components/orderButton/OrderButton";
import CreateProjectInfoModel from "../myprojects/myProjectsModels/CreateProjectInfoModel";
// import WPCalculator from "../../WPCalc/WPCalculator";
import { MODULE } from "../../../constant/constant";
import PayRemainingBalanceModal from "./Modal/PayRemainingBalance";
import UpgradeSubscriptionModal from "./Modal/UpgradeSubscription";
import { getPaymentMethod } from "../../../redux/userSlice/user.thunk";
import { useStripe } from "@stripe/react-stripe-js";
import { sweetAlert } from "../../../common/SweetAlert/SweetAlert";
import UILoader from "@components/ui-loader";
import Loader from "../../../common/Loader/Loader";
import { ReactComponent as Discount } from "../../../assets/images/svg/Discount.svg";
import { dateFormat } from "../../../common/functions/helperFunctions";

// ** Styles
// import "@styles/base/pages/page-pricing.scss";

const SubscriptionManagement = ({ title }) => {
  const navigate = useNavigate();
  const stripe = useStripe();

  const [searchParams] = useSearchParams();
  // ** local state
  const [isLoading, setIsloading] = useState(false);
  const [isOuterLoading, setIsOuterLoading] = useState(false);

  // const [pricingCalculator, setPricingCalculator] = useState(false);
  const [paymentPopup, setPaymentPopup] = useState(false);
  const [isTermsAccept, setIsTermsAccept] = useState(false);
  // const [openTermModel, setOpenTermModel] = useState(false);
  const [openTermModel, setOpenTermModel] = useState({
    isOpen: false,
    for: null,
    data: null,
  });
  const [openRemaining, setOpenRemaining] = useState({
    isOpen: false,
    data: null,
  });
  const [openUpgrade, setOpenUpgrade] = useState({
    isOpen: false,
    data: null,
  });

  const [projectInfoModel, setProjectInfoModel] = useState(false);

  // const [modalOpen, setModalOpen] = useState(false);

  const [CopyModal, setCopyModal] = useState({ isOpen: false, data: "" });

  const [confSubscription, setConfSubscription] = useState({
    open: false,
    value: "",
  });

  const [tooltipOpen, setTooltipOpen] = useState({});
  const [PaytooltipOpen, setPayTooltipOpen] = useState({});
  const [currentToolTipOpen, setCurrentToolTipOpen] = useState({});

  // ** dispatch
  const dispatch = useDispatch();
  // ** get data from store
  const subscriptionData = useSelector((state) => state.subscription);
  const currentUser = useSelector((state) => state.currentUser);

  const defaultDeleteModalValue = {
    isOpen: false,
    value: "",
    isDisabled: false,
  };

  const [deleteModelOpen, setDeleteModelOpen] = useState(
    defaultDeleteModalValue
  );

  const openEditModal = (val) => {
    setConfSubscription({ open: true, value: val });
  };

  const handleConfSubscription = () => {
    setConfSubscription({
      open: false,
      value: "",
    });
    setIsTermsAccept(false);
  };
  const handlePlan = async () => {
    const value = {
      subscription_id: confSubscription?.value?.id,
    };
    try {
      setIsloading(true);
      const res = await createStripeCustomer(
        value,
        currentUser?.currentuserdata?.data?.user_subscription_id
      );

      if (res?.data?.payment_method === "no_payment_method") {
        const payload = {
          planId: confSubscription?.value?.id,
          client_secret: res?.data?.client_secret,
        };
        dispatch(subscriptionDetails(payload));
        setPaymentPopup(true);
        setIsTermsAccept(false);
      } else {
        setConfSubscription({ open: false, value: "" });

        sweetAlert("Subscription Purchased Successfully", "success");
        dispatch(getCurrentUser());
        navigate(USER_ROUTES.MY_PROJECTS);
        setIsTermsAccept(false);
        toast((t) => (
          <ToastContent
            t={t.id}
            message={res?.message}
            icon={<Check size={12} />}
          />
        ));
      }
    } catch (err) {
      console.log("err: ", err);
      setConfSubscription({ open: false, value: "" });
      // console.log(err.response?.data?.data?.redirect_to, "err:-->");

      // Check if the error response contains a card_declined || active_err code
      if (
        err.response?.data?.data?.code === "card_declined" ||
        err.response?.data?.data?.code === "active_err"
      ) {
        // Handle card declined error specifically
        toast((t) => (
          <ToastContent
            t={t.id}
            message={err.response?.data?.message}
            color="danger"
            icon={<X size={12} />}
          />
        ));
        return; // Exit the catch block to avoid further processing
      }

      if (err.response?.data?.success === false) {
        getErrorMsg(err);
      }
      const win = window.open(err.response?.data?.data?.redirect_to, "_blank");
      if (win === null || typeof win === "undefined") {
        setCopyModal({ isOpen: true, data: err.response?.data?.data });
        // Popup blocked, display message in UI
        toast((t) => (
          <ToastContent
            t={t.id}
            message="Popup blocked. Please enable popups and try again."
            color="danger"
            icon={<X size={12} />}
          />
        ));
      } else {
        // else if (err.response?.data?.data?.redirect_to) {
        //   const res = await stripe.confirmCardPayment(
        //     err.response?.data?.data.payment_intent_client_secret
        //   );

        //   if (!res.error) {
        //     const payload = {
        //       payment_intent_id: res.paymentIntent.id,
        //       user_subscription_id: err.response?.data?.data.user_subscription_id,
        //     };
        //     setIsOuterLoading(true);
        //     const response = await confirmPayment(payload);

        //     setConfSubscription({ open: false, value: "" });
        //     sweetAlert("Subscription Purchased Successfully", "success");
        //     dispatch(getCurrentUser());
        //     navigate(USER_ROUTES.MY_PROJECTS);
        //     setIsTermsAccept(false);
        //     setIsOuterLoading(false);
        //     toast((t) => (
        //       <ToastContent
        //         t={t.id}
        //         message={response?.message}
        //         icon={<Check size={12} />}
        //       />
        //     ));
        //   } else {
        //     setIsOuterLoading(false);
        //     toast((t) => (
        //       <ToastContent
        //         t={t.id}
        //         message="We are unable to authenticate your payment method."
        //         color="danger"
        //         icon={<X size={12} />}
        //       />
        //     ));
        //   }
        // }
        setIsOuterLoading(false);
        getErrorMsg(err);
        console.log("err: ", err);
      }
    } finally {
      setIsloading(false);
    }
  };

  const handleDeleteSubscription = () => {
    setDeleteModelOpen((prev) => ({ ...prev, isDisabled: true }));

    cancelUserSubscription({
      subscription_id: deleteModelOpen?.value?.id,
      immediately: false,
      user_id: currentUser?.currentuserdata?.data?.id,
    })
      .then((res) => {
        toast((t) => (
          <ToastContent
            t={t.id}
            message={res?.message || "Deleted Successfully"}
            icon={<Check size={12} />}
          />
        ));
        setDeleteModelOpen(defaultDeleteModalValue);
        dispatch(getCurrentUser());
      })
      .catch((err) => {
        getErrorMsg(err);
        setDeleteModelOpen(defaultDeleteModalValue);
      });
  };

  useEffect(() => {
    if (subscriptionData?.plans.data?.length === 0) dispatch(fetchPlans());
    if (currentUser?.currentuserdata?.data?.id) {
      dispatch(getPaymentMethod(currentUser?.currentuserdata?.data?.id));
    }
  }, [currentUser?.currentuserdata?.data?.id]);

  useEffect(() => {
    if (currentUser?.currentuserdata.length === 0) {
      dispatch(getCurrentUser());
    }
  }, []);

  const paymentIntent = searchParams.get("payment_intent_client_secret");

  const getPaymentIntent = async () => {
    if (stripe && paymentIntent) {
      const res = await stripe.retrievePaymentIntent(paymentIntent);

      if (res.paymentIntent.status === "succeeded") {
        // searchParams.delete("payment_intent_client_secret");
        toast((t) => (
          <ToastContent
            t={t.id}
            message="Payment successful"
            icon={<Check size={12} />}
          />
        ));
      } else if (res.paymentIntent.status === "requires_payment_method") {
        if (res.paymentIntent.last_payment_error) {
          if (res.paymentIntent.last_payment_error.code === "card_declined") {
            toast((t) => (
              <ToastContent
                t={t.id}
                message={res.paymentIntent.last_payment_error.message}
                icon={<X size={12} />}
                color="danger"
              />
            ));
          } else if (
            res.paymentIntent.last_payment_error.code ===
            "payment_intent_authentication_failure"
          ) {
            toast((t) => (
              <ToastContent
                t={t.id}
                message="The Provided PaymentMethod Has Failed Authentication."
                icon={<X size={12} />}
                color="danger"
              />
            ));
          } else {
            toast((t) => (
              <ToastContent
                t={t.id}
                message="Project not created"
                icon={<X size={12} />}
                color="danger"
              />
            ));
          }
        }
      } else {
        toast((t) => (
          <ToastContent
            t={t.id}
            message="Project not created"
            icon={<X size={12} />}
            color="danger"
          />
        ));
      }
      navigate({
        search: "",
      });
    }
  };
  useEffect(() => {
    getPaymentIntent();
  }, [stripe]);

  useEffect(() => {
    if (isOuterLoading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [isOuterLoading]);

  return (
    <>
      <UILoader blocking={isOuterLoading} loader={<Loader />}>
        <div className="plans-offer">
          {/* {!title ? (
            <>
              <div className="row justify-content-between align-items-center mb-2">
                <div className="d-flex back-div col-lg-9 col-12"></div>
                <div className="col-12 col-md-4 col-lg-3 text-start text-lg-end">
                  <OrderButton
                    setProjectInfoModel={setProjectInfoModel}
                    projectInfoModel={projectInfoModel}
                  />
                </div>
              </div>
              <h1 className="text-center my-2 section-title">Plans we offer</h1>
            </>
          ) : null} */}

          <Row className="pricing-card">
            <Col xs={12} md={12} lg={12} xl={11} xxl={9} className="m-auto">
              <Row>
                {subscriptionData?.plans?.isFetching && <PlansSkeleton />}
                {subscriptionData?.plans?.data?.map((row) => (
                  <Col xs={12} md={6} lg={6} xl={4} key={row.id}>
                    <Card
                      className={classnames("bordered shadow-none ", {
                        ["active-plan"]:
                          row?.id ===
                          currentUser?.currentuserdata?.data?.subscription_id,
                        ["upcoming-plan"]:
                          row?.id ===
                          currentUser?.currentuserdata?.data
                            ?.upcoming_subscription_id,
                      })}
                    >
                      <CardBody>
                        <div className="d-flex align-items-center subscriptionTitle">
                          <h2>{row.subscription_name}</h2>
                          {/* {row?.id ===
                          currentUser?.currentuserdata?.data
                            ?.subscription_id && (
                          <Badge color="light-success" className="ms-75 h-100">
                            Current Subscription
                          </Badge>
                        )} */}
                          {/* {currentUser?.currentuserdata?.data
                          ?.upcoming_subscription_id
                          ? row?.id ===
                              currentUser?.currentuserdata?.data
                                ?.upcoming_subscription_id && (
                              <Badge
                                color="light-warning"
                                className="ms-75 h-100"
                              >
                                Upcoming
                              </Badge>
                            )
                          : ""} */}
                        </div>
                        <div className="annual-plan">
                          <div className="price-div">
                            <span className="price text-break">
                              $ {row?.price}
                            </span>
                            <span className="pricing-duration text-body font-medium-1  ms-25">
                              / {row?.payment_cycle_in_month} mo
                            </span>
                          </div>
                        </div>
                      </CardBody>
                      <div className="middle-points">
                        <CardBody>
                          <p className="text-break">{row.description}</p>
                        </CardBody>
                      </div>
                      {/* subscription end date ========================================================== */}
                      {row?.id ===
                        currentUser?.currentuserdata?.data?.subscription_id &&
                        !currentUser?.currentuserdata?.data
                          .upcoming_subscription_id &&
                        !!currentUser?.currentuserdata?.data
                          ?.subscription_end_date && (
                          <CardBody className="py-0">
                            {/* <div style={{ color: "red", fontWeight: 500 }}>
                              Subscription End Date :{" "}
                              {dateFormat(
                                currentUser?.currentuserdata?.data
                                  ?.subscription_end_date
                              )}
                            </div> */}
                            <Badge color="danger">
                              Subscription End Date :{" "}
                              {dateFormat(
                                currentUser?.currentuserdata?.data
                                  ?.subscription_end_date
                              )}
                            </Badge>
                          </CardBody>
                        )}
                      {/* ========================================================== */}
                      <CardBody
                        className={`pb-0 ${
                          row?.id ===
                            currentUser?.currentuserdata?.data
                              ?.subscription_id &&
                          !currentUser?.currentuserdata?.data
                            .upcoming_subscription_id &&
                          !!currentUser?.currentuserdata?.data
                            ?.subscription_end_date &&
                          " mt-0 pt-10"
                        }`}
                      >
                        {row?.discount && (
                          <ul className="mb-0">
                            <li className="text-break">
                              <Discount />
                              {row?.discount}% discount on Services
                            </li>
                          </ul>
                        )}
                      </CardBody>

                      <CardBody className="pt-0">
                        {/* <div className="d-flex"> */}
                        {/* Pay remaining button  */}
                        {currentUser?.currentuserdata?.data?.subscription_id ===
                          row?.id && (
                          <>
                            <Button
                              block
                              disabled={
                                currentUser?.currentuserdata?.data
                                  ?.reamining_amount ||
                                currentUser?.currentuserdata?.data
                                  ?.subscription_end_date
                              }
                              color="primary"
                              className="d-flex align-items-center justify-content-center"
                              onClick={() => {
                                setPayTooltipOpen({});
                                // setOpenRemaining({
                                //   isOpen: true,
                                //   data: row,
                                // });

                                setDeleteModelOpen({
                                  isOpen: true,
                                  value: row,
                                });
                              }}
                            >
                              {/* <span>
                                {currentUser?.currentuserdata?.data
                                  ?.reamining_amount
                                  ? "No Outstanding Payment"
                                  : "Pay Remaining Balance"}
                              </span>{" "} */}
                              Cancel Subscription
                              <Info
                                className={`ms-25 ${
                                  currentUser?.currentuserdata?.data
                                    ?.reamining_amount
                                    ? "d-none"
                                    : ""
                                }`}
                                size="18"
                                id={`Remaining${row.id}`}
                                onMouseEnter={() => {
                                  setPayTooltipOpen({
                                    ...PaytooltipOpen,
                                    [row.id]: true,
                                  });
                                }}
                                onMouseLeave={() => {
                                  setPayTooltipOpen({
                                    ...PaytooltipOpen,
                                    [row.id]: false,
                                  });
                                }}
                              />
                            </Button>
                            <Tooltip
                              className="custom-tooltip"
                              id={row.id}
                              placement="top"
                              isOpen={PaytooltipOpen[row.id]}
                              target={`Remaining${row.id}`}
                            >
                              <p style={{ margin: 0 }}>
                                Settle any due balance on your subscription now.
                                After clearing the balance, your subscription is
                                set to auto-renew annually, with subsequent
                                monthly charges tailored to your subscription
                                tier.
                              </p>
                            </Tooltip>

                            {/* <UncontrolledTooltip
                            className="custom-tooltip"
                            target={`Remaining${row.id}`}
                          >
                            Settle any due balance on your subscription now.
                            After clearing the balance, your subscription is set
                            to auto-renew annually, with subsequent monthly
                            charges tailored to your subscription tier.
                          </UncontrolledTooltip> */}
                          </>
                        )}
                        {/* Upgrade Button */}
                        {currentUser?.currentuserdata?.data
                          ?.user_subscription_id &&
                          row?.id !==
                            currentUser?.currentuserdata?.data
                              ?.subscription_id &&
                          Number(
                            currentUser?.currentuserdata?.data?.subscription_id
                          ) < Number(row?.id) && (
                            <>
                              <Button
                                block
                                disabled={
                                  row?.id ===
                                  currentUser?.currentuserdata?.data
                                    ?.upcoming_subscription_id
                                }
                                color="primary"
                                style={{
                                  visibility: `${
                                    Number(
                                      currentUser?.currentuserdata?.data
                                        ?.subscription_id
                                    ) > Number(row?.id)
                                      ? "hidden"
                                      : "visible"
                                  }`,
                                }}
                                // onClick={() => {
                                //   setTooltipOpen({});
                                //   setOpenTermModel({
                                //     isOpen: true,
                                //     for: "upgrade",
                                //     data: row,
                                //   });
                                // }}

                                onClick={() => {
                                  openEditModal(row);
                                  // setOpenTermModel(true);
                                  setOpenTermModel({
                                    isOpen: true,
                                    for: "change",
                                    data: row,
                                  });
                                }}
                              >
                                <span>
                                  {row?.id ===
                                  currentUser?.currentuserdata?.data
                                    ?.upcoming_subscription_id
                                    ? "Upcoming Subscription"
                                    : "Change Subscription"}
                                </span>{" "}
                                <Info
                                  className="ms-25"
                                  size="18"
                                  id={`upgrade${row.id}`}
                                  onMouseEnter={() => {
                                    setTooltipOpen({
                                      ...tooltipOpen,
                                      [row.id]: true,
                                    });
                                  }}
                                  onMouseLeave={() => {
                                    setTooltipOpen({
                                      ...tooltipOpen,
                                      [row.id]: false,
                                    });
                                  }}
                                />
                              </Button>

                              <Tooltip
                                className="custom-tooltip"
                                id={row.id}
                                placement="top"
                                isOpen={tooltipOpen[row.id]}
                                target={`upgrade${row.id}`}
                              >
                                <p style={{ margin: 0 }}>
                                  You can upgrade your subscription mid-cycle.
                                  The original subscription end date remains
                                  unchanged, resulting in a prorated allocation
                                  of carats for the remaining days in your
                                  current term. Your upgraded subscription will
                                  auto-renew on your original end date if you do
                                  not cancel.
                                </p>
                              </Tooltip>
                            </>
                          )}

                        {currentUser?.currentuserdata?.data
                          ?.user_subscription_id &&
                          row?.id !==
                            currentUser?.currentuserdata?.data
                              ?.subscription_id &&
                          row?.id !==
                            currentUser?.currentuserdata?.data
                              ?.subscription_id &&
                          Number(row?.id) <
                            Number(
                              currentUser?.currentuserdata?.data
                                ?.subscription_id
                            ) && (
                            <>
                              <Button
                                disabled={
                                  row?.id ===
                                    currentUser?.currentuserdata?.data
                                      ?.subscription_id ||
                                  row?.id ===
                                    currentUser?.currentuserdata?.data
                                      ?.upcoming_subscription_id ||
                                  isLoading
                                }
                                block
                                outline={
                                  row?.id ===
                                    currentUser?.currentuserdata?.data
                                      ?.subscription_id ||
                                  row?.id ===
                                    currentUser?.currentuserdata?.data
                                      ?.upcoming_subscription_id
                                }
                                color="primary"
                                onClick={() => {
                                  setCurrentToolTipOpen({});
                                  openEditModal(row);
                                  setOpenTermModel({
                                    isOpen: true,
                                    for: "change",
                                    data: row,
                                  });
                                }}
                                className="mt-2"
                              >
                                <span>
                                  {" "}
                                  {currentUser?.currentuserdata?.data
                                    ?.user_subscription_id === null ||
                                  currentUser?.currentuserdata?.data
                                    ?.user_subscription_id === undefined
                                    ? "Buy Subscription"
                                    : row?.id ===
                                      currentUser?.currentuserdata?.data
                                        ?.subscription_id
                                    ? "Current Subscription"
                                    : row?.id ===
                                      currentUser?.currentuserdata?.data
                                        ?.upcoming_subscription_id
                                    ? "Upcoming Subscription"
                                    : "Change Subscription"}
                                </span>

                                {currentUser?.currentuserdata?.data
                                  ?.upcoming_subscription_id !== row.id ? (
                                  <>
                                    <Info
                                      className="ms-25"
                                      size="18"
                                      id={`current${row.id}`}
                                      onMouseEnter={() => {
                                        setCurrentToolTipOpen({
                                          ...currentToolTipOpen,
                                          [row.id]: true,
                                        });
                                      }}
                                      onMouseLeave={() => {
                                        setCurrentToolTipOpen({
                                          ...currentToolTipOpen,
                                          [row.id]: false,
                                        });
                                      }}
                                    />
                                    <Tooltip
                                      className="custom-tooltip"
                                      id={row.id}
                                      placement="top"
                                      isOpen={currentToolTipOpen[row.id]}
                                      target={`current${row.id}`}
                                    >
                                      <p style={{ margin: 0 }}>
                                        By selecting this option you will be
                                        downgrading your plan to the selected
                                        tier.
                                        {/* <br /> */}
                                        <b>
                                          {" "}
                                          This change will only take effect at
                                          the end of your current one-year term.
                                        </b>{" "}
                                        {/* <br /> */}
                                        You may not downgrade your plan to take
                                        effect mid-cycle
                                      </p>
                                    </Tooltip>
                                  </>
                                ) : (
                                  ""
                                )}
                              </Button>
                            </>
                          )}

                        {/* Buy Button */}
                        {(currentUser?.currentuserdata?.data
                          ?.user_subscription_id === null ||
                          currentUser?.currentuserdata?.data
                            ?.user_subscription_id === undefined) && (
                          <Button
                            disabled={
                              row?.id ===
                                currentUser?.currentuserdata?.data
                                  ?.subscription_id ||
                              row?.id ===
                                currentUser?.currentuserdata?.data
                                  ?.upcoming_subscription_id ||
                              isLoading
                            }
                            block
                            outline={
                              row?.id ===
                                currentUser?.currentuserdata?.data
                                  ?.subscription_id ||
                              row?.id ===
                                currentUser?.currentuserdata?.data
                                  ?.upcoming_subscription_id
                            }
                            color="primary"
                            onClick={() => {
                              openEditModal(row);
                              // setOpenTermModel(true);
                              setOpenTermModel({
                                isOpen: true,
                                for: "change",
                                data: row,
                              });
                            }}
                            className="mt-2"
                          >
                            {currentUser?.currentuserdata?.data
                              ?.user_subscription_id === null ||
                            currentUser?.currentuserdata?.data
                              ?.user_subscription_id === undefined
                              ? "Purchase Subscription"
                              : "Change Subscription"}
                          </Button>
                        )}

                        {/* </div> */}
                      </CardBody>
                      <div className="card-badge">
                        {row?.id ===
                          currentUser?.currentuserdata?.data
                            ?.subscription_id && (
                          <Badge color="light-success" className="ms-75 h-100">
                            Current Subscription
                          </Badge>
                        )}
                        {currentUser?.currentuserdata?.data
                          ?.upcoming_subscription_id
                          ? row?.id ===
                              currentUser?.currentuserdata?.data
                                ?.upcoming_subscription_id && (
                              <Badge
                                color="light-warning"
                                className="ms-75 h-100"
                              >
                                Upcoming Subscription
                              </Badge>
                            )
                          : ""}
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </div>
      </UILoader>
      {/* {!title ? (
        <>
          <hr />
          <div className="text-center d-flex flex-column flex-md-row justify-content-center">
            <Button.Ripple
              className="orange-btn"
              color="flat-primary"
              onClick={(e) => {
                e.preventDefault();
                setModalOpen(true);
              }}
            >
              Compare With Pay As You Go Pricing
            </Button.Ripple>
            <Button.Ripple
              className="orange-btn ms-0 ms-md-1 mt-1 mt-md-0"
              color="flat-primary"
              onClick={(e) => {
                e.preventDefault();
                setPricingCalculator(true);
              }}
            >
              Calculate the Right Package for You!
            </Button.Ripple>
          </div>
          <hr />
        </>
      ) : null} */}
      {/* <StripeElement> */}
      <PaymentWizard
        show={paymentPopup}
        setShow={setPaymentPopup}
        module="subscription"
        setConfSubscription={setConfSubscription}
        setIsloading={setIsloading}
      />

      {/* <PricingCalculatorModel
        setShow={setPricingCalculator}
        show={pricingCalculator}
      /> */}
      {/* </StripeElement> */}

      {/* {modalOpen && (
        <WPCalculator modalOpen={modalOpen} setModalOpen={setModalOpen} />
      )} */}

      <ConfirmModel
        disabled={isLoading}
        // title="Are you sure you want to purchase this subscription?"
        title={
          currentUser?.currentuserdata?.data?.user_subscription_id === null ||
          currentUser?.currentuserdata?.data?.user_subscription_id === undefined
            ? "Are you sure you want to purchase this subscription?"
            : "Are you sure you want to change your plan?"
        }
        info={
          currentUser?.currentuserdata?.data?.subscription_id
            ? "We would like to inform you that this change will only take effect at the end of your current cycle."
            : ""
        }
        open={isTermsAccept && confSubscription.open}
        setOpen={handleConfSubscription}
        handleClick={handlePlan}
        primaryBtnTitle={isLoading ? "Loading..." : "Yes"}
        modalSize="modal-lg"
      />
      <TermAndCondition
        setIsTermsAccept={setIsTermsAccept}
        openTermModel={openTermModel}
        setOpenTermModel={setOpenTermModel}
        module={MODULE.SUBSCRIPTION}
        setOpenRemaining={setOpenRemaining}
        setOpenUpgrade={setOpenUpgrade}
      />

      <PayRemainingBalanceModal
        openRemaining={openRemaining}
        setOpenRemaining={setOpenRemaining}
        modalSize="modal-lg"
      />

      <UpgradeSubscriptionModal
        openUpgrade={openUpgrade}
        setOpenUpgrade={setOpenUpgrade}
        modalSize="modal-lg"
      />

      <CPtoClipBoardModal setCopyModal={setCopyModal} CopyModal={CopyModal} />
      {projectInfoModel && (
        <CreateProjectInfoModel
          show={projectInfoModel}
          setShow={setProjectInfoModel}
        />
      )}

      {deleteModelOpen?.isOpen && (
        <ConfirmModel
          disabled={deleteModelOpen?.isDisabled}
          handleClick={handleDeleteSubscription}
          btnColor="danger"
          primaryBtnTitle={deleteModelOpen?.isDisabled ? "Canceling..." : "Yes"}
          secondaryBtnText="No"
          open={deleteModelOpen?.isOpen}
          setOpen={setDeleteModelOpen}
          title="Are you sure you want to cancel this subscription?"
        />
      )}
    </>
  );
};

export default SubscriptionManagement;
