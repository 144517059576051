/* eslint-disable no-unused-vars */
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormFeedback, Input } from "reactstrap";
import { RenderDynamicFiledLabel } from "./index";

const FormFileUploader = ({ fieldData }) => {
  const { control, errors, watch, fieldsDisabled } = useFormContext();
  const value = watch(fieldData?.name);
  const isString = typeof value === "string";
  // render default field value
  const renderDefaultValue = () => {
    return (
      isString && (
        <b className="ms-75">
          Click :&nbsp;&nbsp;
          <a href={value} target="_blank" className="text-decoration-underline">
            {value.split("/").pop()}
          </a>
        </b>
      )
    );
  };

  return (
    <React.Fragment>
      {/* Label */}
      <RenderDynamicFiledLabel
        fieldData={fieldData}
        // extraData={renderDefaultValue()}
        extraData={
          <span style={{ color: "red", marginLeft: "5px" }}>
            pending functionality !!
          </span>
        }
      />

      <Controller
        id={fieldData?.name}
        name={fieldData?.name}
        control={control}
        render={({ field: { onChange, value, ...fieldProps } }) => {
          if (value) {
            console.log(value);
          }
          return (
            <Input
              {...fieldProps}
              disabled={fieldsDisabled}
              type="file"
              placeholder={fieldData?.placeholder}
              onChange={(e) => {
                onChange(e.target.files[0]);
              }}
            />
          );
        }}
      />
      {errors && errors[fieldData?.name] && (
        <FormFeedback>{errors[fieldData?.name].message}</FormFeedback>
      )}
    </React.Fragment>
  );
};

export default FormFileUploader;
