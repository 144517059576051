/* eslint-disable no-confusing-arrow */
/* eslint-disable multiline-ternary */
// import { createColumnHelper } from "@tanstack/react-table";

// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect, useRef } from "react";
import { Button, Row, Col, Card } from "reactstrap";
import { dateFormat } from "../../../../common/functions/helperFunctions";
import { ReactComponent as ArchiveIcon } from "../../../../assets/images/pages/archive.svg";
import { Trash, MessageSquare, PlusCircle, Edit } from "react-feather";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import Select from "react-select";
import { ADMIN_ROUTES } from "../../../../constant/route";
import { DropdownStyles } from "../../../../utility/Utils";

// const columnHelper = createColumnHelper();

export const ststusOptions = [
  { value: "requested", label: "Project Requested" },
  { value: "footage_received", label: "Footage Received" },
  { value: "assigned_to_editor", label: "Assigned to Editor" },
  { value: "editing_in_progress", label: "Editing in Progress" },
  { value: "revision_in_progress", label: "Revision in Progress" },
  { value: "edit_complete", label: "Edit Complete" },
];

export const projectTitle = (data) => {
  const d = data.split(":");
  const key = d[0];
  const val = d[1];

  switch (key) {
    case "insta_video":
      return `Instagram Video :  ${val?.replace(/_/g, " ")}`;

    case "highlight_video":
      return `Music Highlight Video – Music Only : ${val?.replace(
        /_([^_]*)_/g,
        "-$1 "
      )}s`;

    case "narrative_highlight_video":
      return `Narrative Highlight Video – Music + Dialogue : ${val?.replace(
        /_([^_]*)_/g,
        "-$1 "
      )}s`;

    case "cinematic_film":
      return `Cinematic Feature Film – Music + Dialogue : ${val?.replace(
        /_([^_]*)_/g,
        "-$1 "
      )}s`;

    case "documentary_edits":
      return `Documentary Edits :  ${
        val === "ceremony_reception_MCE"
          ? "ceremony + reception Edit"
          : val?.replace(/_/g, " ").replace("MCE", "Edit")
      }`;
    default:
      return "-";
  }
};

export const defaultColumns = [
  // columnHelper.accessor((row) => row.company_name, {
  //   // accessorKey: "companyName",
  //   id: "company_name",
  //   header: "Company Name",
  //   cell: (info) => info.getValue(),
  // }),
  {
    // accessorKey: "companyName",
    accessorFn: (row) => row.sr_no,
    id: "no",
    // size: 100,
    size: 80,
    minSize: 30,
    maxSize: Number.MAX_SAFE_INTEGER,
    header: "No.",
    cell: (info) => info.getValue(),
  },
  {
    // accessorKey: "companyName",
    accessorFn: (row) => row.company_name,
    id: "companyName",
    // size: 100,
    size: 200,
    minSize: 80,
    maxSize: Number.MAX_SAFE_INTEGER,
    header: "Company Name",
    cell: (props) => {
      // const navigate = props.table.options.meta?.navigate;
      return (
        <>
          <span
            className={`on-hover-table-data ${
              props.row.original?.subscription_id !== null
                ? "table-custom-column"
                : ""
            }`}
            // onClick={() =>
            //   navigate(ADMIN_ROUTES.CLIENT_DETAIL, {
            //     state: props.row.original.user_id,
            //   })
            // }
          >
            <p className="custom-project-table-width table-text-break">
              {props.row.original.company_name
                ? props.row.original.company_name
                : "-"}
            </p>
          </span>
        </>
      );
    },
  },
  {
    // accessorKey: "orderDate",
    // header: () => <span>Last Name</span>,
    accessorFn: (row) =>
      row.invoice_due_date ? dateFormat(row.invoice_due_date) : "-",
    id: "orderDate",
    // size: 100,
    size: 170,
    minSize: 80,
    maxSize: Number.MAX_SAFE_INTEGER,
    cell: (info) => info.getValue(),
    header: "Order Date",
  },
  {
    // accessorKey: "projectName",
    accessorFn: (row) => row.project_name,
    id: "projectName",
    // size: 100,
    size: 280,
    minSize: 80,
    maxSize: Number.MAX_SAFE_INTEGER,
    header: "Project Name",
    cell: (props) => {
      // const navigate = props.table.options.meta?.navigate;
      return (
        <>
          <p
            className="project-name-table click-name break-word table-text-break"
            // onClick={() =>
            //   navigate(ADMIN_ROUTES.PROJECT_INFO, {
            //     state: row.sub_project_id,
            //   })
            // }
          >
            {props.row.original.project_name}
          </p>
        </>
      );
    },
  },
  {
    // accessorKey: "projectType",
    accessorFn: (row) => projectTitle(row.sub_order),
    id: "projectType",
    // size: 100,
    size: 475,
    minSize: 80,
    maxSize: Number.MAX_SAFE_INTEGER,
    header: "Project Type",
    cell: (props) => {
      return (
        <>
          <p className="table-text-break">
            {/* {props.row.original} */}
            {projectTitle(props.row.original.sub_order)}
          </p>
        </>
      );
    },
  },
  {
    // accessorKey: "languageInformation",
    accessorFn: (row) =>
      row?.wedding_language === null ? "--" : row?.wedding_language,
    id: "languageInformation",
    // size: 100,
    size: 170,
    minSize: 80,
    maxSize: Number.MAX_SAFE_INTEGER,
    header: "Language",
    cell: ({ getValue }) => {
      return <p className="table-text-break">{getValue()}</p>;
    },
  },
  {
    accessorKey: "nle_software",
    // accessorFn: (row) =>
    //   row?.nle_software === null ? "--" : row?.nle_software?.replace(/_/g, " "),
    id: "NLE",
    // size: 100,
    size: 320,
    minSize: 80,
    maxSize: Number.MAX_SAFE_INTEGER,
    header: "NLE Software",
    cell: ({ getValue }) => {
      return (
        <p style={{ textTransform: "uppercase" }}>
          {getValue() === null ? "--" : getValue()?.replace(/_/g, " ")}
        </p>
      );
    },
  },
  {
    // accessorKey: "footage",
    accessorFn: (row) => row.footage_transfer_method,
    id: "footage",
    // size: 100,
    size: 250,
    minSize: 80,
    maxSize: Number.MAX_SAFE_INTEGER,
    header: "Footage",
    cell: ({ getValue, row }) => {
      return (
        <>
          <span>
            {getValue() ? (
              getValue() === "online" ? (
                <a
                  className="click-name"
                  href={row.original.online_footage_link}
                  target="__blank"
                >
                  Link
                </a>
              ) : (
                <span>
                  <p className="table-text-break">
                    {row.original.tracking_number} <br />
                    {row.original.shipping_service_text}
                  </p>
                </span>
              )
            ) : (
              "-"
            )}
          </span>
        </>
      );
    },
  },
  {
    // accessorKey: "feedback",
    accessorFn: (row) => row.project_name,
    id: "feedback",
    // size: 100,
    size: 130,
    minSize: 80,
    maxSize: Number.MAX_SAFE_INTEGER,
    header: "Feedback",
    cell: (props) => {
      const handleChatOpen = props.table.options.meta?.handleChatOpen;
      const unReadMessageCount = props.table.options.meta?.unReadMessageCount;
      return (
        <span
          className="click-name list-feedback-msg"
          onClick={() => {
            handleChatOpen(props.row.original);
          }}
        >
          <div className="chat-with-icon">
            <label className="chat-icon-tb cursor-pointer position-relative">
              <MessageSquare />
              {unReadMessageCount(props.row.original.sub_project_id) && (
                <Badge color="primary" className="badge-up">
                  {" "}
                  {unReadMessageCount(props.row.original.sub_project_id)}
                </Badge>
              )}
            </label>
          </div>
        </span>
      );
    },
  },
  // {
  //   accessorKey: "privacy_policy",
  //   // accessorFn: (row) =>
  //   //   row?.privacy_policy === null
  //   //     ? "--"
  //   //     : row?.privacy_policy?.replace(/_/g, " "),
  //   id: "privacyPolicy",
  //   // size: 100,
  //   size: 320,
  //   minSize: 80,
  //   maxSize: Number.MAX_SAFE_INTEGER,
  //   header: "Privacy Policy",
  //   cell: (props) => {
  //     return (
  //       <p className="text-capitalize">
  //         {/* custom-project-type-td */}
  //         {props.getValue() === null
  //           ? "--"
  //           : props.getValue()?.replace(/_/g, " ")}
  //       </p>
  //     );
  //   },
  // },
  {
    // accessorKey: "ETA",
    accessorFn: (row) => row.project_name,
    id: "ETA",
    // size: 100,
    size: 260,
    minSize: 80,
    maxSize: Number.MAX_SAFE_INTEGER,
    padding: "10px",
    header: "Estimated Completion Date",
    cell: (props) => {
      const etaDates = props.table.options.meta?.etaDates;
      const handleETADate = props.table.options.meta?.handleETADate;
      return (
        <ReactDatePicker
          id="ETA"
          name={`ETA_${props.row.original.sub_project_id}`}
          placeholderText="ETA"
          selected={etaDates[`${props.row.original.sub_project_id}`]}
          onChange={(date) =>
            handleETADate(date, props.row.original.sub_project_id)
          }
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          className="form-control"
          dateFormat="MM/dd/yy"
          minDate={moment().toDate()}
          calendarClassName="custom-datepicker-calendar"
          popperClassName="custom-datepicker-popper"
          onKeyDown={(e) => {
            e.preventDefault();
          }}
        />
      );
    },
  },
  {
    // accessorKey: "reviewLink",
    accessorFn: (row) => row.project_review_link,
    id: "reviewLink",
    // size: 100,
    // size: 200,
    minSize: 390,
    maxSize: Number.MAX_SAFE_INTEGER,
    header: "Review Link",
    cell: (props) => {
      const renderMessageSegments =
        props.table.options.meta?.renderMessageSegments;
      const setOpenLinkModel = props.table.options.meta?.setOpenLinkModel;
      return (
        <>
          {props.getValue() ? (
            <>
              <Row>
                <Col className="click-name list-feedback-msg d-flex justify-content-center align-items-center">
                  <p className="me-1" style={{ wordBreak: "break-all" }}>
                    {renderMessageSegments(props.getValue())}
                  </p>
                  <Edit
                    size={16}
                    className="cursor-pointer"
                    style={{ width: "30px" }}
                    onClick={() =>
                      setOpenLinkModel({
                        type: "edit",
                        id: props.row.original.sub_project_id,
                        review_link: props.getValue(),
                      })
                    }
                  />
                </Col>
              </Row>
            </>
          ) : (
            <>
              <PlusCircle
                className="cursor-pointer"
                onClick={() =>
                  setOpenLinkModel({
                    type: "add",
                    id: props.row.original.sub_project_id,
                  })
                }
              />
            </>
          )}
        </>
      );
    },
  },
  {
    // accessorKey: "assignedEditor",
    accessorFn: (row) => row.editor_id,
    id: "assignedEditor",
    // size: 100,
    size: 260,
    minSize: 80,
    maxSize: Number.MAX_SAFE_INTEGER,
    padding: "10px",
    header: "Assigned Editor",
    cell: (props) => {
      const editorsOptions = props.table.options.meta?.editorsOptions;
      const handleEditorAssigned =
        props.table.options.meta?.handleEditorAssigned;
      const selectThemeColors = props.table.options.meta?.selectThemeColors;
      return (
        <Select
          menuPortalTarget={document.body}
          id="assign_editor"
          placeholder="Select Editor"
          className="react-select custom-table-react-se"
          classNamePrefix="select"
          isClearable={false}
          styles={DropdownStyles}
          options={editorsOptions?.data}
          getOptionValue={(opt) => opt.id}
          getOptionLabel={(opt) => opt.editor_name}
          theme={selectThemeColors}
          // value={getProjectStatus(row?.project_status)}
          defaultValue={editorsOptions?.data?.find(
            (item) => item.id === props.getValue()
          )}
          onChange={(e) =>
            handleEditorAssigned(e, props.row.original.sub_project_id)
          }
        />
      );
    },
  },
  {
    // accessorKey: "projectStatus",
    accessorFn: (row) => row.project_status,
    id: "projectStatus",
    // size: 100,
    size: 260,
    minSize: 80,
    maxSize: Number.MAX_SAFE_INTEGER,
    padding: "10px",
    header: "Project Status",
    cell: (props) => {
      const selectThemeColors = props.table.options.meta?.selectThemeColors;
      const getProjectStatus = props.table.options.meta?.getProjectStatus;
      const changeStatusFun = props.table.options.meta?.changeStatusFun;

      return (
        <>
          {props.getValue() === "approved_by_client" ? (
            "Approved By Client"
          ) : (
            <Select
              menuPortalTarget={document.body}
              id="filter"
              placeholder="Select Filter"
              styles={DropdownStyles}
              className="react-select custom-table-react-se"
              classNamePrefix="select"
              isClearable={false}
              options={ststusOptions}
              theme={selectThemeColors}
              value={getProjectStatus(props.getValue())}
              onChange={(e) =>
                changeStatusFun(e, props.row.original.sub_project_id)
              }
            />
          )}
        </>
      );
    },
  },
  {
    // accessorKey: "actions_archive_delete",
    id: "actions_archive_delete",
    // size: 120,
    size: 260,
    minSize: 120,
    maxSize: Number.MAX_SAFE_INTEGER,
    header: "ARCHIVED OR DELETE",
    cell: ({ row, table }) => {
      const handleArchived = table.options.meta?.handleArchived;
      const handleDeleteModal = table.options.meta?.handleDeleteModal;
      return (
        <>
          {/* <td className="text-center"> */}
          <Button.Ripple
            className="btn-icon rounded-circle me-50"
            color="flat-primary"
            onClick={() => handleArchived(row.original.sub_project_id)}
          >
            <ArchiveIcon />
          </Button.Ripple>
          <Button.Ripple
            className="btn-icon rounded-circle"
            color="flat-danger"
            onClick={
              () => handleDeleteModal(row.original.sub_project_id)
              // handleDeleteProject(row.sub_project_id)
            }
          >
            <Trash size={16} />
          </Button.Ripple>
          {/* </td> */}
        </>
      );
    },
  },
];

// export const TruncatedTextDiv = ({ initialText }) => {
//   const [truncatedText, setTruncatedText] = useState(initialText);
//   const divTextRef = useRef(null);

//   useEffect(() => {
//     const truncateText = () => {
//       const divText = divTextRef.current;
//       const maxHeight = divText.offsetHeight;

//       while (divText.scrollHeight > maxHeight) {
//         setTruncatedText((prevText) => prevText.replace(/\W*\s(\S)*$/, "..."));
//       }
//     };

//     truncateText();
//   }, []);

//   return (
//     <div ref={divTextRef} className="button-text-test">
//       {truncatedText}
//     </div>
//   );
// };

export const TruncatedTextDiv = ({ initialText }) => {
  return (
    <div className="truncated-button">
      <span className="text">{initialText}</span>
    </div>
  );
};

export const reorderColumn = (draggedColumnId, targetColumnId, columnOrder) => {
  columnOrder.splice(
    columnOrder.indexOf(targetColumnId),
    0,
    columnOrder.splice(columnOrder.indexOf(draggedColumnId), 1)[0]
  );
  return [...columnOrder];
};

export const ststusOptionsTab = [
  // { value: "all", label: "All Projects" },
  { value: "requested", label: "Project Requested" },
  { value: "footage_received", label: "Footage Received" },
  { value: "assigned_to_editor", label: "Assigned to Editor" },
  { value: "editing_in_progress", label: "Editing in Progress" },
  { value: "revision_in_progress", label: "Revision in Progress" },
  { value: "edit_complete", label: "Edit Complete" },
  { value: "approved_by_client", label: "Approved" },
];

export const ProjectTypesLabels = [
  ["insta_video", "Instagram video"],
  ["highlight_video", "Music Highlight Video - Music only"],
  ["narrative_highlight_video", "Narrative Highlight video - Music + Dialogue"],
  ["cinematic_film", "Cinematic Feature Film - Music + Dialogue"],
  ["documentary_edits", "Documentary edits"],
];

export const projectTypeOptions = [
  {
    id: 1,
    value: "insta_video:1_minute",
    label: "Instagram Video (1 Minute)",
    carats: "20 Coins",
    price: 249,
  },
  {
    id: 2,
    value: "highlight_video:2_3_minute",
    label: "Music Highlight Video - Music Only (2 - 3 Minutes)",
    carats: "32 Coins",
    price: 399,
  },
  {
    id: 3,
    value: "highlight_video:4_6_minute",
    label: "Music Highlight Video - Music Only (4 - 6 Minutes)",
    carats: "40 Coins",
    price: 499,
  },
  {
    id: 4,
    value: "narrative_highlight_video:3_5_minute",
    label: "Narrative Highlight Video - Music + Dialogue (3 - 5 Minutes)",
    carats: "48 Coins",
    price: 599,
  },
  {
    id: 5,
    value: "narrative_highlight_video:6_8_minute",
    label: "Narrative Highlight Video - Music + Dialogue (6 - 8 Minutes)",
    carats: "53 Coins",
    price: 669,
  },
  {
    id: 6,
    value: "narrative_highlight_video:9_12_minute",
    label: "Narrative Highlight Video - Music + Dialogue (9 - 12 Minutes)",
    carats: "59 Coins",
    price: 739,
  },
  {
    id: 7,
    value: "cinematic_film:13_15_minute",
    label: "Cinematic Feature Film - Music + Dialogue (13 - 15 Minutes)",
    carats: "64 Coins",
    price: 799,
  },
  {
    id: 8,
    value: "cinematic_film:16_20_minute",
    label: "Cinematic Feature Film - Music + Dialogue (16 - 20 Minutes)",
    carats: "70 Coins",
    price: 879,
  },
  {
    id: 9,
    value: "cinematic_film:21_25_minute",
    label: "Cinematic Feature Film - Music + Dialogue (21 - 25 Minutes)",
    carats: "79 Coins",
    price: 989,
  },
  {
    id: 10,
    value: "documentary_edits:ceremony_MCE",
    label: "Documentary Edits (Ceremony Edit)",
    carats: "8 Coins",
    price: 99,
  },
  {
    id: 11,
    value: "documentary_edits:reception_MCE",
    label: "Documentary Edits (Reception Edit)",
    carats: "8 Coins",
    price: 99,
  },
  {
    id: 12,
    value: "documentary_edits:dances_only",
    label: "Documentary Edits (Dances Only)",
    carats: "6 Coins",
    price: 75,
  },
  {
    id: 13,
    value: "documentary_edits:speeches_only",
    label: "Documentary Edits (Speeches Only)",
    carats: "6 Coins",
    price: 75,
  },
  {
    id: 14,
    value: "documentary_edits:ceremony_reception_MCE",
    label: "Documentary Edits (Ceremony + Reception Edit)",
    carats: "12 Coins",
    price: 149,
  },
  {
    id: 15,
    value: "documentary_edits:full_day_edit",
    label: "Documentary Edits (Full Day Edit)",
    carats: "20 Coins",
    price: 249,
  },
];

export const ProjectStatusColorCode = new Map([
  ["requested", "#8f5bd0"],
  ["footage_received", "#257ab3"],
  ["assigned_to_editor", "#6ac4fa"],
  ["editing_in_progress", "#f5a449"],
  ["revision_in_progress", "#d14854"],
  ["edit_complete", "#9cc941"],
  ["approved_by_client", "#43bb71"],
]);

export const NoDataFound = () => (
  <div className="no_data">
    <Card>
      <p>No Data Found</p>
    </Card>
  </div>
);

export const fetchKeys = (data) => {
  let dt = data;
  if (dt && dt !== "null" && dt !== "undefined" && JSON.stringify(dt)) {
    try {
      dt = JSON.parse(dt);
    } catch (error) {
      return "-";
    }
    const result = Object.keys(dt)
      .map((key) => {
        const matchingLabel = ProjectTypesLabels.find(
          (label) => label[0] === key
        );
        return matchingLabel ? matchingLabel[1] : null;
      })
      .filter((label) => label !== null)
      .join(", ");
    return result;
  } else {
    return "-";
  }
};

const checkValue = (string) => {
  if (string) {
    let str = string.trim(); // Use `let` instead of `const` to allow reassignment

    if (str.includes("minute")) {
      const lastHyphenIndex = str.lastIndexOf("-");

      if (lastHyphenIndex !== -1) {
        str = `${str.slice(0, lastHyphenIndex)} ${str.slice(
          lastHyphenIndex + 1
        )}`;
        if (str.trim().split(" ")[0] !== "1") {
          str = str.replace(/\bminute\b/g, "Minutes");
        } else {
          str = str.replace(/\bminute\b/g, "Minute");
        }
        return str;
      }
    } else {
      return str;
    }
  } else {
    return "";
  }
};

// Rest of your code...

export const convertVideoData = (data) => {
  const dtt = [];
  try {
    if (data && data !== "null" && data !== "undefined") {
      Object.entries(JSON.parse(data)).forEach((item) => {
        const key = ProjectTypesLabels.filter((it) => it[0] === item[0]);
        if (key.length) {
          let val = "";
          if (Array.isArray(item[1])) {
            let valueData = "";
            item[1].forEach((dt) => {
              valueData = `${valueData} ${dt},\n`;
            });
            val = valueData.replace(/,\n$/, "");
          }
          dtt.push(
            <div key={key[0][1]} style={{ marginTop: "10px" }}>
              {key[0][1]} : {checkValue(val?.split("_")?.join("-"))}
            </div>
          );
        }
      });
      return dtt;
    } else {
      return dtt;
    }
  } catch (err) {
    return "";
  }
};
