/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Modal,
  Button,
  ModalBody,
  ModalHeader,
  Badge,
  Card,
  CardBody,
  CardHeader,
  ModalFooter,
} from "reactstrap";
import UILoader from "@components/ui-loader";
import Loader from "../../../../common/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
// import { dateFormat } from "../../../../common/functions/helperFunctions";
import {
  calculateProrate,
  upgradeSubscription,
} from "../../../../services/subscription.service";
import { getErrorMsg } from "../../../../common/ErrorMessages/errorMessage";
import LineSkelaton from "../../../../common/skeleton/LineSkelaton";
import {
  dateFormat,
  numberWithCommas,
} from "../../../../common/functions/helperFunctions";
import ToastContent from "../../../components/toast/toast";
import toast from "react-hot-toast";
import { Check, X } from "react-feather";
import { getCurrentUser } from "../../../../redux/currentUserSlice/currentUser.thunk";

const UpgradeSubscriptionModal = (props) => {
  const {
    openUpgrade,
    setOpenUpgrade,

    // modalSize,
    btnColor,
    outline,
    info,
  } = props;
  const dispatch = useDispatch();
  const paymentData = useSelector((state) => state.user.paymentMethods);
  const currentUser = useSelector(
    (state) => state.currentUser?.currentuserdata?.data
  );

  const [data, setData] = useState(null);

  const [loading, setIsLoading] = useState(false);
  const [submitLoading, setIsSubmitLoading] = useState(false);

  const apiCall = async () => {
    setIsLoading(true);
    try {
      const res = await calculateProrate({
        subscription_id: openUpgrade?.data?.id,
      });
      setData(res?.data);
      setIsLoading(false);
    } catch (error) {
      getErrorMsg(error);
      setIsLoading(false);
    }
  };

  const handleClick = () => {
    setIsSubmitLoading(true);
    upgradeSubscription({
      subscription_id: openUpgrade?.data?.id,
    })
      .then((res) => {
        toast((t) => (
          <ToastContent
            t={t.id}
            message={res?.message}
            icon={<Check size={12} />}
          />
        ));
        setIsSubmitLoading(false);
        dispatch(getCurrentUser());
        setOpenUpgrade({ isOpen: !openUpgrade?.isOpen, data: null });
      })
      .catch((err) => {
        setIsSubmitLoading(false);
        // getErrorMsg(err);
        if (err.response?.data?.data?.redirect_to) {
          const win = window.open(
            err.response?.data?.data?.redirect_to,
            "_self"
          );
          if (win === null || typeof win === "undefined") {
            // Popup blocked, display message in UI
            toast((t) => (
              <ToastContent
                t={t.id}
                message="Popup blocked. Please enable popups and try again."
                color="danger"
                icon={<X size={12} />}
              />
            ));
          }
        } else {
          getErrorMsg(err);
        }
      });
  };

  useEffect(() => {
    if (openUpgrade?.isOpen) {
      document.body.style.overflow = "hidden";
      apiCall();
    }

    return () => {
      document.body.style.overflow = "visible";
    };
  }, [openUpgrade?.isOpen]);

  return (
    <div>
      <Modal
        isOpen={openUpgrade?.isOpen}
        toggle={() =>
          setOpenUpgrade({ isOpen: !openUpgrade?.isOpen, data: null })
        }
        className={`modal-dialog-centered modal-lg `}
        // onClosed={onClose}
        backdrop="static"
        scrollable
      >
        <UILoader blocking={submitLoading} loader={<Loader />}>
          <ModalHeader
            className={`bg-transparent ${submitLoading && "btn-loading"}`}
            toggle={() =>
              setOpenUpgrade({ isOpen: !openUpgrade?.isOpen, data: null })
            }
          ></ModalHeader>
          <ModalBody className="px-sm-4  pb-5 ">
            <h1 className="text-center mb-2">Upgrade Your Subscription Now</h1>
            <p className="text-center">{info ? info : ""}</p>
            <Row
              className="gy-1 gx-2 "
              style={{ overflow: "auto", maxHeight: "59vh" }}
            >
              <Col xs={12} className="plan-info-modal ">
                <h5 className="Plan-detail d-flex align-items-start align-items-md-center flex-column flex-md-row w-100 ">
                  <span className="me-50 mb-0"> Current Plan : </span>
                  <span className="plan-name text-success">
                    {loading ? (
                      <LineSkelaton className=" ms-50 mb-0" />
                    ) : (
                      data?.current_plan
                    )}
                  </span>
                </h5>
              </Col>
              <Col xs={12} className="plan-info-modal ">
                <h5 className="Plan-detail d-flex align-items-start align-items-md-center flex-column flex-md-row w-100 ">
                  <span className="me-50 mb-0">
                    Subscription Upgraded to :{" "}
                  </span>
                  <span className="plan-name text-success">
                    {loading ? (
                      <LineSkelaton className=" ms-50 mb-0" />
                    ) : (
                      data?.upcoming_plan
                    )}
                  </span>
                </h5>
              </Col>
              <Col xs={12} className="plan-info-modal ">
                <h5 className="Plan-detail d-flex align-items-start align-items-md-center flex-column flex-md-row w-100 ">
                  <span className="me-50 mb-0">Subscription Period : </span>
                  <span className="plan-name text-success">
                    {loading ? (
                      <LineSkelaton className=" ms-50 mb-0" />
                    ) : (
                      `${dateFormat(data?.subscription_start_date)} -
                      ${dateFormat(data?.subscription_end_date)}`
                    )}
                  </span>
                </h5>
              </Col>
              <Col xs={12} className="plan-info-modal ">
                <p className="note">
                  <b>*Note :</b> You are upgrading mid-cycle. With this option,
                  your original subscription end date remains unchanged (you are
                  not extending your term), resulting in a prorated allocation
                  of carats for the remaining days in your current term. Your
                  plan will auto-renew at this upgraded subscription tier on
                  your original end date if you do not cancel. Please reach out
                  if you have any questions!
                </p>
              </Col>
              <Col xs={12} className="">
                {/* <div> */}
                {/* <h4 style={{ fontWeight: "700" }}>Cost Breakdown </h4> */}
                <h5 style={{ fontWeight: "700" }}>Current Plan Details</h5>
                <Card className="breakdownCard">
                  <CardBody>
                    <div className="cost-breakDown">
                      <h5 className="w-100 ">
                        Days Used :&nbsp;
                        <span className="plan-name text-success">
                          {data?.total_used_days}
                          &nbsp;
                          {data?.total_used_days <= 1 ? "Day" : "Days"}
                        </span>{" "}
                      </h5>
                    </div>
                    <h5 className="w-100 ">
                      Next Monthly Payment Date :{"  "}
                      <span className="plan-name text-success">
                        {data?.upcoming_invoice_date
                          ? dateFormat(data?.upcoming_invoice_date)
                          : "-"}
                      </span>{" "}
                    </h5>
                    <h5 className="w-100 ">
                      Total Carats in Current Plan :{"  "}
                      <span className="plan-name text-success">
                        {data?.upcoming_invoice_date
                          ? data?.current_plan_carat
                          : "-"}
                      </span>{" "}
                    </h5>
                  </CardBody>
                </Card>
                <h5 style={{ fontWeight: "700", marginTop: "25px" }}>
                  Upgraded Plan Details
                </h5>
                <Card className="breakdownCard">
                  <CardBody>
                    <div>
                      {/* <hr /> */}
                      <h5 className="w-100">
                        Total Carats in New Plan : {"  "}
                        <span className="plan-name text-success">
                          {numberWithCommas(data?.upcoming_plan_carat)}
                        </span>{" "}
                      </h5>
                      <h5 className="w-100">
                        Prorated Carats Credited Today : {"  "}
                        <span className="plan-name text-success">
                          {numberWithCommas(data?.prorate_carat)}
                        </span>{" "}
                      </h5>
                    </div>
                    <hr />
                    <div className="">
                      <h5 className=" text-start ">
                        Total Amount Payable Now : {"  "}
                        <span className="plan-name text-success">
                          ${numberWithCommas(data?.prorate_amount_to_pay)}
                        </span>{" "}
                      </h5>
                      <h5 className=" text-start ">
                        Future Monthly Payment : {"  "}
                        <span className="plan-name text-success">
                          ${numberWithCommas(data?.next_month_invoice)}
                        </span>{" "}
                      </h5>
                    </div>
                  </CardBody>
                </Card>
                {/* </div> */}
              </Col>
              {currentUser?.reamining_amount && (
                <Col xs={12} className="plan-info-modal ">
                  <p className="note">
                    <b>*Note :</b> Please note that the payment you are making
                    for the current period is based on a prorated amount. After
                    the conclusion of this subscription cycle, you will be
                    charged the standard monthly price for your subscription.
                  </p>
                </Col>
              )}

              <Col xs={12} className="">
                <div className="added-cards">
                  <>
                    <h5 style={{ fontWeight: "700" }}>Payment Information</h5>

                    {paymentData?.data?.map((card) => {
                      if (card.default_method) {
                        return (
                          <div
                            key={card.id}
                            className={"cardMaster rounded border p-2 mb-1"}
                          >
                            <div className="d-flex justify-content-between flex-row ">
                              <div className="card-information word-break-all">
                                <div className="d-flex align-items-center mb-50">
                                  <h6 className="mb-0 text-start">
                                    {card.name_on_card}
                                  </h6>
                                  {card.default_method === 1 && (
                                    <Badge
                                      color="light-primary"
                                      className="ms-50"
                                    >
                                      Default
                                    </Badge>
                                  )}
                                </div>
                                <span className="card-number d-flex align-items-center mb-50 text-start">
                                  **** **** **** {card.card_number}
                                </span>
                                <span className="mt-2 text-start d-flex">
                                  Card expires at {card.card_expiry}
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                    <div className="plan-info-modal ">
                      <p className="note">
                        <b>*Note :</b> You can change the default payment method
                        from the user profile section.
                      </p>
                    </div>
                  </>
                </div>
              </Col>
            </Row>
            <Row className="gy-1 gx-2 mt-75">
              <h5 className="add-coin-price text-start">
                Total Amount Payable Now : $
                {numberWithCommas(data?.prorate_amount_to_pay)}
              </h5>
              <h5 className="add-coin-price mb-1 text-start">
                Next Monthly Payment Date :{"  "}
                {data?.upcoming_invoice_date
                  ? dateFormat(data?.upcoming_invoice_date)
                  : "-"}
              </h5>

              <Col className="text-center mt-1" xs={12}>
                <Button
                  disabled={loading}
                  className="me-1 mb-1 mb-sm-0"
                  color={btnColor ? btnColor : "primary"}
                  onClick={() => handleClick()}
                  outline={outline ? outline : ""}
                >
                  Upgrade Subscription Now
                </Button>
                <Button
                  className="mb-1 mb-sm-0"
                  color="secondary"
                  outline
                  onClick={() => {
                    setOpenUpgrade({
                      isOpen: !openUpgrade?.isOpen,
                      data: null,
                    });
                  }}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </UILoader>
      </Modal>
    </div>
  );
};

export default UpgradeSubscriptionModal;
