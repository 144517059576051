/* eslint-disable no-mixed-operators */

import { Button } from "reactstrap";
import { getAdminData } from "../../../../services/getCurrentUser";

/* eslint-disable no-confusing-arrow */
const orderProjectData = [
  {
    fieldName: "insta_video",
    forSubscriber: "20 Coins",
    priceItem: 249,
    forAlacarte: "$249",
    stringName: "Instagram Video : 1 Minute",
  },
  {
    fieldName: "highlight_video[min_2_3]",
    forSubscriber: "32 Coins",
    priceItem: 399,
    forAlacarte: "$399",
    stringName: "Music Highlight Video – Music Only : 2-3 Minutes",
  },
  // {
  //   fieldName: "highlight_video[min_2_3_qty]",
  //   forSubscriber: "32 Coins",
  // priceItem: 99,//
  // forAlacarte: "$399",
  //  stringName: "Documentary Edits : ",/
  //  },
  {
    fieldName: "highlight_video[min_4_6]",
    forSubscriber: "40 Coins",
    priceItem: 499,
    forAlacarte: "$499",
    stringName: "Music Highlight Video – Music Only : 4-6 Minutes",
  },
  // {
  //   fieldName: "highlight_video[min_4_6_qty]",
  //   forSubscriber: "40 Coins",
  // priceItem: 99,//
  // forAlacarte: "$499",
  //  stringName: "Documentary Edits : ",/
  //  },
  {
    fieldName: "narrative_highlight_video[min_3_5]",
    forSubscriber: "48 Coins",
    priceItem: 599,
    forAlacarte: "$599",
    stringName: "Narrative Highlight Video – Music + Dialogue : 3-5 Minutes",
  },
  // {
  //   fieldName: "narrative_highlight_video[min_3_5_qty]",
  //   forSubscriber: "48 Coins",
  // priceItem: 99,//
  // forAlacarte: "$599",
  //  stringName: "Instagram Video - 1 Minute",/
  // / },
  {
    fieldName: "narrative_highlight_video[min_6_8]",
    forSubscriber: "53 Coins",
    priceItem: 669,
    forAlacarte: "$669",
    stringName: "Narrative Highlight Video – Music + Dialogue : 6-8 Minutes",
  },
  {
    fieldName: "narrative_highlight_video[min_9_12]",
    forSubscriber: "59 Coins",
    priceItem: 739,
    forAlacarte: "$739",
    stringName: "Narrative Highlight Video – Music + Dialogue : 9-12 Minutes",
  },
  {
    fieldName: "cinematic_film[min_13_15]",
    forSubscriber: "64 Coins",
    priceItem: 799,
    forAlacarte: "$799",
    stringName: "Cinematic Feature Film – Music + Dialogue : 13-15 Minutes",
  },
  {
    fieldName: "cinematic_film[min_16_20]",
    forSubscriber: "70 Coins",
    priceItem: 879,
    forAlacarte: "$879",
    stringName: "Cinematic Feature Film – Music + Dialogue : 16-20 Minutes",
  },
  {
    fieldName: "cinematic_film[min_21_25]",
    forSubscriber: "79 Coins",
    priceItem: 989,
    forAlacarte: "$989",
    stringName: "Cinematic Feature Film – Music + Dialogue : 21-25 Minutes",
  },
  {
    fieldName: "documentary_edits[ceremony_MCE]",
    forSubscriber: "8 Coins",
    priceItem: 99,
    forAlacarte: "$99",
    stringName: "Documentary Edits : Ceremony Edit",
  },
  {
    fieldName: "documentary_edits[reception_MCE]",
    forSubscriber: "8 Coins",
    priceItem: 99,
    forAlacarte: "$99",
    stringName: "Documentary Edits : Reception Edit",
  },
  {
    fieldName: "documentary_edits[dances_only]",
    forSubscriber: "6 Coins",
    priceItem: 75,
    forAlacarte: "$75",
    stringName: "Documentary Edits : Dances Only",
  },
  {
    fieldName: "documentary_edits[speeches_only]",
    forSubscriber: "6 Coins",
    priceItem: 75,
    forAlacarte: "$75",
    stringName: "Documentary Edits : Speeches Only",
  },
  {
    fieldName: "documentary_edits[ceremony_reception_MCE]",
    forSubscriber: "12 Coins",
    priceItem: 149,
    forAlacarte: "$149",
    stringName: "Documentary Edits : Ceremony + Reception Edit",
  },
  {
    fieldName: "documentary_edits[full_day_edit]",
    forSubscriber: "20 Coins",
    forAlacarte: "$249",
    priceItem: 249,
    stringName: "Documentary Edits : Full Day Edit",
  },
];

const USER_PLAN_TYPE = {
  SUBSCRIBER: "subscriber",
  ALACARTE: "alacarte",
};

const checkUserPlanType = (value) =>
  value ? USER_PLAN_TYPE.SUBSCRIBER : USER_PLAN_TYPE.ALACARTE;

const getEventDayPercentage = (value) => {
  let percentage;
  switch (value) {
    case "1.5":
      percentage = 15;
      break;
    case "2":
      percentage = 30;
      break;
    case "2.5":
      percentage = 45;
      break;
    case "3":
      percentage = 60;
      break;
    case "3.5":
      percentage = 75;
      break;
    case "4":
      percentage = 90;
      break;
    default:
      percentage = 0;
      break;
  }

  return percentage;
};

const priceForUser = (isUserType, fieldName, event_last_day) => {
  const data = orderProjectData.find((row) => row.fieldName === fieldName);

  if (checkUserPlanType(isUserType) === USER_PLAN_TYPE.SUBSCRIBER) {
    if (event_last_day) {
      const percentage = getEventDayPercentage(event_last_day);
      const forSubscriber = parseInt(data?.forSubscriber.match(/\d+/)[0]);
      const eventDayTotal = (forSubscriber * percentage) / 100;
      const totalAmount = forSubscriber + eventDayTotal;

      return (
        <h4 className="item-price">{`${Math.round(totalAmount)} Coins`}</h4>
      );
    }
    return <h4 className="item-price">{data?.forSubscriber}</h4>;
  } else {
    if (event_last_day) {
      const percentage = getEventDayPercentage(event_last_day);
      const forAlacarte = parseInt(data?.forAlacarte.match(/\d+/)[0]);
      const eventDayTotal = (forAlacarte * percentage) / 100;
      const totalAmount = forAlacarte + eventDayTotal;

      return <h4 className="item-price">{`$${totalAmount.toFixed(2)}`}</h4>;
    }
    return <h4 className="item-price">{data?.forAlacarte}</h4>;
  }
};

// const createOrderMeta = (data) => {
//   const result = {};

//   for (const property in data) {
//     if (property !== "discount_code") {
//       if (
//         property !== "footage_size" &&
//         property !== "event_last_day" &&
//         property !== "wedding_language" &&
//         property !== "language_information" &&
//         property !== "additional_note"
//       ) {
//         if (property === "insta_video") {
//           if (data[property]) {
//             result[property] = ["1_minute"];
//           }
//         } else if (typeof data[property] === "object") {
//           for (const key in data[property]) {
//             if (data[property][key]) {
//               if (!result.hasOwnProperty(property)) {
//                 result[property] = [];
//               }
//               if (property !== "documentary_edits") {
//                 result[property].push(`${key.replace("min_", "")}_minute`);
//               } else {
//                 result[property].push(key);
//               }
//             }
//           }
//         } else {
//           if (data[property]) {
//             result[property] = [data[property]];
//           }
//         }
//       }
//     }
//   }

//   return result;
// };

function renderValueBasedOnRange(val, ranges, defaultValue) {
  for (const range of ranges) {
    const { min, max, value } = range;
    if (val >= min && val <= max) {
      return <>{value}</>;
    }
  }
  return <>{defaultValue}</>;
}

function renderValueBasedOnRangeDiff(val, ranges) {
  for (const range of ranges) {
    const { min, max, caretvalue } = range;
    if (val >= min && val <= max) {
      const difference = val - caretvalue; // Calculate the difference between calculateTotalAmount() and min
      return difference;
    }
  }
}

const createOrderMeta = (data) => {
  const result = {};
  for (const property of data) {
    if (typeof property === "object") {
      if (!result[property.product_master_id]) {
        result[property.product_master_id] = [];
      }

      result[property.product_master_id]?.push(property.id);
    }
  }

  return result;
};

const NO_FORM_DATA_AVAILABLE = () => {
  const handelContactUsEmail = (data) => {
    const width = 800;
    const height = 600;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
    const features = `width=${width},height=${height},left=${left},top=${top},resizable=yes,scrollbars=yes`;
    const subject = `${data?.name} need to set order form`;
    const body = `${data?.name} need to set up an order form so we can start ordering from their site.`;
    const mailtoLink = `mailto:${data?.email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.open(mailtoLink, "_blank", features);
  };

  const handelContactUsAPI = () => {
    getAdminData()
      .then((res) => {
        handelContactUsEmail(res.data);
      })
      .catch((e) => console.log("error", e));
  };
  return (
    <div className="no_data">
      <p
        style={{
          maxWidth: "550px",
          minHeight: "350px",
        }}
        className="d-flex flex-column justify-content-center m-auto text-center"
      >
        <h2>No Data available.</h2>
        Please get in touch with your administrator to create a form according
        to the requirements.
        <div className="mt-1">
          <Button
            color="primary"
            outline
            style={{ width: "max-content" }}
            onClick={() => handelContactUsAPI()}
          >
            Contact Us
          </Button>
        </div>
      </p>
    </div>
  );
};

export {
  priceForUser,
  checkUserPlanType,
  USER_PLAN_TYPE,
  orderProjectData,
  getEventDayPercentage,
  createOrderMeta,
  renderValueBasedOnRange,
  renderValueBasedOnRangeDiff,
  NO_FORM_DATA_AVAILABLE,
};
