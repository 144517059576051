import React from "react";
import PopupManagement from "./PopupManagement";
import { popUpIndexData } from "../../../utility/Utils";

const toolTipMessage = {
  newProjectPopup:
    "Here is the general template for defining the Terms and Conditions for the new project. You should consult a lawyer before defining your T&C. Under no condition is Blinkovo (the platform) liable for any issues with your T&C. This is just a template and not the legal T&C.",
  orderPagePopup:
    "Here is the general template for defining the Terms and Conditions for the order page. You should consult a lawyer before defining your T&C. Under no condition is Blinkovo (the platform) liable for any issues with your T&C. This is just a template and not the legal T&C.",
  subscriptionPagePopup:
    "Here is the general template for defining Terms and Conditions for Subscription Services. You should consult a lawyer before defining your T&C. Under no condition is Blinkovo (the platform) liable for any issues with your T&C. This is just a template and not the legal T&C. ",
  termsAndConditionsPopup:
    "Here is the general template for defining the Terms and Conditions. You should consult a lawyer before defining your T&C. Under no condition is Blinkovo (the platform) liable for any issues with your T&C. This is just a template and not the legal T&C.",
  privacyPolicyPopup:
    "Here is the general template for defining the privacy policy. You should consult a lawyer to define your privacy policy. Under no condition is Blinkovo (the platform) liable for any issues with your privacy policy. This is just a template and not the legal privacy policy.",
};

export const NewProjectPopup = () => (
  <PopupManagement
    indexNumber={popUpIndexData.NEW_PROJECT}
    toolTipMessage={toolTipMessage?.newProjectPopup}
  />
);
export const OrderPagePopup = () => (
  <PopupManagement
    indexNumber={popUpIndexData.ORDER_PAGE}
    toolTipMessage={toolTipMessage?.orderPagePopup}
  />
);
export const SubscriptionPagePopup = () => (
  <PopupManagement
    indexNumber={popUpIndexData.SUBSCRIPTION_PURCHASE}
    toolTipMessage={toolTipMessage?.subscriptionPagePopup}
  />
);
export const TermsAndConditionsPopup = () => (
  <PopupManagement
    indexNumber={popUpIndexData.TERMS_OF_USE}
    toolTipMessage={toolTipMessage?.termsAndConditionsPopup}
  />
);
export const PrivacyPolicyPopup = () => (
  <PopupManagement
    indexNumber={popUpIndexData.PRIVACY_POLICY}
    toolTipMessage={toolTipMessage?.privacyPolicyPopup}
  />
);
