// ** React Imports
import { Fragment, useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Copy } from "react-feather";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

// ** Reactstrap Imports
import {
  Row,
  Col,
  Modal,
  Input,
  Button,
  ModalBody,
  ModalHeader,
  Progress,
} from "reactstrap";
import { USER_ROUTES } from "../../../constant/route";
// import { USER_ROUTES } from "../../../constant/route";

// ** Third Party Components
// import classnames from "classnames";
// import Cleave from "cleave.js/react";
// import { Check, X } from "react-feather";

const CPtoClipBoardModal = ({ CopyModal, setCopyModal }) => {
  const navigate = useNavigate();

  const [countdown, setCountdown] = useState(60);

  useEffect(() => {
    if (CopyModal?.isOpen) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown > 0) {
            return prevCountdown - 1;
          } else {
            clearInterval(timer);
            navigate(USER_ROUTES.MY_PROJECTS);
            return 0;
          }
        });
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [CopyModal?.isOpen]);

  // ** States
  //   const [show, setShow] = useState(false);

  // const [value, setValue] = useState();
  // console.warn("value: ", value);

  const onCopy = () => {
    // setValue(CopyModal?.data?.redirect_to);
    // setCopyModal({ isOpen: !CopyModal?.isOpen, data: "" });
    toast.success("Copied To Clipboard !");
  };

  return (
    <Fragment>
      <Modal
        backdrop="static"
        isOpen={CopyModal?.isOpen}
        toggle={() => {
          setCopyModal({ isOpen: !CopyModal?.isOpen, data: "" });
          // setValue("");
        }}
        className="modal-dialog-centered"
        onClosed={() => navigate(USER_ROUTES.MY_PROJECTS)}
      >
        <ModalHeader
          className="bg-transparent"
          toggle={() => {
            setCopyModal({ isOpen: !CopyModal?.isOpen, data: "" });
            // setValue("");
          }}
        ></ModalHeader>
        <ModalBody className="px-sm-5 mx-50 pb-5">
          <p className="text-start mb-1">
            It seems like you have a 3D secure card. You need to authorize this
            transaction to complete the purchase. Please click on this link to
            authorize.
          </p>
          {/* <p className="text-center">Add card for future billing</p> */}
          <Row tag="form" className="gy-1 gx-2 mt-75">
            <Col xs="10" className="pe-sm-0 mb-md-0 mb-1">
              <div className="text-break">
                <a target="_blank" href={CopyModal?.data?.redirect_to}>
                  {CopyModal?.data?.redirect_to}
                </a>
              </div>
            </Col>
            <Col md="2" xs="12">
              <CopyToClipboard
                onCopy={onCopy}
                text={CopyModal?.data?.redirect_to}
              >
                <Button color="primary" outline>
                  <Copy />
                </Button>
              </CopyToClipboard>
            </Col>
          </Row>
          <div className="text-center mt-2"> {countdown} sec</div>

          <Progress
            className="my-50"
            value={countdown ? countdown : ""}
            max={60}
          />
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default CPtoClipBoardModal;
