/* eslint-disable multiline-ternary */

// ** Label Select Options
const labelOptions = [
  { value: "1", label: "1 day" },
  { value: "1.5", label: "1.5 days" },
  { value: "2", label: "2 days" },
  { value: "2.5", label: "2.5 days" },
  { value: "3", label: "3 days" },
  { value: "3.5", label: "3.5 days" },
  { value: "4", label: "4+ days" },
];

// ** language select option
const languageOptions = [
  { value: "english", label: "English" },
  { value: "other", label: "Other" },
];

const orderMetaKeys = (data) => {
  let obj = {};

  for (const items in data) {
    obj = {
      ...obj,
      [items]: null,
    };
    for (const projectItem of data[items]) {
      if (items !== "insta_video") {
        const replaceLabel = projectItem.includes("_minute")
          ? `min_${projectItem.replace("_minute", "")}`
          : null;
        const originalKey = replaceLabel || projectItem;

        obj = {
          ...obj,
          [items]: {
            ...obj[items],
            [originalKey]: true,
          },
        };
      } else {
        obj = {
          ...obj,
          [items]: true,
        };
      }
    }
  }

  return obj;
};

const resetHelper = (data) => {
  let obj = {
    footage_size: data?.footage_size,
    event_last_day: data?.event_last_day
      ? labelOptions.find((item) => item.value === String(data?.event_last_day))
          ?.value
      : null,
    wedding_language: data?.wedding_language,
    discount_code: data?.discount_code,
  };

  const isOrderMetaValue = JSON.parse(data?.order_meta);

  if (Object.keys(isOrderMetaValue)?.length) {
    obj = {
      ...obj,
      ...orderMetaKeys(isOrderMetaValue),
    };
  }

  return obj;
};

export { labelOptions, languageOptions, resetHelper };
