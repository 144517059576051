/* eslint-disable multiline-ternary */
// ** React Imports
import React, { useEffect } from "react";
// import { useParams, Link } from "react-router-dom";

// ** Store & Actions
// import { getUser } from "./store";
import { useDispatch, useSelector } from "react-redux";

// ** Reactstrap Imports
import { Col, Row } from "reactstrap";

// ** User View Components

// ** Cutome Component
import Breadcrumbs from "@components/breadcrumbs";

// ** Styles
import "@styles/react/apps/app-users.scss";

import { getUser } from "../../../redux/userSlice/user.thunk";

import AdminAvatar from "./AdminAvatar";
import AdminInfo from "./AdminInfo";

const AdminProfile = () => {
  // ** Dispatch
  const dispatch = useDispatch();

  // const [active, setActive] = useState("1");

  const store = useSelector((state) => state.user);

  // const toggleTab = (tab) => {
  //   if (active !== tab) {
  //     setActive(tab);
  //   }
  // };

  useEffect(() => {
    dispatch(getUser());
  }, []);

  return (
    <div className="app-user-view">
      <div className="row justify-content-between align-items-center mb-2">
        <div className="d-flex back-div col-lg-9 col-12">
          <Breadcrumbs
            title="Profile"
            data={[{ title: "Profile", link: "" }]}
          />
        </div>
      </div>

      <Row>
        <Col xl="4" lg="5" xs={{ order: 1 }} md={{ order: 0, size: 5 }}>
          <AdminAvatar store={store} />
        </Col>
        <Col xl="8" lg="7" xs={{ order: 0 }} md={{ order: 1, size: 7 }}>
          {/* <UserTabs active={active} toggleTab={toggleTab} /> */}
          <AdminInfo />
        </Col>
      </Row>
    </div>
  );
};
export default AdminProfile;
