import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Modal,
  Button,
  ModalBody,
  ModalHeader,
  Badge,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";
import UILoader from "@components/ui-loader";
import { useDispatch, useSelector } from "react-redux";
import {
  dateFormat,
  numberWithCommas,
} from "../../../../common/functions/helperFunctions";
import Loader from "../../../../common/Loader/Loader";
import {
  calculateAmount,
  payRemainingAmount,
} from "../../../../services/subscription.service";
import { getErrorMsg } from "../../../../common/ErrorMessages/errorMessage";
import { getCurrentUser } from "../../../../redux/currentUserSlice/currentUser.thunk";
import ToastContent from "../../../components/toast/toast";
import { Check, X } from "react-feather";
import toast from "react-hot-toast";
import LineSkelaton from "../../../../common/skeleton/LineSkelaton";

const PayRemainingBalanceModal = (props) => {
  const {
    openRemaining,
    setOpenRemaining,
    // disabled,
    modalSize,
    btnColor,
    title,
    outline,
    info,
  } = props;

  const currentUser = useSelector(
    (state) => state.currentUser?.currentuserdata?.data
  );
  const dispatch = useDispatch();
  const paymentData = useSelector((state) => state.user.paymentMethods);
  const [data, setData] = useState(null);
  const [loading, setIsLoading] = useState(false);

  const [submitLoading, setIsSubmitLoading] = useState(false);

  const apiCall = async () => {
    setIsLoading(true);
    try {
      const res = await calculateAmount({
        subscription_id: currentUser?.subscription_id,
      });
      setData(res?.data);
      setIsLoading(false);
    } catch (error) {
      getErrorMsg(error);
      setIsLoading(false);
    }
  };

  const handleClick = () => {
    setIsSubmitLoading(true);
    payRemainingAmount({
      subscription_id: currentUser?.subscription_id,
    })
      .then((res) => {
        toast((t) => (
          <ToastContent
            t={t.id}
            message={res?.message}
            icon={<Check size={12} />}
          />
        ));
        setIsSubmitLoading(false);
        dispatch(getCurrentUser());
        setOpenRemaining({ isOpen: !openRemaining?.isOpen, data: null });
      })
      .catch((err) => {
        setIsSubmitLoading(false);
        // getErrorMsg(err);
        if (err.response?.data?.data?.redirect_to) {
          const win = window.open(
            err.response?.data?.data?.redirect_to,
            "_self"
          );
          if (win === null || typeof win === "undefined") {
            // Popup blocked, display message in UI
            toast((t) => (
              <ToastContent
                t={t.id}
                message="Popup blocked. Please enable popups and try again."
                color="danger"
                icon={<X size={12} />}
              />
            ));
          }
        } else {
          getErrorMsg(err);
        }
      });
  };

  useEffect(() => {
    if (openRemaining?.isOpen) {
      document.body.style.overflow = "hidden";
      apiCall();
    }

    return () => {
      document.body.style.overflow = "visible";
    };
  }, [openRemaining?.isOpen]);

  return (
    <div>
      <Modal
        style={{ zIndex: 99999 }}
        isOpen={openRemaining?.isOpen}
        toggle={() =>
          setOpenRemaining({ isOpen: !openRemaining?.isOpen, data: null })
        }
        className={`modal-dialog-centered  ${modalSize ? modalSize : ""}`}
        backdrop="static"
      >
        <UILoader blocking={submitLoading} loader={<Loader />}>
          <ModalHeader
            className={`bg-transparent ${submitLoading && "btn-loading"}`}
            toggle={() =>
              setOpenRemaining({ isOpen: !openRemaining?.isOpen, data: null })
            }
          ></ModalHeader>
          <ModalBody className="px-sm-4 mx-50 pb-5 ">
            <h1 className="text-center mb-1">
              {title ? title : "Settle Your Remaining Balance"}
            </h1>
            <p className="text-center">{info ? info : ""}</p>
            <Row className="gy-1 gx-2 mt-75">
              <Col xs={12} className="plan-info-modal ">
                <h5 className="Plan-detail d-flex align-items-start align-items-md-center flex-column flex-md-row w-100 ">
                  <span className="me-50 mb-0"> Subscription Plan : </span>
                  <span className="plan-name text-success">
                    {loading ? (
                      <LineSkelaton className=" ms-50 mb-0" />
                    ) : (
                      data?.subscription_plan
                    )}
                  </span>
                </h5>
              </Col>
              <Col xs={12} className="plan-info-modal ">
                <h5 className="Plan-detail d-flex align-items-start align-items-md-center flex-column flex-md-row w-100 ">
                  <span className="me-50 mb-0"> Subscription Period : </span>{" "}
                  <span className="plan-name text-success mt-25 mt-md-0">
                    {loading ? (
                      <LineSkelaton className=" ms-50 mb-0" />
                    ) : (
                      data?.subscription_start_date && (
                        <>
                          {dateFormat(data.subscription_start_date)}
                          {data.subscription_end_date &&
                            ` - ${dateFormat(data.subscription_end_date)}`}
                        </>
                      )
                    )}
                  </span>
                </h5>
              </Col>
              <Col xs={12} className="plan-info-modal ">
                <p className="note">
                  <b>*Note :</b> You are opting to pay off your remaining
                  balance for this plan. By submitting this payment, you will no
                  longer be charged any monthly payments for the remainder of
                  your one year term. Unless canceled, your subscription will be
                  automatically renewed and you will be charged the normal
                  monthly amount at the end of your current term.
                </p>
              </Col>
              <Col xs={12} className="">
                {/* <div> */}
                <h4 style={{ fontWeight: "700" }}>Payment Breakdown</h4>
                <Card className="breakdownCard">
                  <CardBody>
                    <h5 className="Plan-detail d-flex align-items-start align-items-md-center flex-column flex-md-row w-100 mb-1">
                      <span className="me-50 mb-0">
                        {" "}
                        12 Month Total Subscription Cost :{"  "}{" "}
                      </span>
                      <span className="plan-name text-success">
                        {loading ? (
                          <LineSkelaton className=" ms-50 mb-0" />
                        ) : (
                          `$${numberWithCommas(data?.total_plan_price)}`
                        )}
                      </span>
                    </h5>

                    <h5 className="Plan-detail d-flex align-items-start align-items-md-center flex-column flex-md-row w-100 mb-1">
                      <span className="me-50 mb-0"> Paid So Far : </span>
                      <span className="plan-name text-success">
                        {loading ? (
                          <LineSkelaton className=" ms-50 mb-0" />
                        ) : (
                          `$${numberWithCommas(data?.amount_paid)}`
                        )}
                      </span>
                    </h5>
                    <h5 className="Plan-detail d-flex align-items-start align-items-md-center flex-column flex-md-row w-100 ">
                      <span className="me-50 mb-0"> Remaining Balance : </span>
                      <span className="plan-name text-success">
                        {loading ? (
                          <LineSkelaton className=" ms-50 mb-0" />
                        ) : (
                          `$${numberWithCommas(data?.price_to_pay)}`
                        )}
                      </span>
                    </h5>
                  </CardBody>
                </Card>
                {/* </div> */}
              </Col>
              <Col xs={12} className="">
                <div className="added-cards">
                  <>
                    <h4 style={{ fontWeight: "700" }}>Payment Information</h4>

                    {paymentData?.data?.map((card) => {
                      if (card.default_method) {
                        return (
                          <div
                            key={card.id}
                            className={"cardMaster rounded border p-2 mb-1"}
                          >
                            <div className="d-flex justify-content-between flex-row ">
                              <div className="card-information word-break-all">
                                <div className="d-flex align-items-center mb-50">
                                  <h6 className="mb-0 text-start">
                                    {card.name_on_card}
                                  </h6>
                                  {card.default_method === 1 && (
                                    <Badge
                                      color="light-primary"
                                      className="ms-50"
                                    >
                                      Default
                                    </Badge>
                                  )}
                                </div>
                                <span className="card-number d-flex align-items-center mb-50 text-start">
                                  **** **** **** {card.card_number}
                                </span>
                                <span className="mt-2 text-start d-flex">
                                  Card expires at {card.card_expiry}
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                    <div className="plan-info-modal ">
                      <p className="note">
                        <b>*Note :</b> You can change the default payment method
                        from the user profile section.
                      </p>
                    </div>
                  </>
                </div>
              </Col>
            </Row>
            <Row className="gy-1 gx-2 mt-75">
              <h4 className="add-coin-price text-start">
                Total Remaining Balance : $
                {numberWithCommas(data?.price_to_pay)}
              </h4>
              <Col className="text-center mt-1" xs={12}>
                <Button
                  disabled={loading || submitLoading}
                  className="me-1 mb-1 mb-sm-0"
                  color={btnColor ? btnColor : "primary"}
                  onClick={() => handleClick()}
                  outline={outline ? outline : ""}
                >
                  Pay Remaining Balance
                </Button>
                <Button
                  className="mb-1 mb-sm-0"
                  color="secondary"
                  outline
                  onClick={() => {
                    setOpenRemaining({
                      isOpen: !openRemaining?.isOpen,
                      data: null,
                    });
                  }}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </UILoader>
      </Modal>
    </div>
  );
};

export default PayRemainingBalanceModal;
