import React, { useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const CustomQuillTextEditor = ({ value, handleChange, placeholder }) => {
  const quillRef = useRef(null);

  const modules = {
    toolbar: [
      ["link"],
      ["bold", "underline"],
      [{ header: [5, 6, false] }],
      ["clean"],
    ],
  };
  const formats = ["link", "bold", "underline", "header", "clean"];

  return (
    <ReactQuill
      ref={quillRef}
      theme="snow"
      value={value}
      placeholder={placeholder}
      modules={modules}
      formats={formats}
      onChange={(e) => handleChange(e)}
    />
  );
};

export default CustomQuillTextEditor;
