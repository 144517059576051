import React from "react";
import { Plus } from "react-feather";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";

const OrderButton = ({ setProjectInfoModel, projectInfoModel, buttonText }) => {
  const currentUser = useSelector((state) => state.currentUser);

  if (!currentUser.stripePublicKey) {
    return null;
  }
  return (
    <>
      {" "}
      <Button.Ripple
        // outline
        color={buttonText && "primary"}
        disabled={!currentUser.stripePublicKey}
        className={buttonText ? `beta-text-header` : `order-project-btn`}
        style={{
          ...(buttonText
            ? {
                borderColor: "#f5693b !important",
                backgroundColor: "#f5693b !important",
              }
            : {}),
        }}
        onClick={() => setProjectInfoModel(!projectInfoModel)}
      >
        {buttonText ? (
          buttonText
        ) : (
          <>
            <Plus size={14} />
            <span className="align-middle ms-25">Create New Order</span>
          </>
        )}
      </Button.Ripple>
    </>
  );
};

export default OrderButton;
